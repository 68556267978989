<template>
    <div class="container">
        <inner-page-card class="" :scrollbar="true" heading="FLASH">
            <template #buttons>
                <slot name="buttons">
                    <btn icon="fa fa-plus" color="success" border-radius="0"></btn>
                </slot>
            </template>
            <div class="py-3">
                <div class="col-4 col-lg-5 col-md  px-1">
                    <validated-input></validated-input>
                </div>
            </div>
        </inner-page-card>
    </div>
</template>

<script>
import InnerPageCard from '@components/InnerPageCard';

export default {
    name: 'Test',
    components: { InnerPageCard }
};
</script>

<style scoped>

</style>

<template>
    <container>
        <table-page heading="User Favourites">
            <vue-table-card :fields="fields" :url="listUrl"
            :per-page="10" ref="table" search-placeholder="Search">
            </vue-table-card>
        </table-page>
    </container>
</template>

<script>
import tableFields from './user-favourites-table-fields';
import urls from '@/data/urls';

export default {
    name: 'UserFavouritesPage',
    data () {
        return {
            fields: tableFields,
            listUrl: urls.disease.userFavourites.list,
            data: [
                {
                    user: 'vivek p',
                    disease: 'CANINE LEPTOSPIROSIS'
                }
            ]
        };
    }
};
</script>

<style scoped>

</style>

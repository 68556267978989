<template>
    <div>
        <div class="container">
            <div class="fl-y fl-a-c">
                <p class="pl-2" v-if="currentTransactionItem"><span class="font-proxima-bold text-left">User </span>: {{currentTransactionItem.name}}</p>
                <details-card class="w-40r" :heading="'Plan name : ' + currentTransactionItem.plan.name" v-if="currentTransactionItem && currentTransactionItem.plan">
                    <details-list>
                        <details-list-item label="Transaction ID" :value="currentTransactionItem.transaction_id"/>
                        <details-list-item label="Status" :value="currentTransactionItem.status"/>
                        <details-list-item label="Validity" :value="currentTransactionItem.plan.expiry_months"/>
                        <details-list-item label="Initiated Date" :value="currentTransactionItem.created_date"/>
                        <details-list-item label="Transaction Date" :value="currentTransactionItem.transaction_date"/>
                        <details-list-item label="Amount" :value="currentTransactionItem.amount"/>
                        <details-list-item label="Payment Method" :value="currentTransactionItem.payment_method_type"/>
                        <details-list-item label="Bank ID" :value="currentTransactionItem.bank_id"/>
                        <details-list-item label="Bank Referal No" :value="currentTransactionItem.bank_ref_no"/>
                        <details-list-item label="Refund Status" :value="currentTransactionItem.refund_status"/>

                    </details-list>
                    <btn-primary class="mt-3"
                                 v-if="showRefundButton"
                                 @click="setRefundConfirm(currentTransactionItem)"
                    >Refund
                    </btn-primary>
                    <!--                    <div v-if="makeRefundClicked && refundErrorMessage !==''" class="text-danger mt-2">{{refundErrorMessage}}</div>-->
                    <!--                    <div v-if="makeRefundClicked && refundSuccessMessage !==''" class="text-success mt-2">{{refundSuccessMessage}}</div>-->
                </details-card>
            </div>
            <!--            conform modal-->
            <activate-modal ref="RefundConfirmModal" ok-button="Confirm" :url="refundUrl" :params="refundConfirmItem" @response="RefundComplete">
                <p v-if="refundConfirmItem">You are about to refund the transaction <b>{{refundConfirmItem.transaction_id}}</b>. Are you sure
                    ?
                </p>
                <template #loading>
                    <loading-animation/>
                    Please wait while we approving <b v-if="refundConfirmItem" v-html="refundConfirmItem && refundConfirmItem.transaction_id"></b>.
                </template>
            </activate-modal>
        </div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../data/urls';

export default {
    name: 'TransactionDetailsPage',
    data () {
        return {
            transactionDetailsUrl: urls.transactions.detail,
            refundUrl: urls.transactions.refund,
            currentTransactionItem: [],
            transactionId: this.$route.params.id,
            refundConfirmItem: null,
            refundSuccessMessage: '',
            refundErrorMessage: ''
        };
    },
    mounted () {
        this.viewInfo(this.transactionId);
    },
    computed: {
        showRefundButton () {
            return this.currentTransactionItem && this.currentTransactionItem.status === 'Success' && this.currentTransactionItem.refund_status === false;
        }
    },
    methods: {
        async viewInfo (transactionId) {
            // console.log(item);
            const that = this;
            const response = await axios.form(
                this.transactionDetailsUrl, { id: transactionId }
            ).catch(function (exception) {
                that.serverError();
            });
            const json = response.data;
            const dataItem = json.data;
            console.log('json', json.data);
            this.currentTransactionItem = dataItem;
            // this.$refs.transactionViewModal.show();
            this.makeRefundClicked = false;
        },
        setRefundConfirm (item) {
            this.refundConfirmItem = { ...item };
            this.$refs.RefundConfirmModal.show();
        },
        RefundComplete (data) {
            this.refundConfirmItem = null;
            this.$refs.RefundConfirmModal.close();
            // this.$notify('Suggestion Approved Successfully', 'Success', { type: 'success' });
            // this.$refs.table.refreshTable();
            const result = data;
            if (result.success === true) {
                // this.$refs.transactionViewModal.close();
                this.$notify(result.message, 'Success', { type: 'success' });
                console.log('refund success');
                this.refundSuccessMessage = 'Refunded Successfully';
            } else {
                const errors = data.errors;
                console.log('errors', errors);
                this.refundErrorMessage = result.message;
                this.$notify(this.refundErrorMessage, 'Warning', { type: 'warning' });
                for (const key in errors) {
                    if (Object.prototype.hasOwnProperty.call(errors, key)) {
                        let errorMessage;
                        if (key === '__all__') {
                            errorMessage = `${errors[key]}`;
                        } else {
                            errorMessage = `${key}:  ${errors[key]}`;
                        }
                        this.refundErrorMessage = errorMessage;
                        this.$notify(errorMessage, 'Warning', { type: 'warning' });
                    }
                }
            }
            this.viewInfo(this.transactionId);
        }
    }
};
</script>

<style scoped>

</style>

<template>
<div>
        <container>
            <inner-page-card heading="Edit Preparation">
                  <s-form @submit="addItem">
                            <row>
                                 <col-12>
                            <add-btn color="secondary" size="sm" text="Add Indiaction" @click="$refs.indicationAddModal.show()" type="button"/>
                        </col-12>
                                <col-12 lg="12" class="mt-3">
                                    <validated-input class="mt-n2" v-model="detailItem.preperation_name"
                                                     :rules="rules.preperation_name"
                                                     :disabled="loading"
                                                     label="Preperation <span class='text-danger'>*</span> :"></validated-input>
                                </col-12>

                                <col-12 lg="12">
                                    <form-group
                                        :disabled="loading" label="Content <span class='text-danger'>*</span>:">
                                        <ckeditor class="w-100p" :editor="editor" v-model="detailItem.content"
                                                  :rules="rules.content"
                                                  :config="editorConfig"></ckeditor>
                                    </form-group>

                                </col-12>
                                 <col-12 lg="12" class="mb-7">
<!--                                    <validated-ajax-select class="mt-n2" multiple
                                                     :disabled="loading" :url="indiactionUrl" v-model="detailItem.indications"
                                                     label="Indication  :"></validated-ajax-select>-->
                                     <validated-vue-select class="mt-n2 font-weight-700 text-2 v-select-dropdown-max-h-10r" multiple
                                                     :disabled="loading" :options="indicationOptions" v-model="detailItem.indications"
                                                     label="Indication  :"></validated-vue-select>
                                </col-12>
                                <col-12>
                                    <submit-btn loading-text="Saving..." size="sm" :disabled="loading"
                                                :loading="loading"></submit-btn>
                                </col-12>
                            </row>
                        </s-form>

                 <modal width="55r" title="Add" ref="indicationAddModal">
                        <s-form @submit="addIndication">
                            <row>
                                <col-12 lg="6">
                                    <validated-input class="mt-n2" v-model="model.indication_name"
                                                     :rules="rules.indication_name"
                                                     :disabled="loading"
                                                     label="Indication <span class='text-danger'>*</span> :"></validated-input>
                                </col-12>

                                <col-12 lg="6">
                                    <form-group
                                        :disabled="loading" label="Content <span class='text-danger'>*</span>:">
                                        <ckeditor class="w-100p" :editor="editor" v-model="model.content"
                                                  :rules="rules.content"
                                                  :config="editorConfig"></ckeditor>
                                    </form-group>

                                </col-12>
                                <col-12>
                                    <submit-btn loading-text="Saving..." size="sm" :disabled="loading"
                                                :loading="loading"></submit-btn>
                                </col-12>
                            </row>
                        </s-form>
                    </modal>

            </inner-page-card>
        </container>
    </div>
</template>

<script>
import ckeditorSettings from '../../../../components/ckeditor-settings';
import urls from '@/data/urls';
import axios from 'secure-axios';

export default {
    name: 'edit-preparation',
    data () {
        return {
            detailUrl: urls.pharmacy.preparation.detail,
            detailItem: '',
            IndiactionEditUrl: urls.pharmacy.indication.addEdit,
            addEditUrl: urls.pharmacy.preparation.addEdit,
            loading: false,
            indiactionUrl: urls.pharmacy.preparation.indiacationVueSelect,
            editorConfig: ckeditorSettings.editorConfig,
            editor: ckeditorSettings.ClassicEditor,
            indicationOptions: [],
            rules: {
                preperation_name: {
                    required: true
                },
                content: {
                    required: true
                }
            },
            model: {
                content: '',
                preperation_name: ''
            },
            preparationId: this.$route.params.id
        };
    },
    mounted () {
        this.fetchIndication();
        this.fetchData();
    },
    methods: {

        async addItem () {
            try {
                this.loading = true;
                const response = await axios.form(this.addEditUrl, this.detailItem);
                const result = response.data;
                console.log('result', result);
                if (result.success === true) {
                    this.$notify('Updated Successfully', 'Success', { type: 'success' });
                    this.loading = false;
                    /* this.$emit('success'); */
                    this.$router.push('/preperations/');
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        },
        async addIndication () {
            try {
                this.loading = true;
                const response = await axios.form(this.IndiactionEditUrl, this.model);
                const result = response.data;
                console.log('result', result);
                if (result.success === true) {
                    this.$notify('Added Successfully', 'Success', { type: 'success' });
                    this.loading = false;
                    /* this.$emit('success'); */
                    /* window.location.reload(true); */
                    this.fetchIndication();
                    this.$refs.indicationAddModal.close();
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        },
        /* userEditFailure (response) {
            const errors = response.data.errors;
            for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                    const errorMessage = `${key}:  ${errors[key]}`;
                    this.$notify(errorMessage, 'Warning', { type: 'warning' });
                }
            }
        },
        formSuccess (action) {
            if (action === 'A') {
                this.$refs.indicationAddModal.close();
            } else {
                this.$router.push('/preperations/');
            }
            this.$notify(' Added Successfully', 'Success', { type: 'success' });
        }, */
        async fetchIndication () {
            try {
                const response = await axios.get(this.indiactionUrl);
                const result = response.data;
                this.indicationOptions = result.data;
                console.log('detailItem', this.indicationOptions);
                if (result.success === true) {
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
            }
        },
        async fetchData () {
            try {
                console.log('paramId', this.preparationId);
                const response = await axios.form(this.detailUrl, { id: this.preparationId });
                const result = response.data;
                this.detailItem = result.data;
                console.log('detailItem', this.detailItem);
                if (result.success === true) {
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
            }
        }

    }
};
</script>

<style scoped>

</style>

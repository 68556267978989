<template>
    <div>
        <container>
            <table-page heading="CATEGORY">
                <template #buttons>
                    <add-btn @click="$refs.addCategoryModal.show()"/>
                </template>
                <vue-table-card :fields="fields" :url="listUrl" ref="table">
                    <template #actions="{rowData}">
                        <btn-group>
                            <edit-btn @click="SetEditCategory(rowData)"/>
                            <delete-btn @click="setDeleteCategory(rowData)"/>
                        </btn-group>
                    </template>
                    <template #footer>
                        <modal ref="addCategoryModal" title="Add Category" width="35r">
                            <b-form :save-url="addEditUrl" @failure="categoryAddEditFailure" @success="formSuccess('A')" v-slot="{model, loading}">
                                <row>
                                    <col-12 lg="8">
                                        <validated-input label="Name <span class='text-danger'>*</span> :"
                                                         v-model="model.category_name" :rules="rules.category_name"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12>
                                        <submit-btn text="Save" loading-text="Saving..." size="sm" :disabled="loading"
                                                    :loading="loading"/>
                                    </col-12>
                                </row>
                            </b-form>
                        </modal>
                        <edit-modal ref="categoryEditModal" width="35r">
                            <b-form :save-url="addEditUrl" @failure="categoryAddEditFailure" @success="formSuccess('M')" v-slot="{model, loading}"
                                :initial-data="editingItem">
                                <row>
                                    <col-12 lg="8">
                                        <validated-input label="Name <span class='text-danger'>*</span> :"
                                                         v-model="model.category_name" :rules="rules.category_name"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12>
                                        <submit-btn text="Save" loading-text="Saving..." size="sm" :disabled="loading"
                                                    :loading="loading"/>
                                    </col-12>
                                </row>
                            </b-form>
                        </edit-modal>
                        <delete-modal ref="categoryDeleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
                            <p>You are about to delete the category
                                <b v-if="deletingItem"> {{ deletingItem.name }}</b>.Are you sure?</p>
                            <template #loading>
                                <loading-animation/>
                                Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
                            </template>
                        </delete-modal>
                        <modal title="User Info" ref="userViewModal">
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab ad adipisci alias aliquid architecto aut blanditiis corporis cum, dolorum earum
                                eligendi
                                excepturi ipsum maiores nam nobis numquam odio officia, omnis pariatur provident quaerat quibusdam quos sed similique sunt veritatis voluptatibus.
                                Harum
                                itaque minima nesciunt quae recusandae sequi ut? Est, sunt.
                            </p>
                        </modal>
                    </template>
                </vue-table-card>
            </table-page>
        </container>
    </div>
</template>

<script>
import usersTableFields from './category-table-fields';
import urls from '../../../data/urls';

export default {
    name: 'DrugsViewCategoryPage',
    data () {
        return {
            fields: usersTableFields,
            listUrl: urls.drugs.drugCategory.list,
            addEditUrl: urls.drugs.drugCategory.addEdit,
            deleteUrl: urls.drugs.drugCategory.delete,
            data: [
                {
                    id: '23',
                    name: 'Analgesics',
                    added_by: 'Ghanasyam Joshi'
                }
            ],
            editingItem: null,
            deletingItem: {
                name: ''
            },
            rules: {
                category_name: {
                    required: true
                }
            }
        };
    },
    methods: {
        SetEditCategory (item) {
            this.editingItem = { ...item };
            this.$refs.categoryEditModal.show();
        },
        setDeleteCategory (item) {
            this.deletingItem = { ...item };
            this.$refs.categoryDeleteModal.show();
        },
        formSuccess (action) {
            const refs = this.$refs;
            this.$refs.addCategoryModal.close();
            this.$refs.categoryEditModal.close();
            if (action === 'A') {
                this.$notify('Category Added Successfully', 'Success', {
                    type: 'success'
                });
            } else {
                this.$notify('Category Updated Successfully', 'Success', {
                    type: 'success'
                });
            }
            refs.table.refreshTable();

            this.editingItem = null;
        },
        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.categoryDeleteModal.close();
            this.$notify('Category Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        categoryAddEditFailure (response) {
            const errors = response.data.errors;
            for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                    const errorMessage = `${key}:  ${errors[key]}`;
                    this.$notify(errorMessage, 'Warning', { type: 'warning' });
                }
            }
        }
    }
};
</script>

<style scoped>

</style>

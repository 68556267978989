const tableFields = [
    {
        name: 'species_name',
        title: 'Species',
        titleClass: '',
        dataClass: 'w-30r',
        sortField: 'species_name'

    },
    {
        name: 'disease_name',
        title: 'Disease',
        titleClass: '',
        dataClass: 'w-40r',
        sortField: 'disease_name'

    },
    {
        name: 'blood_chemistry',
        title: 'Blood Chemistry',
        titleClass: '',
        dataClass: 'w-40r',
        sortField: 'blood_chemistry'

    },

    {
        name: '__slot:actions',
        title: 'Actions',
        titleClass: '',
        dataClass: ''
    }
];

export default tableFields;

const tableFields = [
    {
        name: 'report_type',
        title: 'Report type',
        titleClass: '',
        dataClass: '',
        sortField: 'report_type'

    },
    {
        name: 'content',
        title: 'Contents',
        titleClass: '',
        dataClass: '',
        sortField: 'content'

    }, {
        name: 'preperation',
        title: 'Preperations',
        titleClass: '',
        dataClass: '',
        sortField: 'preperation'

    }, {
        name: 'reported_by',
        title: 'Reported by',
        titleClass: '',
        dataClass: '',
        sortField: 'reported_by'

    },

    {
        name: '__slot:actions',
        title: 'Actions',
        titleClass: '',
        dataClass: ''
    }
];

export default tableFields;

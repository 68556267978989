const usersTableFields = [
    {
        name: 'username',
        title: 'Username',
        titleClass: '',
        dataClass: 'w-10r'
    },
    {
        name: 'first_name',
        title: 'First Name',
        titleClass: '',
        dataClass: ''
    },
    {
        name: 'last_name',
        title: 'Last Name',
        titleClass: '',
        dataClass: ''
    },
    {
        name: 'college',
        title: 'College',
        titleClass: '',
        dataClass: ''
    },
    {
        name: 'designation',
        title: 'Designation',
        titleClass: '',
        dataClass: ''
    },
    {
        name: 'email',
        title: 'Email',
        titleClass: '',
        dataClass: ''
    },
    {
        name: 'phone_number',
        title: 'Phone Number',
        titleClass: '',
        dataClass: ''
    },
    {
        name: '__slot:actions',
        title: 'Actions',
        titleClass: '',
        dataClass: ''
    }
];

export default usersTableFields;

<template>
        <table-page heading="Banned Users">
            <vue-table-card ref="table" :url="listUrl" :fields="fields" :table-data="data" class="mx-lg-n9">
                <template #actions="{rowData}">
                    <div class="fl-y fl-a-s">
                        <unban-btn @click="UnbanUser(rowData)"></unban-btn>
                        <delete-btn text="Delete" @click="setDeleteUser(rowData)"/>
                    </div>
                </template>
                <template #footer>
                    <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
                        <p>You are about to delete user
                            <b v-if="deletingItem"> {{ deletingItem.username }}</b>. Are you sure about it ?</p>
                        <template #loading>
                            <loading-animation/>
                            Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
                        </template>
                    </delete-modal>
                </template>
            </vue-table-card>
        </table-page>
</template>

<script>
import usersTableFields from './banned-users-table-fields';
import UnbanBtn from '../../../components/buttons/unban-btn';
import urls from '../../../data/urls';
import axios from 'secure-axios';

export default {
    name: 'BannedUsersPage',
    components: { UnbanBtn },
    data () {
        return {
            fields: usersTableFields,
            listUrl: urls.userManagement.users.bannedUsersList,
            unbanUrl: urls.userManagement.users.userBan,
            deleteUrl: urls.userManagement.users.delete,
            deletingItem: {
                name: ''
            },
            data: [
                {
                    id: '23',
                    username: 'gokul',
                    first_name: 'Gokul',
                    last_name: 'Das',
                    college: 'GEC Thrissur',
                    designation: 'Quality Tester',
                    email: 'gokul@mail.in',
                    phone_number: '8475421111',
                    registration_no: '75884',
                    country: 'India',
                    place: 'Ernakulam'
                }
            ]
        };
    },
    methods: {
        async UnbanUser (item) {
            // ban user post method
            const that = this;
            const response = await axios.form(this.unbanUrl, { id: item.id })
                .catch(function (exception) {
                    that.serverError();
                });
            const responseData = await response.data;
            console.log('response', responseData);
            if (responseData.success) {
                this.$notify('User is Unbanned Successfully', 'Success', {
                    type: 'success'
                });
            }
            if (responseData.error) {
                that.$notify('Error fount..!', 'Error',
                    {
                        type: 'warning',
                        duration: 3000,
                        permanent: false
                    }
                );
            }
            this.$refs.table.refreshTable();
        },
        setDeleteUser (item) {
            this.deletingItem = { ...item };
            this.$refs.deleteModal.show();
        },
        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.deleteModal.close();
            this.$notify('User Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        }
    }
};
</script>

<style scoped>

</style>

const tableFields = [
    {
        name: 'report_type',
        title: 'Report type',
        titleClass: '',
        dataClass: '',
        sortField: 'report_type'
    },
    {
        name: 'content',
        title: 'Content',
        titleClass: '',
        dataClass: '',
        sortField: 'content'
    },
    {
        name: 'indication',
        title: 'Indication',
        titleClass: '',
        dataClass: '',
        sortField: 'indication'
    },
    {
        name: 'reported_by',
        title: 'Reported by',
        titleClass: '',
        dataClass: '',
        sortField: 'reported_by'

    },

    {
        name: '__slot:actions',
        title: 'Actions',
        titleClass: '',
        dataClass: ''
    }
];

export default tableFields;

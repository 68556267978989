<template>
    <table-page heading="Users">
        <vue-table-card :fields="fields" :table-data="data">
            <template #actions="{rowData}">
                <btn-group>
                    <edit-btn @click="EditUser(rowData)"/>
                    <delete-btn @click="setDeleteUser(rowData)"/>
                    <view-btn @click="viewUser(rowData)"/>
                </btn-group>
            </template>
            <template #footer>
                <edit-modal ref="userEditModal">
                    <s-form>
                        <row>
                            <col-12>
                                <validated-input></validated-input>
                            </col-12>
                            <col-12>
                                <btn>Update</btn>
                            </col-12>
                        </row>
                    </s-form>
                </edit-modal>
                <delete-modal ref="userDeleteModal">
                    <p>You are about to delete the User <b>User Name</b>. Are you sure
                        ?
                    </p>
                </delete-modal>
                <modal title="User Info" ref="userViewModal">
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab ad adipisci alias aliquid architecto aut blanditiis corporis cum, dolorum earum eligendi
                        excepturi ipsum maiores nam nobis numquam odio officia, omnis pariatur provident quaerat quibusdam quos sed similique sunt veritatis voluptatibus. Harum
                        itaque minima nesciunt quae recusandae sequi ut? Est, sunt.
                    </p>
                </modal>
            </template>
        </vue-table-card>
    </table-page>
</template>

<script>
import usersTableFields from './users-table-fields';

export default {
    name: 'TablePageFormat',
    data () {
        return {
            fields: usersTableFields,
            data: [
                {
                    id: '23',
                    username: 'gokul',
                    first_name: 'Gokul',
                    last_name: 'Das',
                    college: 'GEC Thrissur',
                    designation: 'Quality Tester',
                    email: 'gokul@mail.in',
                    phone_number: '8475421111'
                }
            ]
        };
    },
    methods: {
        EditUser (item) {
            console.log(item);
            this.$refs.userEditModal.show();
        },
        setDeleteUser (item) {
            console.log(item);
            this.$refs.userDeleteModal.show();
        },
        viewUser (item) {
            console.log(item);
            this.$refs.userViewModal.show();
        }
    }
};
</script>

<style scoped>

</style>

<template>
    <container>
        <inner-page-card class="" :scrollbar="true" heading="PLANS">
            <template #buttons>
                <slot name="buttons">
                    <add-btn @click="$refs.addPlanModal.show()"/>

                </slot>
            </template>

            <vue-table-card :fields="fields" :url="listUrl" ref="table" :table-data="data">
                <template #actions="{rowData}">
                    <btn-group>
                        <view-btn text="View" @click="viewUser(rowData)"/>
                        <edit-btn @click="setEdit(rowData)"/>
                        <activate-btn @click="activatePlan(rowData)"/>
                        <delete-btn @click="setDelete(rowData)"/>
                    </btn-group>
                </template>
                <template #plan_type="{rowData}">
                    <div v-html="getPlanType(rowData.plan_type)"/>
                </template>
                <template #footer>
                    <delete-modal ref="planDeleteModal" :url="deleteUrl" :params="deletingItem"
                                  @response="deleteComplete">
                        <p>You are about to delete the plan
                            <b v-if="deletingItem"> {{ deletingItem.name }}</b>.Are you sure?</p>
                        <template #loading>
                            <loading-animation/>
                            Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
                        </template>
                    </delete-modal>
                    <activate-modal ref="planActivateModal" @response="activateSuccess"
                                    :url="activatePlanUrl" :params="activatePlanItem">
                        <p>You are about to activate the Plan <b v-html="activatePlanItem.name"></b>.
                            Are you sure?
                        </p>
                        <template #loading>
                            <loading-animation/>
                            Please wait while we activate the plan...
                        </template>
                    </activate-modal>
                    <modal width="35r" title="Plan Info" ref="planViewModal">
                        <details-card :heading="'Plan name : ' + currentUserItem.name" v-if="currentUserItem">
                            <details-list>
                                <details-list-item label="Description" :value="currentUserItem.description"/>
                                <details-list-item label="Value" :value="currentUserItem.value"/>
                                <details-list-item label="Expiry Months" :value="currentUserItem.expiry_months"/>
                                <details-list-item label="Plan Type" :value="getPlanType(currentUserItem.plan_type)"/>
                                <details-list-item label="Offer Saving Price" :value="currentUserItem.offer_saving"/>
                                <details-list-item label="Offer Percentage" :value="currentUserItem.offer_percentage"/>
                            </details-list>
                        </details-card>
                    </modal>
                    <modal width="55r" title="Add" ref="addPlanModal">
                        <b-form :save-url="addUrl" @failure="userEditFailure" @success="formSuccess('A')"
                                v-slot="{model, loading}">
                            <row>
                                <col-12 lg="6">
                                    <validated-input label="Name <span class='text-danger'>*</span>:"
                                                     v-model="model.name" :rules="rules.name"
                                                     :disabled="loading"></validated-input>
                                </col-12>
                                <col-12 lg="6">
                                    <validated-input v-model="model.description" :rules="rules.description"
                                                     :disabled="loading"
                                                     label="Description <span class='text-danger'>*</span>"></validated-input>
                                </col-12>
                                <col-12 lg="4">
                                    <validated-input v-model="model.value" :rules="rules.value" :disabled="loading" type="number" label="Value <span class='text-danger'>*</span>:"></validated-input>
                                </col-12>
                                <col-12 lg="4">
                                    <validated-input v-model="model.expiry_months" :rules="rules.expiry_months"
                                                     :disabled="loading"
                                                     label="Expiry Months <span class='text-danger'>*</span>:"></validated-input>
                                </col-12>
                                <col-12 lg="4">
                                    <validated-select :options="planOptions" v-model="model.plan_type"
                                                      :rules="rules.plan_type" :disabled="loading"
                                                      label="Plan Type <span class='text-danger'>*</span>:"></validated-select>
                                </col-12>
                                <col-12 lg="6">
                                    <validated-input v-model="model.offer_percentage" :rules="rules.offer_percentage"
                                                     :disabled="loading" label="Offer percentage"></validated-input>
                                </col-12>
                                <col-12 lg="6">
                                    <validated-input v-model="model.offer_saving" :rules="rules.offer_saving"
                                                     :disabled="loading" label="Offer savings"></validated-input>
                                </col-12>
                                <col-12>
                                    <submit-btn loading-text="Saving..." size="sm" :disabled="loading"
                                                :loading="loading"></submit-btn>
                                </col-12>
                            </row>
                        </b-form>
                    </modal>
                    <modal width="55r" title="Edit" ref="editPlanModal">
                        <b-form :save-url="addUrl" @failure="userEditFailure" @success="formSuccess('M')" v-slot="{model, loading}"
                                :initial-data="editingItem">
                            <row>
                                <col-12 lg="6">
                                    <validated-input label="Name <span class='text-danger'>*</span>:"
                                                     v-model="model.name" :rules="rules.name"
                                                     :disabled="loading"></validated-input>
                                </col-12>
                                <col-12 lg="6">
                                    <validated-input v-model="model.description" :rules="rules.description"
                                                     :disabled="loading"
                                                     label="Description <span class='text-danger'>*</span>"></validated-input>
                                </col-12>
                                <col-12 lg="4">
                                    <validated-input v-model="model.value" :rules="rules.value" :disabled="loading" type="number" label="Value <span class='text-danger'>*</span>:"></validated-input>
                                </col-12>
                                <col-12 lg="4">
                                    <validated-input v-model="model.expiry_months" :rules="rules.expiry_months"
                                                     :disabled="loading"
                                                     label="Expiry Months <span class='text-danger'>*</span>:"></validated-input>
                                </col-12>
                                <col-12 lg="4" class="pb-5">
                                    <validated-select :options="planOptions" v-model="model.plan_type"
                                                      :rules="rules.plan_type" :disabled="loading"
                                                      label="Plan Type <span class='text-danger'>*</span>:"></validated-select>
                                </col-12>
                                <col-12>
                                    <submit-btn loading-text="Updating..." size="sm" :disabled="loading"
                                                :loading="loading"></submit-btn>
                                </col-12>
                            </row>
                        </b-form>
                    </modal>
                </template>
            </vue-table-card>

        </inner-page-card>
    </container>
</template>

<script>
import InnerPageCard from '@components/InnerPageCard';
import AddPlanPage from '@/views/plans/add-plans/add-plans-fields';
import urls from '../../../data/urls';
import axios from 'secure-axios';

export default {
    name: 'AddPlanPage',
    components: InnerPageCard,
    data () {
        return {
            fields: AddPlanPage,
            listUrl: urls.userManagement.plan.list,
            addUrl: urls.userManagement.plan.addEdit,
            detailsUrl: urls.userManagement.plan.detail,
            deleteUrl: urls.userManagement.plan.delete,
            activatePlanUrl: urls.userManagement.plan.activate,
            activating: false,
            editingItem: null,
            currentUserItem: null,
            activatePlanItem: {
                id: '',
                name: ''
            },
            deletingItem: {
                name: ''
            },

            rules: {
                name: {
                    required: true
                },
                description: {
                    required: true
                },
                value: {
                    required: true
                },
                expiry_months: {
                    required: true
                },
                plan_type: {
                    required: true
                },
                offer_percentage: {
                    required: false
                },
                offer_saving: {
                    required: false
                }
            },
            planOptions: [
                {
                    label: 'Basic',
                    value: '1'
                }, {
                    label: 'Standard',
                    value: '2'
                }, {
                    label: 'Premium',
                    value: '3'
                }
            ],
            data: [
                {
                    name: 'alfredo-',
                    description: 'lorem afga AWH',
                    value: '2',
                    expiry_months: '2-3-2021',
                    status: 'lorem ',
                    plan: '2'
                }
            ]
        };
    },
    methods: {
        formSuccess (action) {
            const refs = this.$refs;
            this.$refs.addPlanModal.close();
            this.$refs.editPlanModal.close();
            if (action === 'A') {
                this.$notify('Plan Added Successfully', 'Success', {
                    type: 'success'
                });
            } else {
                this.$notify('Plan Updated Successfully', 'Success', {
                    type: 'success'
                });
            }
            refs.table.refreshTable();

            this.editingItem = null;
        },
        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.editPlanModal.show();
        },
        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.planDeleteModal.show();
        },
        activatePlan (item) {
            console.log(item);
            this.activatePlanItem = { ...item };
            this.activatePlanItem.id = item.id;
            this.activatePlanItem.name = item.name;
            this.$refs.planActivateModal.show();
        },
        getPlanType (planType) {
            const filteredPlan = this.planOptions.filter(plan => plan.value === planType);
            return filteredPlan[0].label;
        },
        viewUser1 (item) {
            console.log(item);
            this.$refs.addplansViewModal.show();
        },
        async viewUser (item) {
            console.log(item);
            const that = this;
            const response = await axios.form(
                this.detailsUrl, { id: item.id }
            ).catch(function (exception) {
                that.serverError();
            });
            const json = response.data;
            const dataItem = json.data;
            // console.log('json', json.data.first_name);
            this.currentUserItem = dataItem;
            this.$refs.planViewModal.show();
        },
        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.planDeleteModal.close();
            this.$notify('Plan Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        userEditFailure (response) {
            const errors = response.data.errors;
            for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                    const errorMessage = `${key}:  ${errors[key]}`;
                    this.$notify(errorMessage, 'Warning', { type: 'warning' });
                }
            }
        },
        activateSuccess (response) {
            if (response.error === true) {
                this.$notify({
                    message: response.message ? response.message : 'Failed to activate plan',
                    title: 'Failed',
                    type: 'danger'
                });
            } else {
                this.$notify({
                    message: response.message ? response.message : 'Plan Activated Successfully',
                    title: 'Success',
                    type: 'success'
                });
            }
            // this.activatePlanItem = null;
            this.$refs.planActivateModal.close();
            this.$refs.table.refreshTable();
        }

    }

};
</script>

<style scoped>

</style>

<template>
    <div>
        <container>
            <inner-page-card heading="Add CATEGORIES">
                <custom-size-el height="6r">
                    <transition-fade>
                        <div class="fl-x-cc h-100p" v-if="!showForm" key="anim">
                            <loading-animation></loading-animation>
                        </div>
                        <b-form v-else key="form" :save-url="addUrl" ref="form" @failure="categoryAddEditFailure" @success="formSuccess('A')" v-slot="{model, loading}">
                            <row>
                                <col-12 lg="8">
                                    <validated-input label="Name <span class='text-danger'>*</span> :"
                                                     v-model="model.category_name" :rules="rules.category_name"
                                                     :disabled="loading"></validated-input>
                                </col-12>
                                <col-12>
                                    <submit-btn text="Save" loading-text="Saving..." size="sm" :disabled="loading"
                                                :loading="loading"/>
                                </col-12>
                            </row>
                        </b-form>
                    </transition-fade>
                </custom-size-el>
            </inner-page-card>
        </container>
    </div>
</template>

<script>
import urls from '../../../data/urls';

export default {
    name: 'DrugsAddCategoryPage',
    data () {
        return {
            data: [
                {
                    id: '23',
                    name: 'Analgesics',
                    added_by: 'Ghanasyam Joshi'
                }
            ],
            addUrl: urls.drugs.drugCategory.addEdit,
            rules: {
                category_name: {
                    required: true
                }
            },
            showForm: true
        };
    },
    methods: {
        EditCategory (item) {
            console.log(item);
            this.$refs.categoryEditModal.show();
        },
        setDeleteCategory (item) {
            console.log(item);
            this.$refs.categoryDeleteModal.show();
        },
        formSuccess (action) {
            this.$notify('Category Added Successfully', 'Success', {
                type: 'success'
            });
            this.showForm = false;
            const that = this;
            setTimeout(function () {
                that.showForm = true;
            }, 1000);
        },
        categoryAddEditFailure (response) {
            const errors = response.data.errors;
            for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                    const errorMessage = `${key}:  ${errors[key]}`;
                    this.$notify(errorMessage, 'Warning', { type: 'warning' });
                }
            }
        }

    }
};
</script>

<style scoped>

</style>

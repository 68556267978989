<template>
    <div>
        <container>
            <inner-page-card heading="ARTICLES">
                <!--Add Articles-->
                <b-form :save-url="addUrl" ref="form" @failure="FormFailure" @success="formSuccess('A')" v-slot="{model, loading}">
                    <row>
                        <col-12>
                            <validated-input label="Title" v-model="model.title" :rules="rules.title"
                                             :disabled="loading"/>
                        </col-12>
                        <col-12 class="fl-x">
                            <validated-input label="Author *:" class="col-6 pl-0" v-model="model.author" :rules="rules.author"
                                             :disabled="loading"/>
                            <validated-input label="Batch/Designation *:" class="col-6 pr-0" v-model="model.batch" :rules="rules.batch"
                                             :disabled="loading"/>
                        </col-12>
                        <col-12>
                            <save-btn loading-text="Saving..." size="sm" :disabled="loading"
                                      :loading="loading"/>
                        </col-12>
                    </row>
                </b-form>
            </inner-page-card>
        </container>
    </div>
</template>

<script>
import InnerPageCard from '../../../components/InnerPageCard';
import urls from '../../../data/urls';

export default {
    name: 'AddArticlePage',
    components: { InnerPageCard },
    data () {
        return {
            addUrl: urls.article.oldArticle.addEdit,
            rules: {
                author: {
                    required: true
                },
                batch: {
                    required: true
                }
            }
        };
    },
    methods: {
        formSuccess (action) {
            // const refs = this.$refs;
            // this.$refs.addModal.close();
            // this.$refs.editModal.close();
            if (action === 'A') {
                this.$notify('Article Added Successfully', 'Success', {
                    type: 'success'
                });
            } else {
                this.$notify('Article Updated Successfully', 'Success', {
                    type: 'success'
                });
            }
            const formValidator = this.$refs.form;
            formValidator.model = {};
            // refs.table.refreshTable();

            // this.editingItem = null;
        },
        FormFailure (response) {
            const errors = response.data.errors;
            console.log('errors', errors);
            for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                    const errorMessage = `${key}:  ${errors[key]}`;
                    this.$notify(errorMessage, 'Warning', { type: 'warning' });
                }
            }
        }
    }
};
</script>

<style scoped>

</style>

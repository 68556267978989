<template>
    <container>
        <inner-page-card heading="">
            <template #buttons>
                <slot name="buttons">
                    <btn icon="fa fa-plus" color="success" text="Descriptions" @click="viewDescription" border-radius="0"></btn>
                </slot>
                <slot name="buttons">
                    <btn icon="fa fa-eye" color="danger" text="Report" @click="viewReport" border-radius="0"></btn>
                </slot>
                <slot name="buttons">
                    <btn icon="fa fa-eye" color="success" text="Approved Treatment Suggestions" @click="viewTreatmentSuggestion" border-radius="0"></btn>
                </slot>
            </template>
            <div v-if="detailItem">
                <p class="font-weight-600 ">Title: <span
                    class="font-weight-lighter  text-2">{{detailItem.heading}}</span></p>
                <p class="font-weight-600 ">Species: <span
                    class="font-weight-lighter  text-2">{{detailItem.disease_cat}}</span></p>
            </div>
            <div v-else class="d-flex">
                <loading-animation style="margin-left: 34rem"></loading-animation>
                <p>Please wait...</p>
            </div>
            <inner-page-card :scrollbar="true" heading="IMAGES" class="mx-n4 mt-5">
                <template #buttons>
                    <slot name="buttons">
                        <btn icon="fa fa-plus" color="success" @click="ItemAdd" border-radius="0"></btn>
                           </slot>
                </template>
                        <modal width="50r" ref="addModal" title="Add Details">
                            <s-form @submit="addItem">
                            <row>
                                <div class="col-lg-4 mt-n2">
                                    <p class="font-weight-700 text-2 mb-2">File <span class='text-danger'>*</span>:</p>
                                    <validated-file-input :rules="{required: false}" v-model="model.image" border-radius="0"></validated-file-input>
                                </div>
                                <div class="col-lg-4 mt-n2">
                                    <validated-input label="Description :"  v-model="model.description"></validated-input>
                                </div>
                                <div class="col-lg-4 mt-n2">
                                    <validated-input label="Order :" type="number" v-model="model.order"></validated-input>
                                </div>
                                <col-12>
                                    <btn text="Submit" :loading="loading" loading-text="saving..."></btn>
                                </col-12>
                            </row>
                                </s-form>
                        </modal>
                <vue-table-card :fields="fields" :url="listURL" class="p-0"
                :per-page="10" ref="table" search-placeholder="Title" :extra-params="{disease_id : diseaseId}">
                    <template #actions="{rowData}">
                        <btn-group>
                            <edit-btn @click="EditItem(rowData)"/>
                            <delete-btn @click="setDeleteItem(rowData.id)"/>
                        </btn-group>
                    </template>
                    <template #footer>
                        <edit-modal ref="EditModal" width="55r">
                            <s-form @submit="addItem">
                                <row>
                                    <div class="col-lg-4 mt-n2">
                                        <p class="font-weight-700 text-2">Image <span class='text-danger'>*</span>: Currently:</p>
                                        <validated-file-input label="change:" v-model="editingItem.image" border-radius="0"></validated-file-input>
                                    </div>
                                    <div class="col-lg-4 mt-4">
                                        <validated-input label="Description :" v-model="editingItem.description"></validated-input>
                                    </div>
                                    <div class="col-lg-4 mt-4">
                                        <validated-input type="number" label="Order :" v-model="editingItem.order"></validated-input>
                                    </div>
                                    <col-12>
                                        <btn text="Submit" :loading="loading" loading-text="saving..."></btn>
                                    </col-12>
                                </row>
                            </s-form>
                        </edit-modal>
                        <delete-modal ref="diseaselistDeleteModal" :url="deleteURL" :params="{id : deletingItem}" @response="deleteSuccess">
                            <p>You are about to delete  <b> image </b>.<br> Are you sure about it
                                ?
                            </p>
                        </delete-modal>
                    </template>
                </vue-table-card>
            </inner-page-card>
        </inner-page-card>
    </container>
</template>

<script>
import tableFields from './disease-list-detailspage-table-fields';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'secure-axios';
import urls from '@/data/urls';

export default {
    name: 'DiseaseListDetailsPage',
    data () {
        return {
            loading: false,
            editingItem: '',
            deletingItem: '',
            addEditURL: urls.disease.diseaseImage.addEdit,
            deleteURL: urls.disease.diseaseImage.delete,
            listURL: urls.disease.diseaseImage.list,
            diseaseId: this.$route.params.id,
            detailItem: '',
            detailURL: urls.disease.disease.detail,
            fields: tableFields,
            data: [
                {
                    image: '/media/article/1580500463347_GASEOUS_ANESTHESIA_CIRCUIT..jpg',
                    description: 'jgfhdxdfcvg',
                    order: 'lorem afga AWH'
                }
            ],
            model: {
                disease: this.$route.params.id,
                order: '',
                image: '',
                description: ''
            },
            editor: ClassicEditor,
            editorData: '<p> </p>',
            editorConfig: {}
        };
    },
    mounted () {
        this.fetchData();
    },
    methods: {
        deleteSuccess (item) {
            this.$refs.diseaselistDeleteModal.close();
            if (item.success) {
                this.$notify('Deleted Successfully', 'Success', {
                    type: 'success'
                });
                this.$refs.table.refreshTable();
            } else {
                console.log('item error', item.errors.Id);
                this.$notify(item.errors.Id, 'warning', {
                    type: 'warning'
                });
            }
        },
        clearForm (item) {
            item.order = '';
            item.image = '';
            item.description = '';
        },
        async addItem () {
            var errorMessage = '';
            try {
                this.loading = true;
                if (this.editingItem) {
                    this.editingItem.disease = this.$route.params.id;
                    console.log('in edit');
                    this.response = await axios.form(this.addEditURL, this.editingItem);
                } else {
                    console.log('in add');

                    this.response = await axios.form(this.addEditURL, this.model);
                }
                const result = this.response.data;
                console.log('result', result);
                if (result.success === true) {
                    if (this.editingItem) {
                        this.$notify('Updated Successfully', 'Success', { type: 'success' });
                        this.loading = false;
                        this.editingItem = '';
                        this.$refs.EditModal.close();
                    } else {
                        this.$notify('Added Successfully', 'Success', { type: 'success' });
                        this.loading = false;
                        this.$refs.addModal.close();
                    }
                    this.$refs.table.refreshTable();
                } else {
                    const errors = this.response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            if (key === '__all__') {
                                errorMessage = `${errors[key]}`;
                            } else {
                                errorMessage = `${key}:  ${errors[key]}`;
                            }
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.this.response.status + ' ' + error.this.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        },
        async fetchData () {
            try {
                const response = await axios.form(this.detailURL, { id: this.diseaseId });
                const result = response.data;
                this.detailItem = result.data;
                console.log('detailItem', this.detailItem);
                if (result.success === true) {
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
            }
        },
        ItemAdd () {
            this.editingItem = '';
            this.clearForm(this.model);
            this.$refs.addModal.show();
        },
        EditItem (item) {
            console.log(item);
            this.editingItem = '';
            this.editingItem = { ...item };

            this.$refs.EditModal.show();
        },
        setDeleteItem (item) {
            console.log(item);
            this.deletingItem = '';
            this.deletingItem = item;
            this.$refs.diseaselistDeleteModal.show();
        },
        viewDescription (item) {
            console.log(item);
            this.$router.push('/description-detailspage/' + this.diseaseId + '/');
        },
        viewReport (item) {
            console.log(item);
            this.$router.push('/reportsdetailspage/' + this.diseaseId + '/');
        },
        viewTreatmentSuggestion (item) {
            console.log(item);
            this.$router.push('/treatment-suggestions/' + this.diseaseId + '/');
        }
    }
};
</script>

<style scoped>

</style>

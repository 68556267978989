const tableFields = [
    {
        name: 'indication_name',
        title: 'Indications',
        titleClass: '',
        sortField: 'indication_name',
        dataClass: 'w-20r'
    },
    {
        name: 'treatment_content',
        title: 'Treatment',
        titleClass: '',
        dataClass: 'w-20r'
    },
    {
        name: 'suggested_by_name',
        title: 'Suggested Person',
        titleClass: '',
        dataClass: 'w-20r'
    },
    {
        name: '__slot:actions',
        title: 'Actions',
        titleClass: '',
        dataClass: ''
    }
];

export default tableFields;

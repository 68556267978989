<template>
    <div>
        <container>
            <inner-page-card heading="Add Brands">
                <div class="h-80vh">
                    <transition-fade>
                        <div class="fl-x-cc h-100p" v-if="!showForm" key="anim">
                            <loading-animation></loading-animation>
                        </div>
                        <b-form v-else key="form" :save-url="addEditUrl" ref="formValidator" class="pb-4"
                                @failure="brandAddFormFailure" @success="formSuccess('A')" v-slot="{model, loading}">
                            <row>
                                <col-12>
                                    <validated-input label="Brand name <span class='text-danger'>*</span> :" v-model="model.brand_name" :rules="rules.brand_name"
                                                     :disabled="loading"></validated-input>
                                </col-12>
                                <col-12>
                                    <form-group label="Categories <span class='text-danger'>*</span>:">
                                        <ckeditor class="w-100p" :editor="editor" v-model="model.categories"
                                                  :rules="rules.categories"
                                                  :config="editorConfig" :disabled="loading"></ckeditor>
                                    </form-group>
                                </col-12>
                                <col-12>
                                    <form-group
                                        label="Contents <span class='text-danger'>*</span>:">
                                        <ckeditor class="w-100p" :editor="editor" v-model="model.contents"
                                                  :rules="rules.contents"
                                                  :config="editorConfig" :disabled="loading"></ckeditor>
                                    </form-group>
                                </col-12>
                                <col-12>
                                    <validated-input label="Company name <span class='text-danger'>*</span> :" v-model="model.company_name" :rules="rules.company_name"
                                                     :disabled="loading"></validated-input>
                                </col-12>
                                <col-12>
                                    <form-group
                                        label="Availability <span class='text-danger'>*</span>:">
                                        <ckeditor class="w-100p" :editor="editor" v-model="model.availability"
                                                  :rules="rules.availability"
                                                  :config="editorConfig" :disabled="loading"></ckeditor>
                                    </form-group>
                                </col-12>
                                <col-12>
                                    <form-group
                                        label="Composition <span class='text-danger'>*</span>:">
                                        <ckeditor class="w-100p" :editor="editor" v-model="model.composition"
                                                  :rules="rules.composition"
                                                  :config="editorConfig" :disabled="loading"></ckeditor>
                                    </form-group>
                                </col-12>
                                <col-12>
                                    <form-group
                                        label="Price <span class='text-danger'>*</span>:">
                                        <ckeditor class="w-100p" :editor="editor" v-model="model.price"
                                                  :rules="rules.price"
                                                  :config="editorConfig" :disabled="loading"></ckeditor>
                                    </form-group>
                                </col-12>
                                <col-12>
                                    <form-group
                                        label="Indication <span class='text-danger'>*</span>:">
                                        <ckeditor class="w-100p" :editor="editor" v-model="model.indication"
                                                  :rules="rules.indication"
                                                  :config="editorConfig" :disabled="loading"></ckeditor>
                                    </form-group>
                                </col-12>
                                <col-12>
                                    <form-group
                                        label="Dosage <span class='text-danger'>*</span>:">
                                        <ckeditor class="w-100p" :editor="editor" v-model="model.dosage"
                                                  :rules="rules.dosage"
                                                  :config="editorConfig" :disabled="loading"></ckeditor>
                                    </form-group>
                                </col-12>
                                <col-12>
                                    <save-btn loading-text="Saving..." size="sm" :disabled="loading"
                                              :loading="loading"/>
                                </col-12>
                            </row>
                        </b-form>
                    </transition-fade>
                </div>
            </inner-page-card>
        </container>
    </div>
</template>

<script>
import ckeditorSettings from '../../../components/ckeditor-settings';
import urls from '../../../data/urls';

export default {
    name: 'AddBrandsPage',
    data () {
        return {
            editor: ckeditorSettings.ClassicEditor,
            editorConfig: ckeditorSettings.editorConfig,
            addEditUrl: urls.drugs.brand.addEditUrl,
            rules: {
                brand_name: {
                    required: true
                },
                categories: {
                    required: true
                },
                contents: {
                    required: true
                },
                company_name: {
                    required: true
                },
                availability: {
                    required: true
                },
                composition: {
                    required: true
                },
                price: {
                    required: true
                },
                indication: {
                    required: true
                },
                dosage: {
                    required: true
                }

            },
            ckEditorInputs: {
                indications: false
            },
            showForm: true
        };
    },
    methods: {
        formSuccess (action) {
            console.log('form success');
            // const refs = this.$refs;
            // this.$refs.addModal.close();
            // this.$refs.editModal.close();
            if (action === 'A') {
                this.$notify('Brand Added Successfully', 'Success', {
                    type: 'success'
                });
            }
            this.showForm = false;
            const that = this;
            setTimeout(function () {
                that.showForm = true;
            }, 1000);
            // refs.table.refreshTable();

            // this.editingItem = null;
        },
        brandAddFormFailure (response) {
            const errors = response.data.errors;
            console.log('errors', errors);
            for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                    const errorMessage = `${key}:  ${errors[key]}`;
                    this.$notify(errorMessage, 'Warning', { type: 'warning' });
                }
            }
        }
    }
};
</script>

<style scoped>

</style>

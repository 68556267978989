const tableFields = [
    {
        name: 'image',
        title: 'Images',
        titleClass: '',
        sortField: 'image',
        dataClass: ''
    },
    {
        name: 'description',
        title: 'Description',
        titleClass: '',
        dataClass: ''
    },
    {
        name: 'order',
        title: 'Order',
        titleClass: '',
        dataClass: ''
    },
    {
        name: '__slot:actions',
        title: 'Actions',
        titleClass: '',
        dataClass: ''
    }
];

export default tableFields;

<template>
    <container>
        <table-page heading="Diseases">
            <vue-table-card :fields="fields" :url="listingURL"
                            :per-page="10" ref="table" search-placeholder="Title">
                <template #actions="{rowData}">
                    <btn-group>
                        <view-btn @click="ViewUser(rowData.id)"/>
                        <edit-btn @click="EditUser(rowData)"/>
                        <delete-btn @click="setDeleteUser(rowData)"/>
                    </btn-group>
                </template>
                <template #footer>
                    <edit-modal ref="diseaselistEditModal" title="Edit details">
                        <s-form @submit="addItem">
                            <row>
                                <div class="col-lg-6">
                                    <validated-input label="Heading <span class='text-danger'>*</span>:" v-model="editingItem.heading" :rules="{required:true}"></validated-input>
                                </div>
                                <div class="col-lg-6">
                                    <validated-ajax-select :url="catogaryURL" label="Species <span class='text-danger'>*</span>:" v-model="editingItem.disease_cat"
                                                          :rules="{required: true}"/>
                                </div>
                                <div class="col-lg-12">
                                    <submit-btn :loading="loading" loading-text="Updating..."></submit-btn>
                                </div>
                            </row>
                        </s-form>
                    </edit-modal>
                    <delete-modal ref="diseaselistDeleteModal" @response="deleteComplete" :url="deleteUrl" :params="{id : deletingItem.id}">
                        <p>You are about to delete {{deletingItem.heading}}.
                            Are you sure about it ?
                        </p>
                    </delete-modal>
                </template>
            </vue-table-card>

        </table-page>
    </container>
</template>

<script>
import tableFields from './disease-list-table-fields';
import urls from '@/data/urls';
import axios from 'secure-axios';

export default {
    name: 'DiseasesListPage',
    data () {
        return {
            loading: false,
            listingURL: urls.disease.disease.list,
            editingURL: urls.disease.disease.addEdit,
            deleteUrl: urls.disease.disease.delete,
            catogaryURL: urls.disease.diseaseCategory.vueSelect,
            editingItem: '',
            deletingItem: '',
            catogaryOptions: [],
            fields: tableFields,
            data: [
                {
                    heading: 'ANAPLASMOSIS IN GOAT & SHEEP',
                    disease_cat: 'Goat and Sheep'
                }
            ]
        };
    },
    mounted () {
        /*  this.fetchData(); */
    },

    methods: {
        deleteComplete (item) {
            this.$refs.diseaselistDeleteModal.close();
            if (item.success) {
                this.$notify('Deleted Successfully', 'Success', {
                    type: 'success'
                });
                this.$refs.table.refreshTable();
            } else {
                console.log('item error', item.errors.Id);
                this.$notify(item.errors.Id, 'warning', {
                    type: 'warning'
                });
            }
        },
        async addItem () {
            try {
                console.log('add funmction called');
                this.loading = true;
                const response = await axios.form(this.editingURL, this.editingItem);
                const result = response.data;
                console.log('result', result);
                if (result.success === true) {
                    this.$notify('Updated Successfully', 'Success', { type: 'success' });
                    this.loading = false;
                    this.$refs.diseaselistEditModal.close();
                    this.$refs.table.refreshTable();
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        },
        async fetchData () {
            try {
                const response = await axios.get(this.catogaryURL);
                const result = response.data.data;
                this.catogaryOptions = result.map(({ label }) => label);
                console.log('catogaryOptions', this.catogaryOptions);
                if (result.success === true) {
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
            }
        },
        ViewUser (item) {
            console.log(item);
            this.$router.push('/disease-list-details-page/' + item + '/');
        },
        EditUser (item) {
            console.log(item);
            this.editingItem = '';
            this.editingItem = { ...item };
            this.$refs.diseaselistEditModal.show();
        },
        setDeleteUser (item) {
            console.log(item);
            this.deletingItem = item;
            this.$refs.diseaselistDeleteModal.show();
        }
    }
};
</script>

<style scoped>

</style>

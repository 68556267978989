const tableFields = [
    {
        name: 'disease_name',
        title: 'Disease',
        titleClass: '',
        sortField: 'disease_name',
        dataClass: 'w-20r'
    },
    {
        name: 'treatment_content',
        title: 'Treatment',
        titleClass: '',
        sortField: 'treatment_content',
        dataClass: 'w-20r'
    },
    {
        name: '__slot:actions',
        title: 'Actions',
        titleClass: '',
        dataClass: ''
    }
];

export default tableFields;

const tableFields = [
    {
        name: 'brand',
        title: 'Brand Name',
        titleClass: '',
        dataClass: '',
        sortField: 'brand'

    },
    {
        name: 'recommended_by',
        title: 'Recommended By',
        titleClass: '',
        dataClass: '',
        sortField: 'recommended_by'

    }
];

const sampleData = [
    {
        id: '23',
        name: 'nj EUTHASOL®',
        recommended_by: 'krystal maharaj'
    },
    {
        id: '23',
        name: 'nj EUTHASOL®',
        recommended_by: 'Krishna prasad'
    },
    {
        id: '23',
        name: 'nj EUTHASOL®',
        recommended_by: 'naresh sahu'
    },
    {
        id: '23',
        name: 'nj EUTHASOL®',
        recommended_by: 'Dr Abhishek Asati'
    },
    {
        id: '23',
        name: 'nj EUTHASOL®',
        recommended_by: 'mohit kumar'
    },
    {
        id: '23',
        name: 'nj EUTHASOL®',
        recommended_by: 'vinay yadav'
    },
    {
        id: '23',
        name: 'nj EUTHASOL®',
        recommended_by: 'Abhishek Thakran'
    },
    {
        id: '23',
        name: 'nj EUTHASOL®',
        recommended_by: 'satish sadashive'
    },
    {
        id: '23',
        name: 'nj EUTHASOL®',
        recommended_by: 'Karthick Raja'
    },
    {
        id: '23',
        name: 'nj EUTHASOL®',
        recommended_by: 'baikunth mahto'
    }
];

export default { tableFields, sampleData };

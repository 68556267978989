const tableFields = [
    {
        name: 'preperation_name',
        title: 'Preperation',
        titleClass: '',
        dataClass: 'w-20r',
        sortField: 'preperation_name'
    },
    {
        name: 'treatment_content',
        title: 'Treatment',
        titleClass: '',
        dataClass: 'w-40r',
        sortField: 'treatment_content'
    }, {
        name: 'suggested_by',
        title: 'Suggested person',
        titleClass: '',
        dataClass: 'w-40r',
        sortField: 'suggested_by'

    },

    {
        name: '__slot:actions',
        title: 'Actions',
        titleClass: '',
        dataClass: ''
    }
];

export default tableFields;

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('container',[_c('inner-page-card',{attrs:{"heading":"LISTING"},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('add-btn',{on:{"click":function($event){return _vm.$refs.addModal.show()}}})]},proxy:true}])},[_c('vue-table-card',{ref:"table",attrs:{"url":_vm.listUrl,"fields":_vm.fields,"table-data":_vm.data},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var rowData = ref.rowData;
return [_c('btn-group',[_c('edit-btn',{on:{"click":function($event){_vm.setEdit (rowData)}}}),_c('delete-btn',{on:{"click":function($event){return _vm.setDeleteUser(rowData)}}})],1)]}},{key:"footer",fn:function(){return [_c('edit-modal',{ref:"addModal",attrs:{"width":"40r","title":"LISTING"}},[_c('b-form',{attrs:{"save-url":_vm.addUrl},on:{"failure":_vm.EditFailure,"success":function($event){return _vm.formSuccess('A')}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var model = ref.model;
var loading = ref.loading;
return [_c('row',[_c('div',{staticClass:"col-lg-6 pt-1"},[_c('validated-ajax-select',{staticClass:"mt-2 font-weight-700 text-2 v-select-dropdown-max-h-10r",attrs:{"label":"Company <span class='text-danger'>*</span>:","border-radius":"0","url":_vm.listVueSelect,"disabled":false,"rules":_vm.rules.company,"name":"Company"},model:{value:(model.company),callback:function ($$v) {_vm.$set(model, "company", $$v)},expression:"model.company"}})],1),_c('div',{staticClass:"col-lg-6 mt-2"},[_c('validated-input',{staticClass:"pt-1 pr-0",attrs:{"label":"Order <span class='text-danger'>*</span>:","type":"number","rules":_vm.rules.order,"disabled":loading},model:{value:(model.order),callback:function ($$v) {_vm.$set(model, "order", $$v)},expression:"model.order"}})],1),_c('col-12',[_c('submit-btn',{staticClass:"mt-7",attrs:{"loading-text":"Saving...","size":"sm","disabled":loading,"loading":loading}})],1)],1)]}}])})],1),_c('edit-modal',{ref:"EditModal"},[_c('b-form',{attrs:{"save-url":_vm.addUrl,"initial-data":_vm.editingItem},on:{"failure":_vm.userEditFailure,"success":function($event){return _vm.formSuccess('M')}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var model = ref.model;
var loading = ref.loading;
return [_c('row',[_c('div',{staticClass:"col-lg-6 pb-7"},[_c('validated-ajax-select',{staticClass:" pl-0 font-weight-700 text-2 v-select-dropdown-max-h-10r",attrs:{"label":"Company <span class='text-danger'>*</span>:","border-radius":0,"url":_vm.listVueSelect,"disabled":false,"rules":_vm.rules.company,"name":"Company"},model:{value:(model.company),callback:function ($$v) {_vm.$set(model, "company", $$v)},expression:"model.company"}})],1),_c('div',{staticClass:"col-lg-6"},[_c('validated-input',{staticClass:" pr-0",attrs:{"label":"Order <span class='text-danger'>*</span>:","type":"number","rules":_vm.rules.order,"disabled":loading},model:{value:(model.order),callback:function ($$v) {_vm.$set(model, "order", $$v)},expression:"model.order"}})],1),_c('col-12',[_c('submit-btn',{attrs:{"loading-text":"Saving...","size":"sm","disabled":loading,"loading":loading}})],1)],1)]}}])})],1),_c('delete-modal',{ref:"deleteModal",attrs:{"url":_vm.deleteUrl,"params":_vm.deletingItem},on:{"response":_vm.deleteComplete},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading-animation'),_vm._v(" Please wait while we delete "),_c('b',{domProps:{"innerHTML":_vm._s(_vm.deletingItem && _vm.deletingItem.company)}}),_vm._v(". ")]},proxy:true}])},[_c('p',[_vm._v("You are about to delete "),(_vm.deletingItem)?_c('b',[_vm._v(" "+_vm._s(_vm.deletingItem.company))]):_vm._e(),_vm._v(" Are you sure about it ?")])])]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
const tableFields = [
    {
        name: 'species_name',
        title: 'Species',
        sortField: 'species_name',
        titleClass: '',
        dataClass: 'w-30r'
    },
    {
        name: 'disease_name',
        title: 'Disease',
        sortField: 'disease',
        titleClass: '',
        dataClass: 'w-40r'
    },
    {
        name: 'clinical_signs',
        title: 'Notes',
        sortField: 'clinical_signs',
        titleClass: '',
        dataClass: 'w-40r'
    },

    {
        name: '__slot:actions',
        title: 'Actions',
        titleClass: '',
        dataClass: ''
    }
];

export default tableFields;

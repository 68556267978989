const tableFields = [
    {
        name: 'requested_disease',
        title: 'Disease',
        sortField: 'requested_disease',
        titleClass: '',
        dataClass: ''
    },
    {
        name: 'notes',
        title: 'Notes',
        sortField: 'notes',
        titleClass: '',
        dataClass: ''
    },
    {
        name: 'requested_by',
        title: 'Requested',
        sortField: 'requested_by',
        titleClass: '',
        dataClass: ''
    },
    {
        name: '__slot:actions',
        title: 'Actions',
        titleClass: '',
        dataClass: ''
    }
];

export default tableFields;

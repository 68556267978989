<template>
    <container>
        <inner-page-card heading="">
            <template #buttons>
                <slot name="buttons">
                    <btn icon="fa fa-plus" color="success" text="Descriptions" @click="viewDescription" border-radius="0"></btn>
                </slot>
            </template>
            <div>
                <p class="font-weight-600 ">Disease: <span
                    class="font-weight-lighter  text-2">{{detailItem.heading}}</span></p>
                <p class="font-weight-600 ">Species: <span
                    class="font-weight-lighter  text-2">{{detailItem.disease_cat}}</span></p>
            </div>
            <inner-page-card :scrollbar="true" heading="Treatments" class="mx-n4 mt-5">
                <vue-table-card :fields="fields" :url="listUrl" class="p-0"
                :per-page="10" ref="table" search-placeholder="Search" :extra-params="{disease_id : diseaseId}">
                    <template #actions="{rowData}">
                        <btn-group>
                            <edit-btn @click="itemEdit(rowData)"/>
                            <delete-btn @click="setDeleteItem(rowData)"/>
                        </btn-group>
                    </template>
                    <template #footer>
                        <edit-modal ref="treatmentEditModal" width="55r">
                            <s-form @submit="addItem">
                                <row>
                                    <div class="col-lg-6 mt-4">
                                        <validated-input label="Section :" v-model="editItem.section"></validated-input>
                                    </div>
                                    <div class="col-lg-6 mt-4">
                                        <validated-input  label="Treatment Content <span class='text-danger'>*</span>:" v-model="editItem.treatment_content" :rules="{required:true}"></validated-input>
                                    </div>
                                    <div class="col-lg-12 mt-4">
                                        <validated-ajax-select :url="diseaseUrl" label="Disease :" v-model="editItem.disease"></validated-ajax-select>
                                    </div>
                                    <div class="col-lg-12 mt-4">
                                        <validated-ajax-select :url="suggestVueselect"  label="Suggested by :" v-model="editItem.suggested_by"></validated-ajax-select>
                                    </div>
                                    <col-12>
                                        <btn text="Submit" :loading="loading" loading-text="saving..."></btn>
                                    </col-12>
                                </row>
                            </s-form>
                        </edit-modal>
                        <delete-modal ref="treatmentDeleteModal" @response="deleteSuccess" :url="deleteUrl" :params="{id:deleteItem.id}">
                            <p>You are about to delete <b>{{deleteItem.disease_name}}</b>.<br>. Are you sure about it
                                ?.
                            </p>
                        </delete-modal>
                    </template>
                </vue-table-card>
            </inner-page-card>
        </inner-page-card>
    </container>
</template>

<script>
import tableFields from './treatment-table-fields';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'secure-axios';
import urls from '@/data/urls';

export default {
    name: 'TreatmentSuggestions',
    data () {
        return {
            loading: false,
            diseaseUrl: urls.disease.disease.vueSelect,
            suggestVueselect: urls.disease.treatmentsAddSuggestionList.sugestedVueSelect,
            addEditUrl: urls.disease.treatmentsSuggestions.addEdit,
            editItem: [],
            listUrl: urls.disease.treatmentsSuggestions.list,
            detailItem: '',
            deleteItem: '',
            deleteUrl: urls.disease.treatmentsAddSuggestionList.delete,
            detailURL: urls.disease.disease.detail,
            diseaseId: this.$route.params.id,
            fields: tableFields,
            data: [
                {
                    disease: 'ryytiyi',
                    treatment_content: 'jgfhdxdfcvg',
                    suggested_by: 'Anil'

                }
            ],
            sectionOptions: [
                { label: '--------', value: '' },
                { label: 'Disease', value: 'disease' },
                { label: 'Indication', value: 'indication' },
                { label: 'Preperation', value: 'preperation' }
            ],
            editor: ClassicEditor,
            editorData: '<p> </p>',
            editorConfig: {}
        };
    },
    mounted () {
        this.fetchData();
    },
    methods: {

        deleteSuccess (item) {
            console.log('delete state', item);
            this.$refs.treatmentDeleteModal.close();
            this.$notify('Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        async fetchData () {
            try {
                const response = await axios.form(this.detailURL, { id: this.diseaseId });
                const result = response.data;
                this.detailItem = result.data;
                console.log('detailItem', this.detailItem);
                if (result.success === true) {
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
            }
        },
        async addItem () {
            try {
                this.loading = true;
                const response = await axios.form(this.addEditUrl, this.editItem);
                const result = response.data;
                console.log('result', result);
                if (result.success === true) {
                    this.$notify('Updated Successfully', 'Success', { type: 'success' });
                    this.loading = false;
                    this.$refs.treatmentEditModal.close();
                    this.$refs.table.refreshTable();
                    this.$emit('success');
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        },
        itemEdit (item) {
            console.log(item);
            this.editItem = '';
            this.editItem = item;
            this.$refs.treatmentEditModal.show();
        },
        setDeleteItem (item) {
            console.log(item);
            this.deleteItem = item;
            this.$refs.treatmentDeleteModal.show();
        },
        viewDescription (item) {
            console.log(item);
            this.$router.push('/description-detailspage/' + this.diseaseId + '/');
        }
    }
};
</script>

<style scoped>

</style>

const usersTableFields = [
    {
        name: 'name',
        title: 'Name',
        titleClass: 'w-10r',
        dataClass: '',
        sortField: 'name'
    },
    {
        name: 'designation',
        title: 'Designation',
        titleClass: 'w-10r',
        dataClass: '',
        sortField: 'designation'
    },
    {
        name: 'department',
        title: 'Department',
        titleClass: '',
        dataClass: '',
        sortField: 'department'
    },
    {
        name: 'college',
        title: 'College',
        titleClass: '',
        dataClass: '',
        sortField: 'college'
    },
    {
        name: 'category',
        title: 'Category',
        titleClass: 'w-10r',
        dataClass: '',
        sortField: 'category'
    },
    {
        name: 'phone',
        title: 'Phone',
        titleClass: '',
        dataClass: '',
        sortField: 'phone'
    },
    {
        name: '__slot:actions',
        title: 'Actions',
        titleClass: 'text-right',
        dataClass: 'text-right'
    }
];

export default usersTableFields;

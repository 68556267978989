<template>
    <container>
        <div v-if="loading">
            <div class="fl-x-cc">
                <loading-animation></loading-animation>
            </div>
        </div>
        <inner-page-card :heading="detailsData.drug_name" v-else>
            <div>
                <div class="font-weight-600">Categories</div>
                <ul class="mt-3 ml-5">
                    <li class="text-2" v-for="(item,i) in  detailsData.drug_category" :key="i">{{item.name}}</li>
                </ul>
                <div class="font-weight-600 mt-3">Action:</div>
                <div class="mt-3 text-2" v-html="detailsData.drug_action"></div>
                <!--                <ul class="mt-3 ml-5">-->
                <!--                    <li class="text-2">Phenothiazine derivative having depressant effect on CNS and various central neurotransmitter receptors</li>-->
                <!--                </ul>-->
                <div class="font-weight-600 mt-3">Indications:</div>
                <div class="mt-3 text-2" v-html="detailsData.indications"></div>
                <!--                <ul class="mt-3 ml-5">-->
                <!--                    <li class="text-2">Produce mild to moderate sedation of short duration, Preanaesthetic, anti-emetic, behaviour modifying agent, to alleviate itching as a result-->
                <!--                        of skin irritation-->
                <!--                    </li>-->
                <!--                </ul>-->
                <div class="font-weight-600 mt-3">Dose:</div>
                <div class="mt-3 text-2" v-html="detailsData.dose"></div>
                <!--                <router-link to="/" class="text-2 font-weight-600">For sedation</router-link>-->
                <!--                <div class="text-2">-->
                <!--                    -DOGS - 0.01-0.02 mg/kg slow IV, 0.01 - 0.05 mg/kg IM, SC, 1-3 mg/kg PO <br/>-->
                <!--                    -DOGS - 0.01-0.02 mg/kg slow IV, 0.01 - 0.05 mg/kg IM, SC, 1-3 mg/kg PO <br>-->
                <!--                    -HORSE : 0.04-0.1 mg/kg IM, IV, SC <br>-->
                <!--                    -SWINE : 0.03-0.5 mg/kg IM, IV, SC <br>-->
                <!--                    -ELEPHANT : 0.004–0.06 mg/kg IM or IV <br>-->
                <!--                </div>-->
                <div class="font-weight-600 mt-3">Contraindications:</div>
                <div class="mt-2 text-2" v-html="detailsData.contraindications">
                </div>
                <div class="font-weight-600 mt-3">Available Brands:</div>
                <div class="text-2 mt-2" v-html="detailsData.available_brands"></div>
                <div class="font-weight-600 mt-3">Added by: <span class="text-2 font-weight-normal">{{detailsData.added_by}}</span></div>
            </div>
            <!--            Brands-->
            <inner-page-card heading="BRANDS" class="mx-n4 mt-4">
                <template #buttons>
                    <add-btn @click="$refs.addBrandModal.show()"/>
                </template>
                <vue-table-card :fields="fields" :url="brandListUrl" class="p-0" ref="table" :extra-params="{drug_id : drugId}">
                    <template #actions="{rowData}">
                        <btn-group>
                            <view-btn @click="viewBrandDetails(rowData)"/>
                            <edit-btn @click="SetEdit(rowData)"/>
                            <delete-btn @click="setDeleteItem(rowData)"/>
                        </btn-group>
                    </template>
                    <template #footer>
                        <!--Edit Brand Modal-->
                        <edit-modal ref="brandEditModal" width="55r">
                            <s-form @submit="brandAddEditSubmit('M')" v-if="editingItem">
                                <row>
                                    <col-12>
                                        <validated-input label="Brand name <span class='text-danger'>*</span> :"
                                                         v-model="editingItem.brand_name" :rules="rules.drug_name"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12>
                                        <form-group label="Categories <span class='text-danger'>*</span> :">
                                            <ckeditor class="w-100p" :editor="editor" v-model="editingItem.categories" :config="editorConfig"></ckeditor>
                                        </form-group>
                                    </col-12>
                                    <col-12>
                                        <form-group label="Contents <span class='text-danger'>*</span> :">
                                            <ckeditor class="w-100p" :editor="editor" v-model="editingItem.contents" :config="editorConfig"></ckeditor>
                                        </form-group>
                                    </col-12>
                                    <col-12>
                                        <validated-input label="Company name <span class='text-danger'>*</span> :" v-model="editingItem.company_name"></validated-input>
                                    </col-12>
                                    <col-12>
                                        <form-group label="Availability <span class='text-danger'>*</span> :">
                                            <ckeditor class="w-100p" :editor="editor" v-model="editingItem.availability" :config="editorConfig"></ckeditor>
                                        </form-group>
                                    </col-12>
                                    <col-12>
                                        <form-group label="Composition <span class='text-danger'>*</span> :">
                                            <ckeditor class="w-100p" :editor="editor" v-model="editingItem.composition" :config="editorConfig"></ckeditor>
                                        </form-group>
                                    </col-12>
                                    <col-12>
                                        <form-group label="Price <span class='text-danger'>*</span> :">
                                            <ckeditor class="w-100p" :editor="editor" v-model="editingItem.price" :config="editorConfig"></ckeditor>
                                        </form-group>
                                    </col-12>
                                    <col-12>
                                        <form-group label="Indication <span class='text-danger'>*</span> :">
                                            <ckeditor class="w-100p" :editor="editor" v-model="editingItem.indication" :config="editorConfig"></ckeditor>
                                        </form-group>
                                    </col-12>
                                    <col-12>
                                        <form-group label="Dosage <span class='text-danger'>*</span> :">
                                            <ckeditor class="w-100p" :editor="editor" v-model="editingItem.dosage" :config="editorConfig"></ckeditor>
                                        </form-group>
                                    </col-12>
                                    <col-12>
                                        <save-btn loading-text="Saving..." :disabled="loading"
                                                  :loading="loading"/>
                                    </col-12>
                                </row>
                            </s-form>
                        </edit-modal>
                        <!--Add Brand Modal-->
                        <modal ref="addBrandModal" title="Add" width="55r">
                            <s-form @submit="brandAddEditSubmit('A')">
                                <row>
                                    <col-12>
                                        <validated-input label="Brand name <span class='text-danger'>*</span> :"
                                                         v-model="model.brand_name" :rules="rules.brand_name"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12>
                                        <form-group label="Categories <span class='text-danger'>*</span> :">
                                            <ckeditor class="w-100p" :editor="editor" v-model="model.categories" :config="editorConfig"></ckeditor>
                                        </form-group>
                                    </col-12>
                                    <col-12>
                                        <form-group label="Contents <span class='text-danger'>*</span> :">
                                            <ckeditor class="w-100p" :editor="editor" v-model="model.contents" :config="editorConfig"></ckeditor>
                                        </form-group>
                                    </col-12>
                                    <col-12>
                                        <validated-input label="Company name <span class='text-danger'>*</span> :" v-model="model.company_name"></validated-input>
                                    </col-12>
                                    <col-12>
                                        <form-group label="Availability <span class='text-danger'>*</span> :">
                                            <ckeditor class="w-100p" :editor="editor" v-model="model.availability" :config="editorConfig"></ckeditor>
                                        </form-group>
                                    </col-12>
                                    <col-12>
                                        <form-group label="Composition <span class='text-danger'>*</span> :">
                                            <ckeditor class="w-100p" :editor="editor" v-model="model.composition" :config="editorConfig"></ckeditor>
                                        </form-group>
                                    </col-12>
                                    <col-12>
                                        <form-group label="Price <span class='text-danger'>*</span> :">
                                            <ckeditor class="w-100p" :editor="editor" v-model="model.price" :config="editorConfig"></ckeditor>
                                        </form-group>
                                    </col-12>
                                    <col-12>
                                        <form-group label="Indication <span class='text-danger'>*</span> :">
                                            <ckeditor class="w-100p" :editor="editor" v-model="model.indication" :config="editorConfig"></ckeditor>
                                        </form-group>
                                    </col-12>
                                    <col-12>
                                        <form-group label="Dosage <span class='text-danger'>*</span> :">
                                            <ckeditor class="w-100p" :editor="editor" v-model="model.dosage" :config="editorConfig"></ckeditor>
                                        </form-group>
                                    </col-12>
                                    <col-12>
                                        <save-btn loading-text="Saving..." :disabled="loading"
                                                  :loading="loading"/>
                                    </col-12>
                                </row>
                            </s-form>
                        </modal>
                        <delete-modal ref="brandDeleteModal">
                            <p>You are about to delete brand <b>Brand Name</b>. Are you sure about it
                                ?
                            </p>
                        </delete-modal>
                        <delete-modal ref="brandDeleteModal" :url="brandDeleteUrl" :params="deletingItem" @response="deleteComplete">
                            <p>You are about to delete the category
                                <b v-if="deletingItem"> {{ deletingItem.name }}</b>.Are you sure?</p>
                            <template #loading>
                                <loading-animation/>
                                Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
                            </template>
                        </delete-modal>
                    </template>
                </vue-table-card>
            </inner-page-card>
        </inner-page-card>
    </container>
</template>

<script>
import brandsTableFields from './brands-table-fields';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'secure-axios';
import urls from '../../../../data/urls';

export default {
    name: 'ViewDrugsPage',
    data () {
        return {
            fields: brandsTableFields,
            detailUrl: urls.drugs.drug.detail,
            brandListUrl: urls.drugs.brand.listUrl,
            brandAddEditUrl: urls.drugs.brand.addEditUrl,
            brandDeleteUrl: urls.drugs.brand.delete,
            data: [
                {
                    id: '23',
                    name: 'test',
                    added_by: 'Site Administrator'
                },
                {
                    id: '23',
                    name: 'test',
                    added_by: 'Site Administrator'
                }
            ],
            drugId: this.$route.params.id,
            editor: ClassicEditor,
            // model: '',
            detailsData: null,
            loading: false,
            editingItem: null,
            deletingItem: {
                name: ''
            },
            editorConfig: {
                // The configuration of the editor.
                // toolbar: {
                //     items: [
                //         'heading',
                //         '|',
                //         'bold',
                //         'italic',
                //         '|',
                //         'bulletedList',
                //         'numberedList',
                //         '|',
                //         'undo',
                //         'redo'
                //     ]
                // }
            },
            rules: {
                brand_name: {
                    required: true
                },
                categories: {
                    required: true
                },
                contents: {
                    required: true
                },
                company_name: {
                    required: true
                },
                availability: {
                    required: true
                },
                composition: {
                    required: true
                },
                price: {
                    required: true
                },
                indication: {
                    required: true
                },
                dosage: {
                    required: true
                }
            },
            model: {
                drug: this.drugId,
                brand_name: '',
                categories: '',
                contents: '',
                company_name: '',
                availability: '',
                composition: '',
                price: '',
                indication: '',
                dosage: ''
            }
        };
    },
    mounted () {
        this.loadData();
    },
    methods: {
        SetEdit (item) {
            // console.log(item);
            this.editingItem = { ...item };
            // this.editingItem.categories = item.category;
            this.$refs.brandEditModal.show();
        },
        setDeleteItem (item) {
            // console.log(item);
            this.deletingItem = { ...item };
            this.$refs.brandDeleteModal.show();
        },
        async loadData () {
            const that = this;
            this.loading = true;
            const response = await axios.form(
                this.detailUrl, { id: this.drugId }
            ).catch(function (exception) {
                that.serverError();
            });
            const json = response.data;
            const dataItem = json.data;
            this.detailsData = dataItem;
            this.loading = false;
        },
        viewBrandDetails (item) {
            console.log(item);
            this.$router.push('/brand-details/' + item.id);
        },
        brandAddFormSuccess (action) {
            const refs = this.$refs;
            this.$refs.addBrandModal.close();
            this.$refs.brandEditModal.close();
            if (action === 'A') {
                this.$notify('Brand Added Successfully', 'Success', {
                    type: 'success'
                });
            } else {
                this.$notify('Brand Updated Successfully', 'Success', {
                    type: 'success'
                });
            }
            refs.table.refreshTable();
            //
            this.editingItem = null;
        },
        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.brandDeleteModal.close();
            this.$notify('Brand Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        async brandAddEditSubmit (type) {
            const that = this;
            // this.loading = true;
            // this.invalidCredential = false;
            this.model.drug = this.drugId;
            let response = null;
            if (type === 'A') {
                response = await axios.form(
                    this.brandAddEditUrl, this.model
                ).catch(function (exception) {
                    that.serverError();
                });
            }
            if (type === 'M') {
                this.editingItem.drug = this.drugId;
                response = await axios.form(
                    this.brandAddEditUrl, this.editingItem
                ).catch(function (exception) {
                    that.serverError();
                });
            }

            const responseData = await response.data;
            if (responseData.success) {
                if (type === 'A') {
                    this.$notify('Brand Added Successfully', 'Success', {
                        type: 'success'
                    });
                }
                if (type === 'M') {
                    this.$notify('Brand updated Successfully', 'Success', {
                        type: 'success'
                    });
                }
                this.$refs.table.refreshTable();
                this.$refs.addBrandModal.close();
                this.$refs.brandEditModal.close();
            }
            if (responseData.error) {
                // const errors = this.response.data.errors;
                const errors = responseData.errors;

                for (const key in errors) {
                    if (Object.prototype.hasOwnProperty.call(errors, key)) {
                        const errorMessage = `${key}:  ${errors[key]}`;
                        this.$notify(errorMessage, 'Warning', { type: 'warning' });
                    }
                }
                this.$refs.table.refreshTable();

                this.loading = false;
            }
        },
        serverError () {
            console.error('Server error');
        },
        brandAddFormFailure (response) {
            const errors = response.data.errors;
            // console.log('errors');
            for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                    const errorMessage = `${key}:  ${errors[key]}`;
                    this.$notify(errorMessage, 'Warning', { type: 'warning' });
                }
            }
        }

    }
};
</script>

<style scoped>

</style>

<template>
    <div class="">
        <container py="5">
            <div class="row">
                <div class="col-12">
                </div>
                <div class="col-lg-6">
                    <dashboard-btn :text="'Pending Approval ('+ items.pending + ')'"  @click="gotoRoute('/account/list-pending-approval/')"></dashboard-btn>
                    <dashboard-btn text="Add Drug"  class="w-100p mt-4 py-3" @click="gotoRoute('/view-drugs/')"></dashboard-btn>
                    <dashboard-btn text="Add Brands" class="w-100p mt-4 py-3" @click="gotoRoute('/drug-index/add-brands/')"></dashboard-btn>
                    <dashboard-btn text="Add Disease" class="w-100p mt-4 py-3" @click="gotoRoute('/add-disease/')"></dashboard-btn>
                    <dashboard-btn :text="'Disease Request ('+ items.request_diseases + ')'" class="w-100p mt-4 py-3" @click="gotoRoute('/disease-requests/')"></dashboard-btn>
                </div>
                 <div class="col-lg-6">
                     <dashboard-btn  :text="'Pending Reports ('+items.report + ')'" class="w-100p py-3" @click="gotoRoute('/issue-reported/')"></dashboard-btn>
                    <dashboard-btn text="Add Categories" class="w-100p mt-4 py-3" @click="gotoRoute('/drug-index/add-category/')"></dashboard-btn>
                     <dashboard-btn text="Add Articles" class="w-100p mt-4 py-3" @click="gotoRoute('/add-article/')"/>
                     <dashboard-btn :text="'Treatments Suggestions ('+ items.treatment_suggestions +')'" class="w-100p mt-4 py-3" @click="gotoRoute('/treatments-suggestions/')"/>
                     <dashboard-btn :text="'Disease Reports ('+ items.disease_reports +')'" class="w-100p mt-4 py-3" @click="gotoRoute('/disease-issues-reported/')" />
                </div>
            </div>
        </container>
    </div>

</template>

<script>
import DashboardBtn from './ui-components/dashboard-btn';
import urls from '@/data/urls';
import axios from 'secure-axios';
export default {
    name: 'Home',
    components: { DashboardBtn },
    data () {
        return {
            detailUrl: urls.dashBord.details,
            items: ''
        };
    },
    mounted () {
        this.fetchData();
    },
    methods: {
        gotoRoute (path) {
            // alert(path);
            this.$router.push(path);
        },

        async fetchData () {
            try {
                const response = await axios.get(this.detailUrl);
                const result = response.data.data;
                this.items = result;
                console.log('detailItem', this.result);
                if (result.success === true) {
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
            }
        }
    }

};

</script>
<style scoped>

</style>

<template>
<div class="container ml-0">
    <table-page heading="Pending Approval">
        <vue-table-card ref="table" :url="listUrl" :fields="fields" class="class">
            <template #actions="{rowData}">
                <btn-group>
                    <view-btn @click="viewUser(rowData)"/>
                    <approve-btn-success @click="ApproveUser(rowData)"/>
                    <delete-btn @click="setDeleteUser(rowData)"/>
                    <reject-btn @click="SetRejectUser(rowData)"/>
                </btn-group>
            </template>
            <template #footer>
                <delete-modal ref="userDeleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
                    <p>You are about to delete user
                        <b v-if="deletingItem"> {{ deletingItem.username }}</b>. Are you sure about it ?</p>
                    <template #loading>
                        <loading-animation/>
                        Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
                    </template>
                </delete-modal>
                <activate-modal ref="userActivateModal" ok-button="Approve" :url="userApproveUrl" :params="approvingItem" @response="approveComplete">
                    <p>You are about to Approve the User <b>{{approvingItem.username}}</b>. Are you sure
                        ?
                    </p>
                    <template #loading>
                        <loading-animation/>
                        Please wait while we approving <b v-html="approvingItem && approvingItem.name"></b>.
                    </template>
                </activate-modal>
                <modal width="35r" title="User Info" ref="userViewModal">
                    <details-card :heading="'Username : ' + selectedUserItem.username" v-if="selectedUserItem">
                        <details-list>
                            <details-list-item label="First Name" :value="selectedUserItem.first_name"/>
                            <details-list-item label="Last Name" :value="selectedUserItem.last_name"/>
                            <details-list-item label="College name" :value="selectedUserItem.college"/>
                            <details-list-item label="Batch" :value="selectedUserItem.batch"/>
                            <details-list-item label="Designation" :value="selectedUserItem.designation"/>
                            <details-list-item label="Email" :value="selectedUserItem.email"/>
                            <details-list-item label="Phone number" :value="selectedUserItem.phone_number"/>
                            <details-list-item label="Registration number" :value="selectedUserItem.reg_no"/>
                            <details-list-item label="Country" :value="selectedUserItem.country"/>
                            <details-list-item label="Place" :value="selectedUserItem.place"/>
                        </details-list>
                    </details-card>
                </modal>
                <!--                Reject User Modal-->
                <modal width="35r" title="Reject User" ref="rejectUserModal">
                    <s-form @submit="RejectFormSubmit">
                        <row>
                            <col-12>
                                <validated-text-area-lg v-model="rejectModel.reject_reason" :rules="rejectFormRules.reject_reason"
                                                        label="Reject Reason"/>
                            </col-12>
                            <col-12>
                                <validated-checkbox v-model="rejectModel.reject_mail_send" :rules="rejectFormRules.reject_mail_send"
                                                    label="Reject mail send"/>
                            </col-12>
                            <col-12>
                                <btn text="Reject" loading-text="Rejecting..." size="sm" :disabled="rejectUserLoading"
                                      :loading="rejectUserLoading"/>
                            </col-12>
                        </row>
                    </s-form>
                </modal>
            </template>
        </vue-table-card>
    </table-page>
</div>
</template>

<script>
import pendingUsersTableFields from './pending-users-table-fields';
import urls from '../../../data/urls';
import axios from 'secure-axios';

export default {
    name: 'TablePageFormat',
    data () {
        return {
            fields: pendingUsersTableFields,
            listUrl: urls.userManagement.users.pendingApprovalList,
            detailsUrl: urls.userManagement.users.detail,
            userApproveUrl: urls.userManagement.users.usersApprove,
            deleteUrl: urls.userManagement.users.delete,
            rejectUrl: urls.userManagement.users.usersReject,
            selectedUserItem: null,
            approvingItem: {
                name: ''
            },
            deletingItem: {
                name: ''
            },
            rejectingItem: {
                name: ''
            },
            rejectFormRules: {
                reject_reason: {
                    required: false
                },
                reject_mail_send: {
                    required: false
                }
            },
            rejectModel: {
                id: '',
                reject_reason: '',
                reject_mail_send: false
            },
            rejectUserLoading: false
        };
    },
    methods: {
        SetRejectUser (item) {
            console.log(item);
            this.rejectingItem = { ...item };
            this.ResetRejectModel();
            this.$refs.rejectUserModal.show();
        },
        ResetRejectModel () {
            this.rejectModel = {
                id: '',
                reject_reason: '',
                reject_mail_send: false
            };
        },
        ApproveUser (item) {
            this.approvingItem = { ...item };
            // this.approvingItem.id = 0;
            this.$refs.userActivateModal.show();
        },
        setDeleteUser (item) {
            this.deletingItem = { ...item };
            this.$refs.userDeleteModal.show();
        },
        async viewUser (item) {
            console.log(item);
            const that = this;
            const response = await axios.form(
                this.detailsUrl, { id: item.id }
            ).catch(function (exception) {
                that.serverError();
            });
            const json = response.data;
            const dataItem = json.data;
            // console.log('json', json.data.first_name);
            this.selectedUserItem = dataItem;
            this.$refs.userViewModal.show();
        },
        approveComplete (response) {
            // this.deletingItem = null;
            // this.$refs.userActivateModal.close();
            // this.$notify('User Approved Successfully', 'Success', {
            //     type: 'success'
            // });
            if (response.error === true) {
                this.$notify({
                    message: response.message ? response.message : 'Failed to approve user',
                    title: 'Failed',
                    type: 'danger'
                });
            } else {
                this.$notify({
                    message: response.message ? response.message : 'User approved Successfully',
                    title: 'Success',
                    type: 'success'
                });
            }
            this.$refs.userActivateModal.close();
            this.$refs.table.refreshTable();
        },
        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.userDeleteModal.close();
            this.$notify('User Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        RejectFormSuccess (action) {
            const refs = this.$refs;
            this.$refs.rejectUserModal.close();
            // this.$refs.editModal.close();
            if (action === 'A') {
                this.$notify('User Rejected Successfully', 'Success', {
                    type: 'success'
                });
            } else {
                this.$notify('User Rejected Successfully', 'Success', {
                    type: 'success'
                });
            }
            refs.table.refreshTable();// refresh vue table after form action
        },
        removeNullText (item) {
            if (item === 'null') {
                return '';
            }
        },
        async RejectFormSubmit () {
            const that = this;
            this.rejectUserLoading = true;
            // this.invalidCredential = false;
            this.rejectModel.id = this.rejectingItem.id;
            const response = await axios.form(
                this.rejectUrl, this.rejectModel
            ).catch(function (exception) {
                that.serverError();
            });
            this.$refs.rejectUserModal.close();
            console.log('reject response', response.data);
            const responseData = response.data;
            if (responseData.success) {
                this.$notify('User Rejected Successfully', 'Success', {
                    type: 'success'
                });
            }
            if (responseData.error) {
                console.log('resp data', responseData);
                this.$notify(
                    responseData.message || 'Something Went Wrong..!!',
                    'Error',
                    { type: 'warning' }
                );
            }
            this.$refs.table.refreshTable();
            this.rejectModel = {};
            this.rejectUserLoading = false;
        }

    }
};
</script>

<style scoped>
.class{
    margin-right: -405px;
}
</style>

const tableFields = [
    {
        name: 'heading',
        title: 'Heading',
        titleClass: '',
        dataClass: 'w-30r',
        sortField: 'heading'
    },
    {
        name: 'description',
        title: 'Description',
        titleClass: '',
        dataClass: 'w-40r',
        sortField: 'description'
    },
    {
        name: '__slot:actions',
        title: 'Actions',
        titleClass: '',
        dataClass: ''
    }
];

export default tableFields;

<template>
    <container>
        <inner-page-card heading="PREPERATIONS">
            <div v-if="detailsData">
                <p class="font-weight-600 ">Preperation : <span
                    class="font-weight-lighter  text-2"> {{ detailsData.preperation_name }}</span></p>

                <div class="font-weight-600 mt-3">Content :</div>
                <div v-html="detailsData.content" class="mt-2  text-2"></div>
                 <div class="font-weight-600 mt-3">Indication :</div>
                <div v-html="detailsData.indication_name" class="mt-2  text-2">
                </div>
            </div>
            <inner-page-card heading="SUGGESTIONS" class="mx-n4 mt-5">

                <vue-table-card :fields="fields" params="preparations"
                                :url="suggestionListUrl" ref="table" class="p-0">
                    <template #actions="{rowData}">
                        <btn-group>
                            <edit-btn @click="setEdit(rowData)"/>
                            <approve-btn @click="setApprove(rowData)"/>
                            <delete-btn @click="setDelete(rowData)"/>

                        </btn-group>
                    </template>
                    <template #footer>
                        <edit-modal ref="editModal" width="55r">
                            <b-form :save-url="suggestionAddEditUrl" @failure="userEditFailure"
                                    @success="formSuccess('M')"
                                    v-slot="{model, loading}"
                                    :initial-data="editingItem">
                                <row>
                                    <div class="col-lg-6 mt-n2">
                                        <validated-ajax-select label="Preparation <span class='text-danger'>*</span>:"
                                                               :url="VueSelectUrl"
                                                               :disabled="false" v-model="model.preperations"
                                                               :rules="rules.preperations"
                                                               name="Preperation name"></validated-ajax-select>
                                    </div>
                                    <div class="col-lg-6">
                                        <form-group label="Treatment content <span class='text-danger'>*</span>:">
                                            <ckeditor class="w-100p" :editor="editor" v-model="model.treatment_content"
                                                      :rules="rules.treatment_content"
                                                      :disabled="loading" :config="editorConfig"></ckeditor>
                                        </form-group>
                                    </div>
                                    <col-12>
                                        <submit-btn loading-text="Saving..." size="sm" :disabled="loading"
                                                    :loading="loading"></submit-btn>
                                    </col-12>
                                </row>
                            </b-form>
                        </edit-modal>
                        <!--                        <approve-modal ref="approveModal" :url="suggestionApproveUrl" :params="approvingItem"-->
                        <!--                                       @response="approveComplete">-->
                        <!--                            <p>You are about to approve a suggestion. Are you sure about it?</p>-->
                        <!--                            <template #loading>-->
                        <!--                                <loading-animation/>-->
                        <!--                                Please wait while we approve <b-->
                        <!--                                v-html="approvingItem && approvingItem.preperation_name"></b>.-->
                        <!--                            </template>-->
                        <!--                        </approve-modal>-->
                        <approve-modal ref="approveModal" :url="suggestionApproveUrl" :params="approvingItem"
                                       @response="approveComplete">
                            <p>You are about to approve a suggestion for <b
                                v-if="approvingItem && approvingItem.preparation_name">{{ approvingItem.preparation_name }}</b>.
                                Are you sure about it?</p>
                            <template #loading>
                                <loading-animation/>
                                Please wait while we approve <b v-if="approvingItem && approvingItem.preparation_name">{{approvingItem.preparation_name}}</b>.
                            </template>
                        </approve-modal>
                        <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem"
                                      @response="deleteComplete">
                            <p>You are about to delete the suggestion
                                <b v-if="deletingItem"> {{ deletingItem.preparations }}</b>.Are you sure?</p>
                            <template #loading>
                                <loading-animation/>
                                Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
                            </template>
                        </delete-modal>
                    </template>
                </vue-table-card>
            </inner-page-card>
        </inner-page-card>
    </container>
</template>

<script>
import PreperationDetailsPage from './preparation-detailspage-field';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ApproveModal from '@components/modals/ApproveModal';
import urls from '../../../../data/urls';
import axios from 'secure-axios';

export default {
    name: 'PreperationDetailsPage',
    components: { ApproveModal },
    data () {
        return {
            fields: PreperationDetailsPage,
            suggestionListUrl: urls.pharmacy.preparation.suggestionList + '?preparation=' + this.$route.params.id,
            detailsUrl: urls.pharmacy.preparation.detail,
            addEditUrl: urls.pharmacy.preparation.addEdit,
            deleteUrl: urls.pharmacy.preparation.suggestionDelete,
            suggestionAddEditUrl: urls.pharmacy.preparation.suggestionAddEdit,
            VueSelectUrl: urls.pharmacy.preparation.vueSelect,
            suggestionApproveUrl: urls.pharmacy.preparation.suggestionApprove,
            data: [
                {
                    preparation: 'alfredo-',
                    treatment: 'lorem afga AWH',
                    suggested_person: '2'
                }
            ],
            itemId: this.$route.params.id,
            editor: ClassicEditor,
            editorData: '<p> </p>',
            editorConfig: {},
            detailsData: null,
            approvingItem: null,
            editingItem: { preperation: '', treatment_content: '', id: '' },
            tableData: [],
            approveitem: '',
            deletingItem: {
                name: ''
            },
            rules: {
                preperations: {
                    required: true
                },
                content: {
                    required: true
                }
            }

        };
    },
    mounted () {
        this.loadDetails(this.itemId);
        this.loadTableData(this.itemId);
    },
    methods:
        {
            formSuccess (action) {
                this.$refs.editModal.close();
                if (action === 'A') {
                    this.$notify('Suggestion Added Successfully', 'Success', { type: 'success' });
                } else {
                    this.$notify('Suggestion Updated Successfully', 'Success', { type: 'success' });
                }
                this.$refs.table.refreshTable();
            },
            setEdit (item) {
                // this.editingItem = { ...item };
                this.editingItem.preperations = item.preperations;
                this.editingItem.id = item.id;
                this.editingItem.treatment_content = item.treatment_content;
                this.$refs.editModal.show();
            },
            setDelete (item) {
                this.deletingItem = { ...item };
                this.$refs.deleteModal.show();
            },
            async loadDetails (item) {
                console.log(item);
                const that = this;
                const response = await axios.form(
                    this.detailsUrl, { id: item }
                ).catch(function (exception) {
                    that.serverError();
                });
                const json = response.data;
                const dataItem = json.data;
                this.detailsData = dataItem;
            },
            deleteComplete (response) {
                this.deletingItem = null;
                this.$refs.deleteModal.close();
                this.$notify('suggestion Deleted Successfully', 'Success', {
                    type: 'success'
                });
                this.$refs.table.refreshTable();
            },
            async loadTableData (item) {
                try {
                    const response = await axios.post(this.suggestionListUrl, { id: item });
                    this.tableData = response.data.data;
                } catch (exception) {
                    this.serverError();
                }
            },
            // setApprove (item) {
            //     this.approvingItem = { id: item.id };
            //     this.$refs.approveModal.show();
            // },
            setApprove (item) {
                this.approvingItem = { id: item.id, preparation_name: item.preperation_name };
                this.$refs.approveModal.show();
            },
            approveComplete () {
                this.approvingItem = null;
                this.$refs.approveModal.close();
                this.$notify('Suggestion Approved Successfully', 'Success', { type: 'success' });
                this.$refs.table.refreshTable();
            },
            userEditFailure (response) {
                const errors = response.data.errors;
                for (const key in errors) {
                    if (Object.prototype.hasOwnProperty.call(errors, key)) {
                        const errorMessage = `${key}:  ${errors[key]}`;
                        this.$notify(errorMessage, 'Warning', { type: 'warning' });
                    }
                }
            }

        }
};
</script>

<style scoped>

</style>

<template>
    <table-page heading="Staff Users">
        <vue-table-card :fields="fields" :url="listUrl" ref="table">
            <template #actions="{rowData}">
                <btn-group>
                    <ban-btn text="Ban" @click="BanStaff(rowData)"/>
                    <edit-btn text="Edit" @click="setEdit(rowData)"/>
                </btn-group>
            </template>
            <template #footer>
                 <edit-modal ref="staffEditModal">
                    <b-form :save-url="addEditUrl" @success="StaffEditFormSuccess('M')" v-slot="{model, loading}"
                            :initial-data="editingItem">
                        <row>
                            <col-12>
                                <validated-input label="Username <span class='text-danger'>*</span> :"
                                                 v-model="model.username" :rules="rules.username"
                                                 :disabled="true"></validated-input>
                            </col-12>
                            <col-12>
                                <validated-input label="First name" v-model="model.first_name"
                                                 :rules="rules.first_name" :disabled="loading"></validated-input>
                            </col-12>
                            <col-12>
                                <validated-input label="Last name" v-model="model.last_name" :rules="rules.last_name"
                                                 :disabled="loading"></validated-input>
                            </col-12>
                            <col-12>
                                <validated-input label="Email <span class='text-danger'>*</span> :"
                                                 v-model="model.email"
                                                 :rules="rules.email" :disabled="loading"></validated-input>
                                <form-info>Required</form-info>
                            </col-12>
                            <col-12>
                                <validated-input label="Designation"
                                                 v-model="model.designation" :rules="rules.designation"
                                                 :disabled="loading"></validated-input>
                            </col-12>
                            <col-12>
                                <validated-input label="College" v-model="model.college" :rules="rules.college"
                                                 :disabled="loading"></validated-input>
                            </col-12>
                            <col-12>
                                <validated-input label="Batch" v-model="model.batch" :rules="rules.batch"
                                                 :disabled="loading"></validated-input>
                            </col-12>
                            <col-12>
                                <validated-input label="Reg no" v-model="model.reg_no" :rules="rules.reg_no"
                                                 :disabled="loading"></validated-input>
                            </col-12>
                            <col-12>
                                <validated-input label="Phone number" v-model="model.phone_number" :rules="rules.phone_number"
                                                 :disabled="loading"></validated-input>
                            </col-12>
                            <col-12>
                                <validated-input label="Country:"
                                                 v-model="model.country" :rules="rules.country"
                                                 :disabled="loading"></validated-input>
                            </col-12>
                            <col-12>
                                <validated-input label="Place" v-model="model.place" :rules="rules.place"
                                                 :disabled="loading"></validated-input>
                            </col-12>
                            <col-12>
                                <change-btn loading-text="Saving..." :disabled="loading"
                                            :loading="loading"/>
                            </col-12>
                        </row>
                    </b-form>
                </edit-modal>
            </template>
        </vue-table-card>
    </table-page>
</template>

<script>
import usersTableFields from './staff-users-table-fields';
import urls from '../../../data/urls';
import axios from 'secure-axios';
export default {
    name: 'TablePageFormat',
    data () {
        return {
            fields: usersTableFields,
            listUrl: urls.userManagement.staff.list,
            banUrl: urls.userManagement.staff.staffBan,
            // addEditUrl: urls.userManagement.staff.addEdit,
            addEditUrl: urls.userManagement.users.addEdit,
            editingItem: null,
            data: [
                {
                    id: '23',
                    username: 'gokul',
                    first_name: 'Gokul',
                    last_name: 'Das'
                }
            ],
            rules: {
                username: {
                    required: true
                },
                first_name: {
                    required: false
                },
                last_name: {
                    required: false
                },
                email: {
                    required: true
                },
                designation: {
                    required: false
                },
                college: {
                    required: false
                },
                batch: {
                    required: false
                },
                reg_no: {
                    required: false
                },
                phone_number: {
                    required: false
                },
                country: {
                    required: false
                },
                place: {
                    required: false
                }
            }
        };
    },
    methods: {
        setEdit (item) {
            this.editingItem = { ...item };
            // this.editingItem.phone_number = '';
            this.$refs.staffEditModal.show();
        },
        async BanStaff (item) {
            // ban user post method
            const that = this;
            const response = await axios.form(this.banUrl, { id: item.id })
                .catch(function (exception) {
                    that.serverError();
                });
            const responseData = await response.data;
            // console.log('response', responseData);
            if (responseData.success) {
                this.$notify('Staff is banned Successfully', 'Success', {
                    type: 'success'
                });
                // this.$router.push('/banned-users/');
            }
            if (responseData.error) {
                that.$notify('Error found..!', 'Error',
                    {
                        type: 'warning',
                        duration: 3000,
                        permanent: false
                    }
                );
            }
            this.$refs.table.refreshTable();
        },
        StaffEditFormSuccess (action) {
            // const refs = this.$refs;
            // this.$refs.addModal.close();
            this.$refs.staffEditModal.close();
            if (action === 'A') {
                this.$notify('Staff Added Successfully', 'Success', {
                    type: 'success'
                });
                this.$refs.table.refreshTable();
            } else {
                this.$notify('Staff Updated Successfully', 'Success', {
                    type: 'success'
                });
            }
            this.$refs.table.refreshTable();
            this.editingItem = null;
        }
    }
};
</script>

<style scoped>

</style>

<template>
    <div>
        <div class="container">
            <div class="fl-y fl-a-c">
                <p class="pl-2"><span class="font-proxima-bold text-left">Normal Values </span>: {{normal_values.species_name}}</p>
                <details-card class="w-40r" :heading="'Name : ' + normal_values.species_name" v-if="normal_values && normal_values.species_name">
                    <details-list>
                        <details-list-item label="Species Name" :value="normal_values.species_name"/>
                        <details-list-item label="Lifespan" :value="normal_values.lifespan"/>
                        <details-list-item label="Gestation Age" :value="normal_values.gestation_age"/>
                        <details-list-item label="Heart Rate" :value="normal_values.heart_rate"/>
                        <details-list-item label="Temperature" :value="normal_values.temperature"/>
                        <details-list-item label="Pulse" :value="normal_values.pulse"/>
                        <details-list-item label="Total Protein" :value="normal_values.total_protein"/>
                        <details-list-item label="Albumin" :value="normal_values.albumin"/>
                        <details-list-item label="Globulin" :value="normal_values.globulin"/>
                         <details-list-item label="Glucose" :value="normal_values.glucose"/>
                        <details-list-item label="Respiration" :value="normal_values.respiration"/>
                        <details-list-item label="BUN" :value="normal_values.bun"/>
                        <details-list-item label="Creatine" :value="normal_values.creatinine"/>
                        <details-list-item label="Calcium" :value="normal_values.calcium"/>
                        <details-list-item label="HGB" :value="normal_values.hgb"/>
                        <details-list-item label="PCV" :value="normal_values.pcv"/>
                        <details-list-item label="RBC" :value="normal_values.rbc"/>
                        <details-list-item label="WBC" :value="normal_values.wbc"/>
                        <details-list-item label="Reticulocytes" :value="normal_values.reticulocytes"/>
                        <details-list-item label="Absolute Reticulocytes Count" :value="normal_values.absolute_reticulocytes_count"/>
                        <details-list-item label="MCV" :value="normal_values.mcv"/>
                        <details-list-item label="MCH" :value="normal_values.mch"/>
                        <details-list-item label="MCHC" :value="normal_values.mchc"/>
                        <details-list-item label="Platelets" :value="normal_values.platelets"/>
                        <details-list-item label="MPV" :value="normal_values.mpv"/>
                         <details-list-item label="Neutrophils Segmented" :value="normal_values.neutrophils_segmented"/>
                        <details-list-item label="Neutrophils Band" :value="normal_values.neutrophils_band"/>
                        <details-list-item label="Lymphocytes" :value="normal_values.lymphocytes"/>
                        <details-list-item label="Monocytes" :value="normal_values.monocytes"/>
                        <details-list-item label="Eosinophils" :value="normal_values.eosinophils"/>
                        <details-list-item label="Basophils" :value="normal_values.basophils"/>
                        <details-list-item label="ALT" :value="normal_values.alt"/>
                        <details-list-item label="AST" :value="normal_values.ast"/>
                        <details-list-item label="GGT" :value="normal_values.ggt"/>
                        <details-list-item label="LDH" :value="normal_values.ldh"/>
                        <details-list-item label="SDH" :value="normal_values.sdh"/>
                        <details-list-item label="Bicarbonate" :value="normal_values.bicarbonate"/>
                         <details-list-item label="Amylase" :value="normal_values.amylase"/>
                        <details-list-item label="Alk Phos" :value="normal_values.alk_phos"/>
                        <details-list-item label="Bilirubin" :value="normal_values.bilirubin"/>
                        <details-list-item label="Chloride" :value="normal_values.chloride"/>
                         <details-list-item label="Cholesterol" :value="normal_values.cholesterol"/>
                        <details-list-item label="Magnesium" :value="normal_values.magnesium"/>
                        <details-list-item label="Phosphorus" :value="normal_values.phosphorus"/>
                        <details-list-item label="Potassium" :value="normal_values.potassium"/>
                        <details-list-item label="Sodium" :value="normal_values.sodium"/>
                        <details-list-item label="Urea" :value="normal_values.urea"/>
                        <details-list-item label="Rumen Fluid ph" :value="normal_values.rumen_fluid_ph"/>
                        <details-list-item label="Blood ph" :value="normal_values.blood_ph"/>
                        <details-list-item label="Milk ph" :value="normal_values.milk_ph"/>
                        <details-list-item label="Urine ph" :value="normal_values.urine_ph"/>
                    </details-list>
                </details-card>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../data/urls';

export default {
    name: 'NormalValuesDetailsPage',
    data () {
        return {
            DetailsListUrl: urls.diagnosis.normalValues.DetailsList,
            normal_values: null,
            idx: this.$route.params.id
        };
    },
    mounted () {
        this.viewInfo(this.idx);
    },
    methods: {
        async viewInfo (idx) {
            // console.log(item);
            const that = this;
            const response = await axios.form(
                this.DetailsListUrl, { id: idx }
            ).catch(function (exception) {
                that.serverError();
            });
            const json = response.data;
            const dataItem = json.data;
            console.log('json', json.data);
            this.normal_values = dataItem;
            console.log('normal-values', this.normal_values);
            // this.$refs.transactionViewModal.show();
            // this.makeRefundClicked = false;
        }
    }
};
</script>

<style scoped>

</style>

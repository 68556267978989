<template>
    <div>
        <container>
            <h3>UI Components</h3>
            <tabs>
                <tab title="Form Items">
                    <h4>Form Input</h4>
                    <validated-input/>
                    <code-container :code-str="codeStore.formInput.code"></code-container>
                </tab>
                <tab title="Buttons">
                    <h4>Buttons</h4>
                    <btn-group mode="basic">
                        <view-btn/>
                        <edit-btn/>
                        <delete-btn/>
                        <approve-btn/>
                        <ban-btn/>
                        <unban-btn/>
                        <save-btn/>
                        <submit-btn/>
                    </btn-group>
                    <code-container :code-str="codeStore.buttons.code"/>
                </tab>
                <tab title="Modals">
                    <h4>DeleteModal</h4>
                    <delete-modal ref="sampleDeleteModal"/>
                    <btn @click="openDeleteModal">Open Delete Modal</btn>
                    <code-container :code-str="codeStore.deleteModal.code"/>
                    <h4>Approve Modal</h4>
                    <approve-modal ref="sampleApproveModal">
                        <p>You are about to approve the Plan <b>Plan Name</b>. Are you sure
                            ?
                        </p>
                    </approve-modal>
                    <btn @click="openApproveModal">Open Approve Modal</btn>
                    <code-container :code-str="codeStore.approveModal.code"></code-container>
                    <h4>Activate Modal</h4>
                    <activate-modal ref="activateModal">
                        <p>You are about to activate the Plan <b>Plan Name</b>. Are you sure
                            ?
                        </p>
                    </activate-modal>
                    <btn @click="$refs.activateModal.show()">Open Activate Modal</btn>
                    <code-container :code-str="codeStore.activateModal.code"></code-container>
                </tab>
                <tab title="Pages">
                    <h4>Table Page</h4>
                    <table-page heading="Page Heading">
                        <vue-table-card></vue-table-card>
                    </table-page>
                    <code-container :code-str="codeStore.tablePage.code"/>
                    <h4>Inner Page Card</h4>
                    <inner-page-card heading="Heading">
                        <s-form></s-form>
                    </inner-page-card>
                    <code-container :code-str="codeStore.innerPageCard.code"/>
                    <vue-table></vue-table>
                    <h4>Table Page Format</h4>
                    <table-page-format></table-page-format>
                    <code-container :code-str="codeStore.tablePageFormat.code"/>
                    <h5>Table Fields Format : <span class="text-secondary font-weight-normal">normalvalue-table-fields.js</span></h5>
                    <code-container :code-str="codeStore.tableFieldsFormat.code"/>
                </tab>
                <tab title="Lists">
                    <h5 class="my-3">Details List</h5>
                    <details-list>
                        <details-list-item label="First Name" value="Victor"/>
                        <details-list-item label="Last Name" value="rotich"/>
                    </details-list>
                    <code-container :code-str="codeStore.detailsListItem.code"/>
                    <h5 class="my-3">With Custom Label Width</h5>
                    <details-list>
                        <details-list-item label-width="10r" label="First Name" value="Victor"/>
                        <details-list-item label-width="10r" label="Last Name" value="rotich"/>
                    </details-list>
                    <code-container :code-str="codeStore.detailsListItemWithLabelWidth.code"/>
                </tab>
            </tabs>
        </container>
    </div>
</template>

<script>
import DeleteModal from '../../../components/modals/DeleteModal';
import ApproveModal from '../../../components/modals/ApproveModal';
import ApproveBtn from '../../../components/buttons/approve-btn';
import BanBtn from '../../../components/buttons/ban-btn';
import UnbanBtn from '../../../components/buttons/unban-btn';
import codeStore from './code-store/ui-items.json';
import TablePageFormat from './table-page-format/TablePageFormat';

export default {
    name: 'UiComponentsPage',
    components: { TablePageFormat, UnbanBtn, BanBtn, ApproveBtn, DeleteModal, ApproveModal },
    methods: {
        openDeleteModal () {
            this.$refs.sampleDeleteModal.show();
        },
        openApproveModal () {
            this.$refs.sampleApproveModal.show();
        }
    },
    data () {
        return {
            codeStore: codeStore
        };
    }
};
</script>

<style scoped>

</style>

const tableFields = [
    {
        name: 'title',
        title: 'Title',
        titleClass: '',
        dataClass: 'w-10r',
        sortField: 'title'

    },
    {
        name: 'image',
        title: 'Image',
        titleClass: '',
        dataClass: '',
        sortField: 'image'
    },
    {
        name: 'url',
        title: 'Url',
        titleClass: '',
        dataClass: '',
        sortField: 'url'
    },
    {
        name: 'position',
        title: 'Position',
        titleClass: '',
        dataClass: '',
        sortField: 'position'
    },
    {
        name: 'position_number',
        title: 'Position Number',
        titleClass: '',
        dataClass: '',
        sortField: 'position_number'
    },
    {
        name: '__slot:actions',
        title: 'Actions',
        titleClass: '',
        dataClass: ''
    }
];

export default tableFields;

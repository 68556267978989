<template>
    <div>
        <div class="container">
            <inner-page-card heading="Plans">
                <template #buttons>
                    <add-btn @click="$refs.addPlanModal.show()"/>
                </template>
                <vue-table-card :url="listUrl" :fields="fields" ref="table">
                    <template #actions="{rowData}">
                        <btn-group>
                            <view-btn @click="viewPlanDetails(rowData)"/>
                            <edit-btn @click="setEdit(rowData)"/>
                            <activate-btn @click="ActivatePlan(rowData)"/>
                            <delete-btn @click="SetDelete(rowData)"/>
                        </btn-group>
                    </template>
                    <template #footer>
                        <!--Delete Modal-->
                        <delete-modal ref="planDeleteModal" :url="deleteUrl" :params="deletingItem"
                                      @response="deleteComplete">
                            <p>You are about to delete the plan
                                <b v-if="deletingItem && deletingItem.name"> {{ deletingItem.name }}</b>.Are you sure?</p>
                            <template #loading>
                                <loading-animation/>
                                Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
                            </template>
                        </delete-modal>
                        <!--                        Activate Modal-->
                        <activate-modal ref="planActivateModal" @response="PlanActivateSuccess"
                                        :url="activatePlanUrl" :params="activatePlanItem">
                            <p>You are about to activate the Plan {{activatePlanItem.name}}.
                                Are you sure?
                            </p>
                            <template #loading>
                                <loading-animation/>
                                Please wait while we activate the plan...
                            </template>
                        </activate-modal>
                        <!--                        Plan Info Modal-->
                        <modal width="35r" title="Plan Info" ref="planViewModal">
                            <details-card :heading="'Plan : ' + selectedPlanItem.name" v-if="selectedPlanItem">
                                <details-list>
                                    <details-list-item label="Description" :value="selectedPlanItem.description"/>
                                    <details-list-item label="Value" :value="selectedPlanItem.value"/>
                                    <details-list-item label="Expiry Months" :value="selectedPlanItem.expiry_months"/>
                                    <details-list-item label="Plan Type" :value="getPlanType(selectedPlanItem.plan_type)"/>
                                    <details-list-item label="Offer Saving Price" :value="selectedPlanItem.offer_saving"/>
                                    <details-list-item label="Offer Percentage" :value="selectedPlanItem.offer_percentage"/>
                                </details-list>
                            </details-card>
                        </modal>
                        <!--                        Add Modal-->
                        <modal width="75r" title="Add" ref="addPlanModal">
                            <b-form :save-url="addUrl" @failure="AddEditPlanFormError" @success="formSuccess('A')" v-slot="{model, loading}">
                                <row>
                                    <col-12 lg="6">
                                        <validated-input label="Name <span class='text-danger'>*</span>:"
                                                         v-model="model.name" :rules="rules.name"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input v-model="model.description" :rules="rules.description"
                                                         :disabled="loading"
                                                         label="Description <span class='text-danger'>*</span>"></validated-input>
                                    </col-12>
                                    <col-12 lg="4">
                                        <validated-input v-model="model.value" :rules="rules.value" :disabled="loading"
                                                         label="Value <span class='text-danger'>*</span>:"></validated-input>
                                    </col-12>
                                    <col-12 lg="4">
                                        <validated-input v-model="model.expiry_months" :rules="rules.expiry_months"
                                                         :disabled="loading"
                                                         label="Expiry Months <span class='text-danger'>*</span>:"></validated-input>
                                    </col-12>
                                    <col-12 lg="4">
                                        <validated-select :options="planOptions" v-model="model.plan_type"
                                                          :rules="rules.plan_type" :disabled="loading"
                                                          label="Plan Type <span class='text-danger'>*</span>:"></validated-select>
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input v-model="model.offer_percentage" :rules="rules.offer_percentage"
                                                         :disabled="loading" label="Offer percentage"></validated-input>
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input v-model="model.offer_saving" :rules="rules.offer_saving"
                                                         :disabled="loading" label="Offer savings"></validated-input>
                                    </col-12>
                                    <col-12>
                                        <submit-btn loading-text="Saving..." size="sm" :disabled="loading"
                                                    :loading="loading"></submit-btn>
                                    </col-12>
                                </row>
                            </b-form>
                        </modal>
                        <!--                        Edit Modal-->
                        <modal width="45r" title="Edit" ref="editPlanModal">
                            <b-form :save-url="addUrl" @failure="AddEditPlanFormError" @success="formSuccess('M')" v-slot="{model, loading}"
                                    :initial-data="editingItem">
                                <row>
                                    <col-12 lg="6">
                                        <validated-input label="Name <span class='text-danger'>*</span>:"
                                                         v-model="model.name" :rules="rules.name"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input v-model="model.description" :rules="rules.description"
                                                         :disabled="loading"
                                                         label="Description <span class='text-danger'>*</span>"></validated-input>
                                    </col-12>
                                    <col-12 lg="4">
                                        <validated-input v-model="model.value" :rules="rules.value" :disabled="loading"
                                                         label="Value <span class='text-danger'>*</span>:"></validated-input>
                                    </col-12>
                                    <col-12 lg="4">
                                        <validated-input v-model="model.expiry_months" :rules="rules.expiry_months"
                                                         :disabled="loading"
                                                         label="Expiry Months <span class='text-danger'>*</span>:"></validated-input>
                                    </col-12>
                                    <col-12 lg="4">
                                        <validated-select :options="planOptions" v-model="model.plan_type"
                                                          :rules="rules.plan_type" :disabled="loading"
                                                          label="Plan Type <span class='text-danger'>*</span>:"></validated-select>
                                    </col-12>
                                    <col-12>
                                        <submit-btn loading-text="Updating..." size="sm" :disabled="loading"
                                                    :loading="loading"></submit-btn>
                                    </col-12>
                                </row>
                            </b-form>
                        </modal>
                    </template>
                </vue-table-card>
            </inner-page-card>
        </div>
    </div>
</template>

<script>
import usersTableFields from './plans-for-users-table-fields';
import urls from '../../../data/urls';
import axios from 'secure-axios';

export default {
    name: 'TablePageFormat',
    data () {
        return {
            fields: usersTableFields,
            listUrl: urls.userManagement.plan.list,
            detailsUrl: urls.userManagement.plan.detail,
            addUrl: urls.userManagement.plan.addEdit,
            deleteUrl: urls.userManagement.plan.delete,
            activatePlanUrl: urls.userManagement.plan.activate,
            editingItem: null,
            selectedPlanItem: null,
            activatePlanItem: {
                id: ''
            },
            deletingItem: {
                name: ''
            },
            planOptions: [
                {
                    label: 'Basic',
                    value: '1'
                }, {
                    label: 'Standard',
                    value: '2'
                }, {
                    label: 'Premium',
                    value: '3'
                }
            ],
            // rules for validation
            rules: {
                name: {
                    required: true
                },
                description: {
                    required: true
                },
                value: {
                    required: true
                },
                expiry_months: {
                    required: true
                },
                plan_type: {
                    required: true
                },
                offer_percentage: {
                    required: false
                },
                offer_saving: {
                    required: false
                }
            }
        };
    },
    methods: {
        ActivatePlan (item) {
            // this.activatePlanItem.id = item.id;
            this.activatePlanItem = { ...item };
            this.$refs.planActivateModal.show();
        },
        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.editPlanModal.show();
        },
        SetDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.planDeleteModal.show();
        },
        getPlanType (planType) {
            // set plan type text from the value set on options
            const filteredPlan = this.planOptions.filter(plan => plan.value === planType);
            return filteredPlan[0].label;
        },
        async viewPlanDetails (item) {
            console.log(item);
            const that = this;
            const response = await axios.form(
                this.detailsUrl, { id: item.id }
            ).catch(function (exception) {
                that.serverError();
            });
            const json = response.data;
            const dataItem = json.data;
            // console.log('json', json.data.first_name);
            this.selectedPlanItem = dataItem;
            this.$refs.planViewModal.show();
        },
        formSuccess (action) {
            const refs = this.$refs;
            this.$refs.addPlanModal.close();
            this.$refs.editPlanModal.close();
            if (action === 'A') {
                this.$notify('Plan Added Successfully', 'Success', {
                    type: 'success'
                });
            } else {
                this.$notify('Plan Updated Successfully', 'Success', {
                    type: 'success'
                });
            }
            refs.table.refreshTable();

            this.editingItem = null;
        },
        PlanActivateSuccess (response) {
            if (response.error === true) {
                this.$notify({
                    message: response.message ? response.message : 'Failed to activate plan',
                    title: 'Failed',
                    type: 'danger'
                });
            } else {
                this.$notify({
                    message: response.message ? response.message : 'Plan Activated Successfully',
                    title: 'Success',
                    type: 'success'
                });
            }
            // this.activatePlanItem = null;
            this.$refs.planActivateModal.close();
            this.$refs.table.refreshTable();
        },
        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.planDeleteModal.close();
            this.$notify('Plan Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        AddEditPlanFormError (response) {
            const errors = response.data.errors;
            for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                    const errorMessage = `${key}:  ${errors[key]}`;
                    this.$notify(errorMessage, 'Warning', { type: 'warning' });
                }
            }
        }

    }
};
</script>

<style scoped>

</style>

import { render, staticRenderFns } from "./PreperationsPage.vue?vue&type=template&id=2b9e7b63&scoped=true&"
import script from "./PreperationsPage.vue?vue&type=script&lang=js&"
export * from "./PreperationsPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b9e7b63",
  null
  
)

export default component.exports
<template>
    <div>
        <container>
            <inner-page-card heading="Drugs">
                <template #buttons>
                    <add-btn @click="redirectToAddDrugFormPage"></add-btn>
                </template>
                <vue-table-card :fields="fields" :url="listUrl" ref="table">
                    <template #actions="{rowData}">
                        <btn-group>
                            <view-btn @click="viewDetails(rowData)"/>
                            <edit-btn @click="SetEdit(rowData)"/>
                            <delete-btn @click="setDeleteItem(rowData)"/>
                        </btn-group>
                    </template>
                    <template #footer>
                        <!--                        Add Contact Modal-->
                        <!--                        Edit Contact Modal-->
                        <edit-modal ref="drugEditModal" width="55r">
                            <b-form :save-url="addEdit" @failure="drugAddEditFormFailure" @success="drugAddFormSuccess('M')" v-slot="{model, loading}"
                                    :initial-data="editingItem">
                                <row>
<!--                                    <col-12>-->
<!--                                        <add-btn color="secondary" size="sm" text="Add Category" @click="$refs.addCategoryModal.show()"/>-->
<!--                                    </col-12>-->
                                    <col-12 mt="2">
                                        <validated-ajax-select :url="drugCategorySelectUrl" label="Select Category <span class='text-danger'>*</span> :"
                                                               v-model="model.drug_category" :rules="drugRules.drug_category"
                                                               :disabled="loading"></validated-ajax-select>
                                    </col-12>
                                    <col-12>
                                        <validated-input label="Drug name <span class='text-danger'>*</span> :"
                                                         v-model="model.drug_name" :rules="drugRules.drug_name"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12>
                                        <form-group ck-editor-size="md" label="Drug action <span class='text-danger'>*</span> :">
                                            <ckeditor class="w-100p" :editor="editor" v-model="model.drug_action" :rules="drugRules.drug_action" :config="editorConfig"></ckeditor>
                                        </form-group>
                                    </col-12>
                                    <col-12>
                                        <form-group ck-editor-size="md" label="Indications <span class='text-danger'>*</span> :">
                                            <ckeditor class="w-100p" :editor="editor" v-model="model.indications" :rules="drugRules.indications" :config="editorConfig"></ckeditor>
                                        </form-group>
                                    </col-12>
                                    <col-12>
                                        <form-group ck-editor-size="md" label="Dose <span class='text-danger'>*</span> :">
                                            <ckeditor class="w-100p" :editor="editor" v-model="model.dose" :rules="drugRules.dose" :disabled="loading"
                                                      :config="editorConfig"></ckeditor>
                                        </form-group>
                                    </col-12>
                                    <col-12>
                                        <form-group ck-editor-size="md" label="Contraindications <span class='text-danger'>*</span> :">
                                            <ckeditor class="w-100p" :editor="editor" v-model="model.contraindications"
                                                      :rules="drugRules.contraindications" :disabled="loading" :config="editorConfig"></ckeditor>
                                        </form-group>
                                    </col-12>
                                    <col-12>
                                        <form-group ck-editor-size="md" label="Available brands <span class='text-danger'>*</span> :">
                                            <ckeditor class="w-100p" :editor="editor" v-model="model.available_brands"
                                                      :rules="drugRules.available_brands" :disabled="loading" :config="editorConfig"></ckeditor>
                                        </form-group>
                                    </col-12>
                                    <col-12>
                                        <save-btn loading-text="Saving..." :disabled="loading"
                                                  :loading="loading"/>
                                    </col-12>
                                </row>
                            </b-form>
                        </edit-modal>
                        <!--                        Delete Modal-->
                        <delete-modal ref="drugDeleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
                            <p>You are about to delete the drug
                                <b v-if="deletingItem"> {{ deletingItem.name }}</b>.Are you sure?</p>
                            <template #loading>
                                <loading-animation/>
                                Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
                            </template>
                        </delete-modal>
                    </template>
                </vue-table-card>
            </inner-page-card>
        </container>
    </div>
</template>

<script>
import usersTableFields from './drugs-table-fields';
import ckeditorSettings from '../../../components/ckeditor-settings';
import urls from '../../../data/urls';

export default {
    name: 'AddDrugsPage',
    data () {
        return {
            fields: usersTableFields,
            listUrl: urls.drugs.drug.list,
            deleteUrl: urls.drugs.drug.delete,
            addEdit: urls.drugs.drug.addEdit,
            drugCategorySelectUrl: urls.drugs.drugCategory.vueSelect,
            editor: ckeditorSettings.ClassicEditor,
            editorConfig: ckeditorSettings.editorConfig,
            editingItem: null,
            deletingItem: {
                name: ''
            },
            drugRules: {
                drug_category: {
                    required: true
                },
                drug_name: {
                    required: true
                },
                drug_action: {
                    required: true
                },
                indications: {
                    required: true
                },
                dose: {
                    required: true
                },
                contraindications: {
                    required: true
                },
                available_brands: {
                    required: true
                }
            }
        };
    },
    methods: {
        SetEdit (item) {
            this.editingItem = { ...item };
            this.$refs.drugEditModal.show();
        },
        setDeleteItem (item) {
            this.deletingItem = { ...item };
            this.$refs.drugDeleteModal.show();
        },
        viewDetails (item) {
            console.log(item);
            this.$router.push('/drug-details/' + item.id);
        },
        drugAddFormSuccess (action) {
            const refs = this.$refs;
            // this.$refs.addCategoryModal.close();
            this.$refs.drugEditModal.close();
            if (action === 'A') {
                this.$notify('Drug Added Successfully', 'Success', {
                    type: 'success'
                });
            } else {
                this.$notify('Drug Updated Successfully', 'Success', {
                    type: 'success'
                });
            }
            refs.table.refreshTable();
            //
            // this.editingItem = null;
        },
        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.drugDeleteModal.close();
            this.$notify('Drug Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        redirectToAddDrugFormPage () {
            this.$router.push('/drug-index/add-drugs/');
        },
        drugAddEditFormFailure (response) {
            const errors = response.data.errors;
            console.log('errors');
            for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                    const errorMessage = `${key}:  ${errors[key]}`;
                    this.$notify(errorMessage, 'Warning', { type: 'warning' });
                }
            }
        }

    }
};
</script>

<style scoped>

</style>

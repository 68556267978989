<template>
    <table-page heading="Indications Reports">

        <vue-table-card :fields="fields" :table-data="data" :url="reportUrl" ref="table">
            <template #actions="{rowData}">
                <btn-group>
                    <delete-btn @click="setDelete(rowData)"/>

                </btn-group>

            </template>
            <template #footer>
                <delete-modal ref="deleteModal" :url="reportDeleteUrl" :params="deletingItem"
                              @response="deleteComplete">
                    <p>You are about to delete the indication
                        <b v-if="deletingItem"> {{ deletingItem.indication }}</b>.Are you sure?</p>
                    <template #loading>
                        <loading-animation/>
                        Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
                    </template>
                </delete-modal>

            </template>

        </vue-table-card>
    </table-page>
</template>

<script>
import InnerPageCard from '@components/InnerPageCard';
import IndicationsIssueReportedPage from '@/views/pharmacy/indications-issue-reported/indication-issues-fields';
import urls from '@/data/urls';

export default {
    name: 'IndicationsIssuesReportedPage',
    components: InnerPageCard,
    data () {
        return {
            fields: IndicationsIssueReportedPage,
            reportUrl: urls.pharmacy.indication.report,
            reportDeleteUrl: urls.pharmacy.indication.reportDelete,
            deletingItem: {
                name: ''
            },

            data: [
                {
                    report_type: 'fex-swts',
                    contents: 'afs vsgg ssvsgw',
                    preperations: 'afs vsgg ssvsgw',
                    reported_by: 'afs vsgg ssvsgw'

                }
            ]
        };
    },

    methods: {
        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.deleteModal.show();
        },
        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.deleteModal.close();
            this.$notify('Report Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        }

    }

};
</script>

<style scoped>

</style>

<template>
    <container>
        <inner-page-card class="" :scrollbar="true" heading="URINE ANALYSIS">
            <template #buttons>
                <slot name="buttons">
                    <add-btn @click="$refs.addModal.show()"/>
                </slot>
            </template>
            <modal width="50r" title="URINE ANALYSIS" ref="addModal">
                <b-form :save-url="addEditUrl" @failure="userEditFailure" @success="formSuccess('A')" v-slot="{model, loading}">

                    <div class="row">
                        <div class="col-lg-6">
                            <validated-ajax-select label="Species <span class='text-danger'>*</span>:"
                                                   :url="speciesVueSelectUrl" :disabled="false"
                                                   v-model="model.species" :rules="rules.species"
                                                   name="Species"></validated-ajax-select>
                        </div>
                        <div class="col-lg-6">
                            <validated-ajax-select label="Disease <span class='text-danger'>*</span>:"
                                                   :url="diseaseVueSelectUrl" :disabled="false"
                                                   v-model="model.disease" :rules="rules.disease"
                                                   name="Disease"></validated-ajax-select>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Urine Anlysis <span class='text-danger'>*</span>:"
                                             v-model="model.urinalysis" :rules="rules.urinalysis"
                                             :disabled="loading"></validated-input>
                        </div>
                        <div class="col-lg-12">
                            <submit-btn loading-text="Updating..." size="sm" :disabled="loading"
                                        :loading="loading"></submit-btn>
                        </div>

                    </div>
                </b-form>
            </modal>

            <vue-table-card :fields="fields" :table-data="data" :url="listUrl" ref="table">
                <template #actions="{rowData}">
                    <btn-group>
                        <edit-btn @click="setEdit(rowData)"/>
                        <delete-btn @click="setDelete(rowData)"/>
                    </btn-group>
                </template>
                <template #footer>
                    <edit-modal ref="editModal">
                        <b-form :save-url="addEditUrl" @failure="userEditFailure" @success="formSuccess('M')" v-slot="{model, loading}"
                                :initial-data="editingItem">

                            <div class="row">
                                <div class="col-lg-6">
                                    <validated-ajax-select label="Species <span class='text-danger'>*</span>:"
                                                           :url="speciesVueSelectUrl" :disabled="false"
                                                           v-model="model.species" :rules="rules.species"
                                                           name="Species"></validated-ajax-select>
                                </div>
                                <div class="col-lg-6">
                                    <validated-ajax-select label="Disease <span class='text-danger'>*</span>:"
                                                           :url="diseaseVueSelectUrl" :disabled="false"
                                                           v-model="model.disease" :rules="rules.disease"
                                                           name="Disease"></validated-ajax-select>
                                </div>

                                <div class="col-lg-6">
                                    <validated-input label="Urine Anlysis <span class='text-danger'>*</span>:"
                                                     v-model="model.urinalysis" :rules="rules.urinalysis"
                                                     :disabled="loading"></validated-input>
                                </div>
                                <div class="col-lg-12">
                                    <submit-btn loading-text="Updating..." size="sm" :disabled="loading"
                                                :loading="loading"></submit-btn>
                                </div>

                            </div>
                        </b-form>

                    </edit-modal>
                    <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem"
                                  @response="deleteComplete">
                        <p>You are about to delete
                            <b v-if="deletingItem"> {{ deletingItem.species_name }}</b>.Are you sure?</p>
                        <template #loading>
                            <loading-animation/>
                            Please wait while we delete <b v-html="deletingItem && deletingItem.species_name"></b>.
                        </template>
                    </delete-modal>

                </template>
            </vue-table-card>

        </inner-page-card>
    </container>
</template>

<script>
import InnerPageCard from '@components/InnerPageCard';
import UrineAnalysisPage from '@/views/diagnosis/urine-anlaysis/urine-analysis';
import urls from '@/data/urls';

export default {
    name: 'UrineAnalysisPage',
    components: InnerPageCard,
    data () {
        return {
            fields: UrineAnalysisPage,
            listUrl: urls.diagnosis.urineAnalysis.list,
            addEditUrl: urls.diagnosis.urineAnalysis.addEdit,
            diseaseVueSelectUrl: urls.diagnosis.urineAnalysis.diseaseVueSelect,
            speciesVueSelectUrl: urls.diagnosis.urineAnalysis.speciesVueSelect,
            deleteUrl: urls.diagnosis.urineAnalysis.delete,
            editingItem: null,
            deletingItem: {
                name: ''
            },
            rules: {
                species: {
                    required: true
                },
                disease: {
                    required: true
                },
                urinalysis: {
                    required: true
                }
            },
            data: [
                {
                    species: 'fex-swts',
                    disease: 'afs vsgg ssvsgw',
                    urinalysis: 'ghbhhjbj'
                }
            ]
        };
    },
    methods: {
        formSuccess (action) {
            const refs = this.$refs;
            this.$refs.addModal.close();
            this.$refs.editModal.close();
            if (action === 'A') {
                this.$notify('Urine analysis Added Successfully', 'Success', {
                    type: 'success'
                });
            } else {
                this.$notify('Urine analysis Updated Successfully', 'Success', {
                    type: 'success'
                });
            }
            refs.table.refreshTable();

            this.editingItem = null;
        },
        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.editModal.show();
        },
        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.deleteModal.show();
        },
        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.deleteModal.close();
            this.$notify('Urine analysis Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        userEditFailure (response) {
            const errors = response.data.errors;
            for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                    const errorMessage = `${key}:  ${errors[key]}`;
                    this.$notify(errorMessage, 'Warning', { type: 'warning' });
                }
            }
        }

    }

};
</script>

<style scoped>

</style>

import { render, staticRenderFns } from "./DiseaseListDetailsPage.vue?vue&type=template&id=1ee83fe5&scoped=true&"
import script from "./DiseaseListDetailsPage.vue?vue&type=script&lang=js&"
export * from "./DiseaseListDetailsPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ee83fe5",
  null
  
)

export default component.exports
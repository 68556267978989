import Button from 'lego-framework/src/components/Button';

export default {
    name: 'dashboard-btn',
    extends: Button,
    props: {
        borderRadius: { type: String, default: '0' },
        block: { type: Boolean, default: true },
        color: { type: String, default: 'secondary' },
        size: { type: String, required: false, default: 'lg' }
    }
};

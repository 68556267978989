<template>
    <div>
        <container>
            <inner-page-card heading="VETENARY DOCTORS KERALA">
                <template #buttons>
                    <add-btn @click="$refs.addVetenaryDoctorsKeralaModal.show()"/>
                </template>
                <vue-table-card :fields="fields" :url="listUrl" ref="table" class="p-0">
                    <template #actions="{rowData}">
                        <btn-group>
                            <edit-btn @click="SetEdit(rowData)"/>
                            <delete-btn @click="SetDelete(rowData)"/>
                        </btn-group>
                    </template>
                    <template #footer>
                        <!--                        Add Contact Modal-->
                        <modal ref="addVetenaryDoctorsKeralaModal" title="Add" width="55r">
                            <b-form :save-url="addEditUrl" @failure="VetDocAddEditFailure" @success="formSuccess('A')" v-slot="{model, loading}">
                                <row>
                                    <col-12 lg="6">
                                        <validated-input label="Name :" v-model="model.name" :rules="rules.name"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input label="Category :" v-model="model.category" :rules="rules.category"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input label="Designation :" v-model="model.designation" :rules="rules.designation"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input label="College :" v-model="model.college" :rules="rules.college"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input label="Department :" v-model="model.department" :rules="rules.department"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input label="Phone :" v-model="model.phone" :rules="rules.phone"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12>
                                        <submit-btn loading-text="Submitting..." size="sm" :disabled="loading"
                                                    :loading="loading"/>
                                    </col-12>
                                </row>
                            </b-form>
                        </modal>
                        <!--                        Edit Contact Modal-->
                        <edit-modal ref="vetenaryDoctorsKeralaEditModal" width="55r">
                            <b-form :save-url="addEditUrl" @failure="VetDocAddEditFailure" @success="formSuccess('M')" v-slot="{model, loading}"
                                    :initial-data="editingItem">
                                <row>
                                    <col-12 lg="6">
                                        <validated-input label="Name :" v-model="model.name" :rules="rules.name"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input label="Category :" v-model="model.category" :rules="rules.category"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input label="Designation :" v-model="model.designation" :rules="rules.designation"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input label="College :" v-model="model.college" :rules="rules.college"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input label="Department :" v-model="model.department" :rules="rules.department"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input label="Phone :" v-model="model.phone" :rules="rules.phone"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12>
                                        <submit-btn loading-text="Submitting..." size="sm" :disabled="loading"
                                                    :loading="loading"/>
                                    </col-12>
                                </row>
                            </b-form>
                        </edit-modal>
                        <!--                        Delete Modal-->
                        <delete-modal ref="vetenaryDoctorsKeralaDeleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
                            <p>You are about to delete the item.
                                <b v-if="deletingItem"> {{ deletingItem.name }}</b> Are you sure ?
                            </p>
                            <template #loading>
                                <loading-animation/>
                                Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
                            </template>
                        </delete-modal>
                    </template>
                </vue-table-card>
            </inner-page-card>
        </container>
    </div>
</template>

<script>
import usersTableFields from './vet-doc-table-fields';
import urls from '../../../data/urls';

export default {
    name: 'AddFarmPage',
    data () {
        return {
            fields: usersTableFields,
            listUrl: urls.directory.vetDoc.list,
            addEditUrl: urls.directory.vetDoc.addEdit,
            deleteUrl: urls.directory.vetDoc.delete,
            editingItem: false,
            deletingItem: {
                name: ''
            },
            rules: {
                name: {
                    required: false
                },
                designation: {
                    required: false
                },
                category: {
                    required: false
                },
                college: {
                    required: false
                },
                department: {
                    required: false
                },
                phone: {
                    required: false
                }
            }
        };
    },
    methods: {
        SetEdit (item) {
            this.editingItem = { ...item };// for setting initial values in for
            this.$refs.vetenaryDoctorsKeralaEditModal.show();
        },
        SetDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.vetenaryDoctorsKeralaDeleteModal.show();
        },
        formSuccess (action) {
            const refs = this.$refs;
            this.$refs.addVetenaryDoctorsKeralaModal.close();
            this.$refs.vetenaryDoctorsKeralaEditModal.close();
            if (action === 'A') {
                this.$notify('Item Added Successfully', 'Success', {
                    type: 'success'
                });
            } else {
                this.$notify('Item Updated Successfully', 'Success', {
                    type: 'success'
                });
            }
            refs.table.refreshTable();

            this.editingItem = null;
        },
        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.vetenaryDoctorsKeralaDeleteModal.close();
            this.$notify('Item Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        VetDocAddEditFailure (response) {
            const errors = response.data.errors;
            for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                    const errorMessage = `${key}:  ${errors[key]}`;
                    this.$notify(errorMessage, 'Warning', { type: 'warning' });
                }
            }
        }
    }
};
</script>

<style scoped>

</style>

<template>
    <container>
        <inner-page-card class="" :scrollbar="true" heading="TEST VALUE">
            <template #buttons>
                <slot name="buttons">
                    <add-btn @click="itemAdd"/>

                    <modal width="50r" title="TEST VALUE" ref="specificTestAddModal">
                        <s-form @submit="addItem">
                            <div class="row">
                                <div class="col-lg-6">
                                    <validated-ajax-select label="Species <span class='text-danger'>*</span>:"
                                                           class="mt-2 font-weight-700 text-2 v-select-dropdown-max-h-10r"
                                                           :url="speciesUrl" v-model="model.species"
                                                           :rules="{required:true}"
                                                           :disabled="false"></validated-ajax-select>
                                </div>
                                <div class="col-lg-6 mb-7">
                                    <validated-vue-select label="Test type <span class='text-danger'>*</span>:"
                                                          class="mt-2 font-weight-700 text-2 v-select-dropdown-max-h-10r"
                                                          :options="testTypeOptions" v-model="model.test_type"
                                                          :rules="{required:true}"
                                                          :disabled="false"></validated-vue-select>
                                </div>
                                <div class="col-lg-12">
                                    <submit-btn :loading="loading" loading-text="Saving..."/>

                                </div>
                            </div>
                        </s-form>
                    </modal>
                </slot>
            </template>

            <vue-table-card :fields="fields" :url="listUrl"
                            :per-page="10" ref="table" search-placeholder="Search">
                <template #actions="{rowData}">
                    <btn-group>
                        <edit-btn @click="EditUser(rowData)"/>
                        <delete-btn @click="setDeleteUser(rowData)"/>
                    </btn-group>
                </template>
                <template #footer>
                    <edit-modal ref="specificTestEditModal">
                        <s-form @submit="addItem">
                            <row>
                                <div class="col-lg-6">
                                    <validated-ajax-select label="Species <span class='text-danger'>*</span>:"
                                                           class="mt-2 font-weight-700 text-2 v-select-dropdown-max-h-10r"
                                                           :url="speciesUrl" v-model="editingItem.species"
                                                           :rules="{required:true}"
                                                           :disabled="false"></validated-ajax-select>
                                </div>
                                <div class="col-lg-6 mb-7">
                                    <validated-vue-select label="Test type <span class='text-danger'>*</span>:"
                                                          class="mt-2 font-weight-700 text-2 v-select-dropdown-max-h-10r"
                                                          :options="testTypeOptions" v-model="editingItem.test_type"
                                                          :rules="{required:true}"
                                                          :disabled="false"></validated-vue-select>
                                </div>
                                <div class="col-lg-12">
                                    <submit-btn :loading="loading" loading-text="Saving..."/>

                                </div>
                            </row>
                        </s-form>
                    </edit-modal>
                    <delete-modal ref="specificTestDeleteModal" @response="deleteSuccess" :url="deletingUrl"
                                  :params="{id : deletingItem.id}">
                        <p>You are about to delete <b>{{ deletingItem.species_name }}</b>.
                            Are you sure about it ?
                        </p>
                    </delete-modal>
                </template>
            </vue-table-card>

        </inner-page-card>
    </container>
</template>

<script>
import InnerPageCard from '@components/InnerPageCard';
import specifictestTableFields from '@/views/diagnosis/specific-tests/specific-test-fields';
import urls from '@/data/urls';
import axios from 'secure-axios';

export default {
    name: 'SpecificTestsPage',
    components: InnerPageCard,
    data () {
        return {
            loading: false,
            listUrl: urls.diagnosis.specialTest.list,
            editingUrl: urls.diagnosis.specialTest.addEdit,
            editingItem: '',
            deletingItem: '',
            deletingUrl: urls.diagnosis.specialTest.delete,
            speciesUrl: urls.diagnosis.specialTest.species,
            fields: specifictestTableFields,
            data: [
                {
                    species: 'fex-swts',
                    test_type: 'afs vsgg ssvsgw'
                }
            ],
            model: {
                species: '',
                test_type: ''
            },
            testTypeOptions: [
                { label: '--------', value: '' },
                { label: 'Clinical sign', value: 'clinical_sign' },
                { label: 'Ascalation Palpation', value: 'ascalation' },
                { label: 'Complete Blood count', value: 'cbc' },
                { label: 'Blood Chemistry', value: 'blood_chemistry' },
                { label: 'Urinal Analysis', value: 'urinal_analyisis' },
                { label: 'Pathological Findings', value: 'pathological_findings' }
            ]
        };
    },
    methods: {
        async addItem () {
            try {
                this.loading = true;
                if (this.editingItem) {
                    console.log('in edit');
                    this.response = await axios.form(this.editingUrl, this.editingItem);
                } else {
                    console.log('in add');

                    this.response = await axios.form(this.editingUrl, this.model);
                }
                const result = this.response.data;
                console.log('result', result);
                if (result.success === true) {
                    if (this.editingItem) {
                        this.$notify('Updated Successfully', 'Success', { type: 'success' });
                        this.loading = false;
                        this.editingItem = '';
                        this.$refs.specificTestEditModal.close();
                    } else {
                        this.$notify('Added Successfully', 'Success', { type: 'success' });
                        this.loading = false;
                        this.clearForm(this.model);
                        this.$refs.specificTestAddModal.close();
                        await this.addFormClear();
                    }
                    this.$refs.table.refreshTable();
                } else {
                    const errors = this.response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.this.response.status + ' ' + error.this.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        },
        addFormClear () {
            this.model.test_type = '';
            this.model.species = '';
        },
        EditUser (item) {
            console.log(item);
            this.editingItem = '';
            this.editingItem = { ...item };
            this.$refs.specificTestEditModal.show();
        },
        setDeleteUser (item) {
            console.log(item);
            this.deletingItem = item;
            this.$refs.specificTestDeleteModal.show();
        },
        itemAdd () {
            this.$refs.specificTestAddModal.show();
        },
        deleteSuccess (item) {
            console.log('delete state', item);
            this.$refs.specificTestDeleteModal.close();
            if (item.success) {
                this.$notify('Deleted Successfully', 'Success', {
                    type: 'success'
                });
                this.$refs.table.refreshTable();
            } else {
                console.log('item error', item.errors.Id);
                this.$notify(item.errors.Id, 'warning', {
                    type: 'warning'
                });
            }
        },
        clearForm (item) {
            item.species = '';
            item.test_type = '';
        }
    }

};
</script>

<style scoped>

</style>

const tableFields = [
    {
        name: 'user_id',
        title: 'User',
        titleClass: '',
        sortField: 'user',
        dataClass: ''
    },
    {
        name: 'disease_id',
        title: 'Disease',
        titleClass: '',
        sortField: 'disease',
        dataClass: ''
    }
];

export default tableFields;

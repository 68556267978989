const tableFields = [
    {
        name: 'species_name',
        title: 'Species',
        sortField: 'species_name',
        titleClass: '',
        dataClass: ''
    },
    // {
    //     name: 'calcium',
    //     title: 'Calcium',
    //     sortField: 'calcium',
    //     titleClass: '',
    //     dataClass: ''
    // },
    // {
    //     name: 'lifespan',
    //     title: 'Lifespan',
    //     sortField: 'lifespan',
    //     titleClass: '',
    //     dataClass: ''
    // },
    // {
    //     name: 'gestation_age',
    //     title: 'Generation Age',
    //     sortField: 'gestation_age',
    //     titleClass: '',
    //     dataClass: ''
    // },
    // {
    //     name: 'heart_rate',
    //     title: 'Heart Rate',
    //     sortField: 'heart_rate',
    //     titleClass: '',
    //     dataClass: ''
    // },
    // {
    //     name: 'temperature',
    //     title: 'Temperature',
    //     sortField: 'temperature',
    //     titleClass: '',
    //     dataClass: ''
    // },
    // {
    //     name: 'pulse',
    //     title: 'Pulse',
    //     sortField: 'pulse',
    //     titleClass: '',
    //     dataClass: ''
    // },
    // {
    //     name: 'respiration',
    //     title: 'Respiration',
    //     sortField: 'respiration',
    //     titleClass: '',
    //     dataClass: ''
    // },
    // {
    //     name: 'total_protein',
    //     title: 'Total Protein',
    //     sortField: 'total_protein',
    //     titleClass: '',
    //     dataClass: ''
    // },
    // {
    //     name: 'albumin',
    //     title: 'Albumin',
    //     sortField: 'albumin',
    //     titleClass: '',
    //     dataClass: ''
    // },
    // {
    //     name: 'globulin',
    //     title: 'Globulin',
    //     sortField: 'globulin',
    //     titleClass: '',
    //     dataClass: ''
    // },
    // {
    //     name: 'glucose',
    //     title: 'Glucose',
    //     sortField: 'glucose',
    //     titleClass: '',
    //     dataClass: ''
    // },
    // {
    //     name: 'bun',
    //     title: 'BUN',
    //     sortField: 'bun',
    //     titleClass: '',
    //     dataClass: ''
    // },
    // {
    //     name: 'creatinine',
    //     title: 'Creatine',
    //     sortField: 'creatinine',
    //     titleClass: '',
    //     dataClass: ''
    // },

    {
        name: '__slot:actions',
        title: 'Actions',
        titleClass: '',
        dataClass: ''
    }
];

export default tableFields;

const tableFields = [
    {
        name: 'file',
        title: 'Images',
        titleClass: '',
        dataClass: 'w-20r',
        sortField: 'file'
    },
    {
        name: 'order',
        title: 'Order',
        titleClass: '',
        dataClass: 'w-20r',
        sortField: 'order'
    },
    {
        name: '__slot:actions',
        title: 'Actions',
        titleClass: '',
        dataClass: ''
    }
];

export default tableFields;

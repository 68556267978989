<template>
    <div>
        <container>
            <inner-page-card heading="contact">
                <template #buttons>
                    <add-btn @click="$refs.addContactModal.show()"/>
                </template>
                <vue-table-card :fields="fields" :url="listUrl" :table-data="data" ref="table">
                    <template #actions="{rowData}">
                        <btn-group>
                            <edit-btn @click="SetEdit(rowData)"/>
                            <delete-btn @click="setDeleteContact(rowData)"/>
                        </btn-group>
                    </template>
                    <template #footer>
                        <!--                        Add Contact Modal-->
                        <modal ref="addContactModal" title="Add Contact" width="55r">
                            <b-form :save-url="addUrl" @failure="contactAddEditFailure" @success="formSuccess('A')" v-slot="{model, loading}">
                                <row>
                                    <col-12 lg="6">
                                        <validated-input label="Name :" v-model="model.name" :rules="rules.name"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input label="Category :" v-model="model.category" :rules="rules.category"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input label="Designation :" v-model="model.designation" :rules="rules.designation"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input label="College :" v-model="model.college" :rules="rules.college"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input label="Department :" v-model="model.department" :rules="rules.department"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input label="Phone :" v-model="model.phone" :rules="rules.phone"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12>
                                        <submit-btn loading-text="Submitting..." size="sm" :disabled="loading"
                                                    :loading="loading"/>
                                    </col-12>
                                </row>
                            </b-form>

                        </modal>
                        <!--                        Edit Contact Modal-->
                        <edit-modal ref="contactEditModal" width="55r">
                            <b-form :save-url="addUrl" @failure="contactAddEditFailure" @success="formSuccess('M')" v-slot="{model, loading}"
                                    :initial-data="editingItem">
                                <row>
                                    <col-12 lg="6">
                                        <validated-input label="Name :" v-model="model.name" :rules="rules.name"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input label="Category :" v-model="model.category" :rules="rules.category"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input label="Designation :" v-model="model.designation" :rules="rules.designation"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input label="College :" v-model="model.college" :rules="rules.college"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input label="Department :" v-model="model.department" :rules="rules.department"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input label="Phone :" v-model="model.phone" :rules="rules.phone"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12>
                                        <submit-btn loading-text="Submitting..." size="sm" :disabled="loading"
                                                    :loading="loading"/>
                                    </col-12>
                                </row>
                            </b-form>
                        </edit-modal>
                        <!--Delete Modal-->
                        <delete-modal ref="contactDeleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
                            <p>You are about to delete the contact.
                                <b v-if="deletingItem"> {{ deletingItem.name }}</b> Are you sure ?
                            </p>
                            <template #loading>
                                <loading-animation/>
                                Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
                            </template>
                        </delete-modal>
                    </template>
                </vue-table-card>
            </inner-page-card>
        </container>
    </div>
</template>

<script>
import usersTableFields from './contact-table-fields';
import urls from '../../../data/urls';

export default {
    name: 'AddContactPage',
    data () {
        return {
            fields: usersTableFields,
            addUrl: urls.directory.contact.addEdit,
            listUrl: urls.directory.contact.list,
            deleteUrl: urls.directory.contact.delete,
            editingItem: null,
            deletingItem: {
                name: ''
            },
            data: [
                {
                    id: '23',
                    name: 'Veterinary College Mannuthy',
                    category: 'UG Student',
                    department: 'Swathi Sivanand (GRP 2018)',
                    college: '2018 Batch',
                    designation: 'Department',
                    phone: '7902900460'
                }
            ],
            rules: {
                name: {
                    required: false
                },
                designation: {
                    required: false
                },
                category: {
                    required: false
                },
                college: {
                    required: false
                },
                department: {
                    required: false
                },
                phone: {
                    required: false
                }
            }
        };
    },
    methods: {
        SetEdit (item) {
            console.log(item);
            this.editingItem = { ...item };
            this.$refs.contactEditModal.show();
        },
        setDeleteContact (item) {
            this.deletingItem = { ...item };
            this.$refs.contactDeleteModal.show();
        },
        formSuccess (action) {
            const refs = this.$refs;
            this.$refs.addContactModal.close();
            this.$refs.contactEditModal.close();
            if (action === 'A') {
                this.$notify('Contact Added Successfully', 'Success', {
                    type: 'success'
                });
            } else {
                this.$notify('Contact Updated Successfully', 'Success', {
                    type: 'success'
                });
            }
            refs.table.refreshTable();

            this.editingItem = null;
        },
        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.contactDeleteModal.close();
            this.$notify('Contact Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        contactAddEditFailure (response) {
            const errors = response.data.errors;
            for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                    const errorMessage = `${key}:  ${errors[key]}`;
                    this.$notify(errorMessage, 'Warning', { type: 'warning' });
                }
            }
        }
    }
};
</script>

<style scoped>

</style>

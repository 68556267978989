<template>
    <table-page heading="Users" class="mx-lg-7">
        <vue-table-card :fields="fields" :url="listUrl">
            <template #actions="{rowData}">
                <btn-group>
                    <view-btn @click="viewUser(rowData)"/>
                </btn-group>
            </template>
            <template #footer>
                <delete-modal ref="userDeleteModal">
                    <p>You are about to delete the User <b>User Name</b>. Are you sure
                        ?
                    </p>
                </delete-modal>
                <modal width="35r" title="User Info" ref="userViewModal">
                    <details-card :heading="'Username : ' + selectedUserItem.username" v-if="selectedUserItem">
                        <details-list>
                            <details-list-item label="First Name" :value="selectedUserItem.first_name"/>
                            <details-list-item label="Last Name" :value="selectedUserItem.last_name"/>
                            <details-list-item label="College name" :value="selectedUserItem.college"/>
                            <details-list-item label="Batch" :value="selectedUserItem.batch"/>
                            <details-list-item label="Designation" :value="selectedUserItem.designation"/>
                            <details-list-item label="Email" :value="selectedUserItem.email"/>
                            <details-list-item label="Phone number" :value="selectedUserItem.phone_number"/>
                            <details-list-item label="Registration number" :value="selectedUserItem.reg_no"/>
                            <details-list-item label="Country" :value="selectedUserItem.country"/>
                            <details-list-item label="Place" :value="selectedUserItem.place"/>
                        </details-list>
                    </details-card>
                </modal>
            </template>
        </vue-table-card>
    </table-page>
</template>

<script>
import usersTableFields from './rejected-users-table-fields';
import urls from '../../../data/urls';
import axios from 'secure-axios';

export default {
    name: 'TablePageFormat',
    data () {
        return {
            fields: usersTableFields,
            listUrl: urls.userManagement.users.rejectedUsersList,
            selectedUserItem: null,
            detailsUrl: urls.userManagement.users.detail
        };
    },
    methods: {
        async viewUser (item) {
            const that = this;
            const response = await axios.form(
                this.detailsUrl, { id: item.id }
            ).catch(function (exception) {
                that.serverError();
            });
            const json = response.data;
            const dataItem = json.data;
            this.selectedUserItem = dataItem;
            this.$refs.userViewModal.show();
        }
    }
};
</script>

<style scoped>

</style>

const tableFields = [
    {
        name: 'username',
        title: 'Username',
        titleClass: '',
        dataClass: 'w-10r',
        sortField: 'username'
    },
    {
        name: 'first_name',
        title: 'First Name',
        titleClass: '',
        dataClass: '',
        sortField: 'first_name'
    },
    {
        name: 'last_name',
        title: 'Last Name',
        titleClass: '',
        dataClass: '',
        sortField: 'last_name'
    },
    {
        name: 'college',
        title: 'College',
        titleClass: '',
        dataClass: '',
        sortField: 'college'
    },
    {
        name: 'designation',
        title: 'Designation',
        titleClass: '',
        dataClass: '',
        sortField: 'designation'
    },
    {
        name: 'email',
        title: 'Email',
        titleClass: '',
        dataClass: '',
        sortField: 'email'
    },
    {
        name: 'phone_number',
        title: 'Phone Number',
        titleClass: '',
        dataClass: '',
        sortField: 'phone_number'
    },
    {
        name: 'reg_no',
        title: 'Registration No',
        titleClass: '',
        dataClass: '',
        sortField: 'registration_no'
    },
    {
        name: 'country',
        title: 'Country',
        titleClass: '',
        dataClass: '',
        sortField: 'country'
    },
    {
        name: 'place',
        title: 'Place',
        titleClass: '',
        dataClass: '',
        sortField: 'place'
    },
    {
        name: '__slot:actions',
        title: 'Approve/Delete',
        titleClass: '',
        dataClass: ''
    }
];

export default tableFields;

const tableFields = [
    {
        name: 'indication_name',
        title: 'Disease',
        titleClass: '',
        dataClass: 'w-20r',
        sortField: 'indication_name'

    },
    {
        name: 'treatment_content',
        title: 'Treatment',
        titleClass: '',
        dataClass: 'w-40r',
        sortField: 'treatment_content'

    }, {
        name: 'suggested_by',
        title: 'Suggested person',
        titleClass: '',
        dataClass: 'w-40r',
        sortField: 'suggested_by'
    },

    {
        name: '__slot:actions',
        title: 'Actions',
        titleClass: '',
        dataClass: ''
    }
];

export default tableFields;

<template>
     <container>
        <table-page heading="Disease Reports">
            <vue-table-card :fields="fields" :url="listUrl"
              :per-page="10" ref="table" search-placeholder="Search">
                <template #actions="{rowData}">
                    <btn-group>
                        <delete-btn @click="setDeleteUser(rowData)"/>
                    </btn-group>
                </template>
                <template #footer>
                    <delete-modal ref="diseaseissuesreportedDeleteModal" :url="deleteUrl" :params="{id:deleteItem.id}"  @response="deleteSuccess">
                        <p>You are about to delete <b>{{deleteItem.report_type}} </b>.
                            Are you sure about it ?
                        </p>
                    </delete-modal>
                </template>
            </vue-table-card>

        </table-page>
    </container>
</template>

<script>
import tableFields from './disease-issues-reported-table-fields';
import urls from '@/data/urls';

export default {
    name: 'DiseaseIssuesReportedPage',
    data () {
        return {
            deleteUrl: urls.disease.diseaseReport.delete,
            deleteItem: '',
            listUrl: urls.disease.diseaseReport.disease,
            fields: tableFields,
            data: [
                {
                    report_type: '1',
                    contents: 'Ghdhdgdt',
                    disease: 'H',
                    reported_by: 'Site'
                }
            ]
        };
    },
    methods: {
        deleteSuccess (item) {
            this.$refs.diseaseissuesreportedDeleteModal.close();
            if (item.success) {
                this.$notify('Deleted Successfully', 'Success', {
                    type: 'success'
                });
                this.$refs.table.refreshTable();
            } else {
                console.log('item error', item.errors.Id);
                this.$notify(item.errors.Id, 'warning', {
                    type: 'warning'
                });
            }
        },
        setDeleteUser (item) {
            console.log(item);
            this.deleteItem = item;
            this.$refs.diseaseissuesreportedDeleteModal.show();
        }
    }
};
</script>

<style scoped>

</style>

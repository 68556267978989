import { render, staticRenderFns } from "./SpecificTestsPage.vue?vue&type=template&id=51d7a871&scoped=true&"
import script from "./SpecificTestsPage.vue?vue&type=script&lang=js&"
export * from "./SpecificTestsPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51d7a871",
  null
  
)

export default component.exports
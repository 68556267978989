const tableFields = [
    {
        name: 'preperation_name',
        title: 'Preperation',
        titleClass: '',
        dataClass: 'w-20r',
        sortField: 'preperation_name'

    },
    {
        name: 'content',
        title: 'Content',
        titleClass: '',
        dataClass: 'w-40r',
        sortField: 'content'

    },

    {
        name: '__slot:actions',
        title: 'Actions',
        titleClass: '',
        dataClass: ''
    }
];

export default tableFields;

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const editorConfig = {
    // The configuration of the editor.
    // toolbar: {
    //     items: [
    //         'heading',
    //         '|',
    //         'bold',
    //         'italic',
    //         '|',
    //         'bulletedList',
    //         'numberedList',
    //         '|',
    //         'undo',
    //         'redo'
    //     ]
    // }
};
export default {
    ClassicEditor,
    editorConfig
};

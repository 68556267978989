const tableFields = [
    {
        name: 'indication_name',
        title: 'Indication',
        titleClass: '',
        dataClass: '',
        sortField: 'indication'

    },
    {
        name: 'treatment_content',
        title: 'Treatment',
        titleClass: '',
        dataClass: '',
        sortField: 'treatment_content'

    }, {
        name: 'suggested_by',
        title: 'Suggested person',
        titleClass: '',
        dataClass: '',
        sortField: 'suggested_by'

    },

    {
        name: '__slot:actions',
        title: 'Actions',
        titleClass: '',
        dataClass: ''
    }
];

export default tableFields;

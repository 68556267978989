<template>
    <container>
        <inner-page-card heading="INDICATIONS">
            <div v-if="detailsData">
                <p class="font-weight-600">Indication: <span
                    class="font-weight-lighter text-2">{{ detailsData.indication_name }}</span></p>
                <div class="font-weight-600 mt-3">Content:</div>
                <div v-html="detailsData.content" class="mt-2 text-2"></div>
            </div>
            <inner-page-card heading="SUGGESTIONS" class="mx-n4 mt-5">
                <vue-table-card :fields="fields" params="indication" :url="suggestionListUrl" ref="table"
                                :table-data="data" class="p-0">
                    <template #actions="{ rowData }">
                        <btn-group>
                            <edit-btn @click="setEdit(rowData)"/>
                            <approve-btn @click="setApprove(rowData)"/>
                            <delete-btn @click="setDelete(rowData)"/>
                        </btn-group>
                    </template>
                    <template #footer>
                        <edit-modal ref="editModal" width="55r">
                            <b-form :save-url="suggestionAddEditUrl" @failure="userEditFailure" @success="formSuccess('M')"
                                    v-slot="{ model, loading }" :initial-data="editingItem">
                                <row>
                                    <div class="col-lg-6 mt-n2">
                                        <validated-ajax-select label="Indication <span class='text-danger'>*</span>:"
                                                               :url="VueSelectUrl" :disabled="false"
                                                               v-model="model.indication_name"
                                                               :rules="rules.indication_name"
                                                               name="Indication"></validated-ajax-select>
                                    </div>
                                    <div class="col-lg-6">
                                        <form-group label="Treatment content <span class='text-danger'>*</span>:">
                                            <ckeditor class="w-100p" :editor="editor" v-model="model.treatment_content"
                                                      :rules="rules.treatment_content" :disabled="loading"
                                                      :config="editorConfig"></ckeditor>
                                        </form-group>
                                    </div>
                                    <col-12>
                                        <submit-btn loading-text="Saving..." size="sm" :disabled="loading"
                                                    :loading="loading"></submit-btn>
                                    </col-12>
                                </row>
                            </b-form>
                        </edit-modal>
                        <approve-modal ref="approveModal" :url="suggestionApproveUrl" :params="approvingItem"
                                       @response="approveComplete">
                            <p>You are about to approve a suggestion for <b
                                v-if="approvingItem && approvingItem.indication_name">{{ approvingItem.indication_name }}</b>.
                                Are you sure about it?</p>
                            <template #loading>
                                <loading-animation/>
                                Please wait while we approve <b v-if="approvingItem && approvingItem.indication_name">{{
                                    approvingItem.preparation_name
                                }}</b>.
                            </template>
                        </approve-modal>
                        <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem"
                                      @response="deleteComplete">
                            <p>You are about to delete the suggestion <b
                                v-if="deletingItem">{{ deletingItem.indication_name }}</b>. Are you sure?</p>
                            <template #loading>
                                <loading-animation/>
                                Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
                            </template>
                        </delete-modal>
                    </template>
                </vue-table-card>
            </inner-page-card>
        </inner-page-card>
    </container>
</template>

<script>
import IndicationDetails from './indications-details-fields';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ApproveModal from '@components/modals/ApproveModal';
import axios from 'secure-axios';
import urls from '../../../../data/urls';

export default {
    name: 'IndicationDetailsPage',
    components: { ApproveModal },
    data () {
        return {
            detailsUrl: urls.pharmacy.indication.detail,
            suggestionListUrl: urls.pharmacy.indication.suggestionList + '?indication=' + this.$route.params.id,
            suggestionAddEditUrl: urls.pharmacy.indication.suggestionAddEdit,
            VueSelectUrl: urls.pharmacy.indication.vueSelect,
            suggestionApproveUrl: urls.pharmacy.indication.suggestionApprove,
            deleteUrl: urls.pharmacy.indication.suggestionDelete,
            detailsData: null,
            itemId: this.$route.params.id,
            fields: IndicationDetails,
            approvingItem: null,
            deletingItem: {
                name: ''
            },
            rules: {
                indication_name: {
                    required: true
                },
                content: {
                    required: true
                }
            },
            data: [
                {
                    preparation: 'alfredo-',
                    treatment: 'lorem afga AWH',
                    suggested_person: '2'
                }
            ],
            editor: ClassicEditor,
            editorData: '<p></p>',
            editorConfig: {},
            editingItem: ''
        };
    },
    mounted () {
        this.loadDetails(this.itemId);
    },
    methods: {
        formSuccess (action) {
            this.$refs.editModal.close();
            if (action === 'A') {
                this.$notify('Suggestion Added Successfully', 'Success', { type: 'success' });
            } else {
                this.$notify('Suggestion Updated Successfully', 'Success', { type: 'success' });
            }
            this.$refs.table.refreshTable();
        },
        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.editModal.show();
        },
        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.deleteModal.show();
        },
        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.deleteModal.close();
            this.$notify('Suggestion Deleted Successfully', 'Success', { type: 'success' });
            this.$refs.table.refreshTable();
        },
        userEditFailure (response) {
            const errors = response.data.errors;
            for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                    const errorMessage = `${key}:  ${errors[key]}`;
                    this.$notify(errorMessage, 'Warning', { type: 'warning' });
                }
            }
        },
        async loadDetails (itemId) {
            try {
                const response = await axios.form(this.detailsUrl, { id: itemId });
                this.detailsData = response.data.data;
            } catch (error) {
                this.serverError();
            }
        },
        serverError () {
            console.error('Server error');
        },
        setApprove (item) {
            this.approvingItem = { id: item.id, indication_name: item.indication_name };
            this.$refs.approveModal.show();
        },
        approveComplete () {
            this.approvingItem = null;
            this.$refs.approveModal.close();
            this.$notify('Suggestion Approved Successfully', 'Success', { type: 'success' });
            this.$refs.table.refreshTable();
        }
    },
    watch: {
        '$route.params.id': 'loadDetails'
    }
};
</script>

<style scoped>
</style>

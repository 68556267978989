<template>
    <div>
        <container>
            <inner-page-card :heading="'Add ' + categName">
                <template #buttons>
                    <add-btn @click="openAddModal"/>
                </template>
                <vue-table-card :fields="fields" :url="listUrl" ref="table" class="p-0"
                                :extra-params="{directory_category : categId}">
                    <template #actions="{rowData}">
                        <btn-group>
                            <edit-btn @click="SetEdit(rowData)"/>
                            <delete-btn @click="SetDelete(rowData)"/>
                        </btn-group>
                    </template>
                    <template #footer>
                        <!--                        Add Contact Modal-->
                        <modal ref="addModal" title="Add" width="55r">
                            <s-form @submit="addItem">
                                <!--                            <b-form ref="addForm" :save-url="addEditUrl" @failure="addEditFailure" @success="formSuccess('A')" v-slot="{model, loading}">-->
                                <row>
                                    <col-12 lg="6">
                                        <validated-input label="Name :" v-model="model.name" :rules="rules.name"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input label="Category :" v-model="model.category"
                                                         :rules="rules.category"
                                                         :disabled="loading"></validated-input>
                                        <!--                                        <validated-ajax-select class="pointer-events-none" label="Category :"  :url="categoryVueSelect"  v-model="categId" :rules="rules.category"-->
                                        <!--                                                         :disabled="true" ></validated-ajax-select>-->
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input label="Designation :" v-model="model.designation"
                                                         :rules="rules.designation"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input label="College :" v-model="model.college"
                                                         :rules="rules.college"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input label="Department :" v-model="model.department"
                                                         :rules="rules.department"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input label="Phone :" v-model="model.phone" :rules="rules.phone"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12>
                                        <submit-btn loading-text="Submitting..." size="sm" :disabled="loading"
                                                    :loading="loading"/>
                                    </col-12>
                                </row>
                            </s-form>
                        </modal>
                        <!--                        Edit Contact Modal-->
                        <edit-modal ref="EditModal" width="55r">
<!--                            <b-form :save-url="addEditUrl" @failure="addEditFailure" @success="formSuccess('M')" v-slot="{model, loading}"-->
<!--                                    :initial-data="editingItem">-->
                            <s-form @submit="editItem">
                                <row>
                                    <col-12 lg="6">
                                        <validated-input label="Name :" v-model="editingItem.name" :rules="rules.name"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input label="Category :" v-model="editingItem.category"
                                                         :rules="rules.category"
                                                         :disabled="loading"></validated-input>
                                        <!--                                          <validated-ajax-select label="Category :"  :url="categoryVueSelect" v-model="editingItem.category" :rules="rules.directory_category"-->
                                        <!--                                                         :disabled="loading"></validated-ajax-select>-->
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input label="Designation :" v-model="editingItem.designation"
                                                         :rules="rules.designation"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input label="College :" v-model="editingItem.college"
                                                         :rules="rules.college"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input label="Department :" v-model="editingItem.department"
                                                         :rules="rules.department"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input label="Phone :" v-model="editingItem.phone"
                                                         :rules="rules.phone"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12>
                                        <submit-btn loading-text="Submitting..." size="sm" :disabled="loading"
                                                    :loading="loading"/>
                                    </col-12>
                                </row>
                            </s-form>
                        </edit-modal>
                        <!--Delete Modal-->
                        <delete-modal ref="DeleteModal" :url="deleteUrl" :params="deletingItem"
                                      @response="deleteComplete">
                            <p>You are about to delete the item.
                                <b v-if="deletingItem"> {{ deletingItem.name }}</b> Are you sure ?
                            </p>
                            <template #loading>
                                <loading-animation/>
                                Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
                            </template>
                        </delete-modal>
                    </template>
                </vue-table-card>
            </inner-page-card>
        </container>
    </div>
</template>

<script>
import usersTableFields from './directory-table-fields';
import urls from '../../../data/urls';
import axios from 'secure-axios';

export default {
    name: 'AddDirectoryPage',
    data () {
        return {
            fields: usersTableFields,
            listUrl: urls.directory.directoryItems.list,
            addEditUrl: urls.directory.directoryItems.addEdit,
            deleteUrl: urls.directory.directoryItems.delete,
            categoryVueSelect: urls.directory.category.vueSelect,
            editingItem: false,
            deletingItem: {
                name: ''
            },
            loading: false,
            categId: this.$route.params.categId,
            categName: this.$route.params.categName,
            rules: {
                name: {
                    required: false
                },
                designation: {
                    required: false
                },
                directory_category: {
                    required: false
                },
                college: {
                    required: false
                },
                department: {
                    required: false
                },
                phone: {
                    required: false
                }
            },
            model: {
                name: '',
                designation: '',
                directory_category: this.categId,
                category: '',
                college: '',
                department: '',
                phone: ''
            }
        };
    },
    methods: {
        openAddModal () {
            this.$refs.addModal.show();
            // this.$refs.addForm.model.directory_category = this.categId;
            // this.$refs.addForm.setModelValue('directory_category', this.categId);
        },
        SetEdit (item) {
            this.editingItem = { ...item };// for setting initial values in form
            console.log('editing-item', this.editingItem);
            this.editingItem.directory_category = this.editingItem.directory_category_id;
            this.$refs.EditModal.show();
        },
        SetDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.DeleteModal.show();
        },
        async addItem () {
            const that = this;
            this.loading = true;
            this.model.directory_category = this.categId;
            const response = await axios.form(
                this.addEditUrl, this.model
            ).catch(function (exception) {
                that.serverError();
            });
            this.loading = false;
            // this.$refs.addModal.close();
            const responseData = response.data;
            if (responseData.success) {
                this.$notify('Item Added Successfully', 'Success', {
                    type: 'success'
                });
                this.$refs.addModal.close();
                // this.model = this.resetModel(); // Reset the form model
            }
            if (responseData.error) {
                // this.$notify(
                //     responseData.message || 'Something Went Wrong..!!',
                //     'Error',
                //     { type: 'warning' }
                // );
                console.log('resp data', responseData);
                // this.$notify(
                //     responseData.message || 'Something Went Wrong..!!',
                //     'Error',
                //     { type: 'warning' }
                // );
                const errors = response.data.errors;
                for (const key in errors) {
                    if (Object.prototype.hasOwnProperty.call(errors, key)) {
                        const errorMessage = `${key}:  ${errors[key]}`;
                        this.$notify(errorMessage, 'Error', { type: 'warning' });
                    }
                }
            }
            // this.$refs.addModal.reset();
            for (const property in this.model) {
                // console.log(`${property}: ${object[property]}`);
                this.model[property] = '';
            }
            this.$refs.table.refreshTable();
        },
        async editItem () {
            const that = this;
            this.loading = true;
            this.editingItem.directory_category = this.editingItem.directory_category_id;
            const response = await axios.form(
                this.addEditUrl, this.editingItem
            ).catch(function (exception) {
                that.serverError();
            });
            this.loading = false;
            this.$refs.EditModal.close();
            const responseData = response.data;
            if (responseData.success) {
                this.$notify('Item Updated Successfully', 'Success', {
                    type: 'success'
                });
            }
            if (responseData.error) {
                this.$notify(
                    responseData.message || 'Something Went Wrong..!!',
                    'Error',
                    { type: 'warning' }
                );
            }
            this.$refs.table.refreshTable();
        },
        formSuccess (action) {
            const refs = this.$refs;
            this.$refs.addModal.close();
            this.$refs.EditModal.close();
            if (action === 'A') {
                this.$notify('Item Added Successfully', 'Success', {
                    type: 'success'
                });
            } else {
                this.$notify('Item Updated Successfully', 'Success', {
                    type: 'success'
                });
            }
            refs.table.refreshTable();

            this.editingItem = null;
        },
        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.DeleteModal.close();
            this.$notify('Item Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        addEditFailure (response) {
            const errors = response.data.errors;
            for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                    const errorMessage = `${key}:  ${errors[key]}`;
                    this.$notify(errorMessage, 'Warning', { type: 'warning' });
                }
            }
        }
    }
};
</script>

<style scoped>

</style>

<template>
    <container>
        <inner-page-card class="" :scrollbar="true" heading="PREPARATIONS">
            <template #buttons>
                <slot name="buttons">
                    <add-btn @click="itemAdd"/>

                </slot>
            </template>

            <vue-table-card :fields="fields" :url="listUrl" ref="table" :table-data="data">
                <template #actions="{rowData}">
                    <btn-group>
                        <view-btn @click="viewDetails(rowData)"/>
                        <edit-btn @click="setEdit(rowData)"/>
                        <delete-btn @click="setDelete(rowData)"/>
                    </btn-group>
                </template>
                <template #footer>
                    <delete-modal ref="planDeleteModal" :url="deleteUrl" :params="deletingItem"
                                  @response="deleteComplete">
                        <p>You are about to delete the
                            <b v-if="deletingItem"> {{ deletingItem.preperation_name }}</b>.Are you sure?</p>
                        <template #loading>
                            <loading-animation/>
                            Please wait while we delete <b v-html="deletingItem && deletingItem.preperation_name"></b>.
                        </template>
                    </delete-modal>
                    <modal width="55r" title="Add" ref="addPlanModal">
                        <b-form :save-url="addEditUrl" @failure="userEditFailure" @success="formSuccess('A')" v-slot="{model, loading}">
                            <row>
                                <col-12 lg="6">
                                    <validated-input class="mt-n2" v-model="model.preperation_name"
                                                     :rules="rules.preperation_name"
                                                     :disabled="loading"
                                                     label="Preperation <span class='text-danger'>*</span> :"></validated-input>
                                </col-12>

                                <col-12 lg="6">
                                    <form-group
                                        :disabled="loading" label="Content <span class='text-danger'>*</span>:">
                                        <ckeditor class="w-100p" :editor="editor" v-model="model.content"
                                                  :rules="rules.content"
                                                  :config="editorConfig"></ckeditor>
                                    </form-group>

                                </col-12>
                                <col-12>
                                    <submit-btn loading-text="Saving..." size="sm" :disabled="loading"
                                                :loading="loading"></submit-btn>
                                </col-12>
                            </row>
                        </b-form>
                    </modal>
                    <modal width="55r" title="Edit" ref="editPlanModal">
                        <b-form :save-url="addEditUrl" @failure="userEditFailure" @success="formSuccess('M')" v-slot="{model, loading}"
                                :initial-data="editingItem">
                            <row>
                                <col-12 lg="6">
                                    <validated-input class="mt-n2" v-model="model.preperation_name"
                                                     :rules="rules.preperation_name"
                                                     :disabled="loading"
                                                     label="Preperation <span class='text-danger'>*</span> :"></validated-input>
                                </col-12>

                                <col-12 lg="6">
                                    <form-group
                                        :disabled="loading" label="Content <span class='text-danger'>*</span>:">
                                        <ckeditor class="w-100p" :editor="editor" v-model="model.content"
                                                  :rules="rules.content"
                                                  :config="editorConfig"></ckeditor>
                                    </form-group>

                                </col-12>
                                <col-12>
                                    <submit-btn loading-text="Saving..." size="sm" :disabled="loading"
                                                :loading="loading"></submit-btn>
                                </col-12>
                            </row>
                        </b-form>
                    </modal>
                </template>
            </vue-table-card>

        </inner-page-card>
    </container>
</template>

<script>
import InnerPageCard from '@components/InnerPageCard';
import PreparationPage from '@/views/pharmacy/preparations/preparation-page';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import urls from '../../../data/urls';
import axios from 'secure-axios';

export default {
    name: 'AddPlanPage',
    components: InnerPageCard,
    data () {
        return {
            fields: PreparationPage,
            listUrl: urls.pharmacy.preparation.list,
            addEditUrl: urls.pharmacy.preparation.addEdit,
            deleteUrl: urls.pharmacy.preparation.delete,
            activating: false,
            editingItem: null,
            currentUserItem: null,

            activatePlanItem: {
                id: ''
            },
            deletingItem: {
                name: ''
            },

            rules: {
                preperation_name: {
                    required: true
                },
                content: {
                    required: true
                }
            },

            data: [
                {
                    name: 'alfredo-',
                    description: 'lorem afga AWH',
                    value: '2',
                    expiry_months: '2-3-2021',
                    status: 'lorem ',
                    plan: '2'
                }
            ],
            editor: ClassicEditor,
            editorConfig: {}

        };
    },
    methods: {
        formSuccess (action) {
            const refs = this.$refs;
            this.$refs.addPlanModal.close();
            this.$refs.editPlanModal.close();
            if (action === 'A') {
                this.$notify('Prepartion Added Successfully', 'Success', {
                    type: 'success'
                });
            } else {
                this.$notify('Preparation Updated Successfully', 'Success', {
                    type: 'success'
                });
            }
            refs.table.refreshTable();

            this.editingItem = null;
        },
        setEdit (item) {
            /*  this.editingItem = { ...item };
            this.$refs.editPlanModal.show(); */
            this.$router.push('/preparations-edit/' + item.id + '/');
        },
        itemAdd () {
            this.$router.push('/preparations-add/');
        },
        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.planDeleteModal.show();
        },
        viewDetails (item) {
            console.log(item);
            this.$router.push('/preperations-details/' + item.id);
        },
        viewUser1 (item) {
            console.log(item);
            this.$refs.addplansViewModal.show();
        },
        async viewUser (item) {
            console.log(item);
            const that = this;
            const response = await axios.form(
                this.detailsUrl, { id: item.id }
            ).catch(function (exception) {
                that.serverError();
            });
            const json = response.data;
            const dataItem = json.data;
            // console.log('json', json.data.first_name);
            this.currentUserItem = dataItem;
            this.$refs.planViewModal.show();
        },
        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.planDeleteModal.close();
            this.$notify('Preparation Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        userEditFailure (response) {
            const errors = response.data.errors;
            for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                    const errorMessage = `${key}:  ${errors[key]}`;
                    this.$notify(errorMessage, 'Warning', { type: 'warning' });
                }
            }
        }

    }

};
</script>

<style scoped>

</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('container',[_c('inner-page-card',{attrs:{"scrollbar":true,"heading":"INDICATIONS"},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_vm._t("buttons",function(){return [_c('add-btn',{on:{"click":function($event){return _vm.$refs.addModal.show()}}})]})]},proxy:true}],null,true)},[_c('vue-table-card',{ref:"table",attrs:{"fields":_vm.fields,"url":_vm.listUrl},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var rowData = ref.rowData;
return [_c('btn-group',[_c('view-btn',{on:{"click":function($event){return _vm.viewDetails(rowData)}}}),_c('edit-btn',{on:{"click":function($event){return _vm.setEdit(rowData)}}}),_c('delete-btn',{on:{"click":function($event){return _vm.setDelete(rowData)}}})],1)]}},{key:"footer",fn:function(){return [_c('delete-modal',{ref:"planDeleteModal",attrs:{"url":_vm.deleteUrl,"params":_vm.deletingItem},on:{"response":_vm.deleteComplete},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading-animation'),_vm._v(" Please wait while we delete "),_c('b',{domProps:{"innerHTML":_vm._s(_vm.deletingItem && _vm.deletingItem.name)}}),_vm._v(". ")]},proxy:true}])},[_c('p',[_vm._v("You are about to delete the plan "),(_vm.deletingItem)?_c('b',[_vm._v(" "+_vm._s(_vm.deletingItem.indication_name))]):_vm._e(),_vm._v(".Are you sure?")])]),_c('modal',{ref:"addModal",attrs:{"width":"55r","title":"Add"}},[_c('b-form',{attrs:{"save-url":_vm.addEditUrl},on:{"failure":_vm.userEditFailure,"success":function($event){return _vm.formSuccess('A')}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var model = ref.model;
var loading = ref.loading;
return [_c('row',[_c('col-12',{attrs:{"lg":"6"}},[_c('validated-input',{staticClass:"mt-n2",attrs:{"rules":_vm.rules.indication_name,"disabled":loading,"label":"Indication <span class='text-danger'>*</span> :"},model:{value:(model.indication_name),callback:function ($$v) {_vm.$set(model, "indication_name", $$v)},expression:"model.indication_name"}})],1),_c('col-12',{attrs:{"lg":"6"}},[_c('form-group',{attrs:{"disabled":loading,"label":"Content <span class='text-danger'>*</span>:"}},[_c('ckeditor',{staticClass:"w-100p",attrs:{"editor":_vm.editor,"rules":_vm.rules.content,"config":_vm.editorConfig},model:{value:(model.content),callback:function ($$v) {_vm.$set(model, "content", $$v)},expression:"model.content"}})],1)],1),_c('col-12',[_c('submit-btn',{attrs:{"loading-text":"Saving...","size":"sm","disabled":loading,"loading":loading}})],1)],1)]}}])})],1),_c('modal',{ref:"editPlanModal",attrs:{"width":"55r","title":"Edit"}},[_c('b-form',{attrs:{"save-url":_vm.addEditUrl,"initial-data":_vm.editingItem},on:{"failure":_vm.userEditFailure,"success":function($event){return _vm.formSuccess('M')}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var model = ref.model;
var loading = ref.loading;
return [_c('row',[_c('col-12',{attrs:{"lg":"6"}},[_c('validated-input',{staticClass:"mt-n2",attrs:{"rules":_vm.rules.indication_name,"disabled":loading,"label":"Indication <span class='text-danger'>*</span> :"},model:{value:(model.indication_name),callback:function ($$v) {_vm.$set(model, "indication_name", $$v)},expression:"model.indication_name"}})],1),_c('col-12',{attrs:{"lg":"6"}},[_c('form-group',{attrs:{"disabled":loading,"label":"Content <span class='text-danger'>*</span>:"}},[_c('ckeditor',{staticClass:"w-100p",attrs:{"editor":_vm.editor,"rules":_vm.rules.content,"config":_vm.editorConfig},model:{value:(model.content),callback:function ($$v) {_vm.$set(model, "content", $$v)},expression:"model.content"}})],1)],1),_c('col-12',[_c('submit-btn',{attrs:{"loading-text":"Saving...","size":"sm","disabled":loading,"loading":loading}})],1)],1)]}}])})],1)]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
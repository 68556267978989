<template>
    <div>
        <container>
            <div v-if="loading">
                <div class="fl-x-cc">
                    <loading-animation></loading-animation>
                </div>
            </div>
            <inner-page-card :heading="detailsData.brand_name" v-else>
                <div class="font-weight-600">Categories:</div>
                <div class="mt-2 text-2">
                    <div v-html="detailsData.category"></div>
                </div>
                <heading-and-content heading="Contents" class="mt-3">
                    <div v-html="detailsData.contents"></div>
                </heading-and-content>
                <heading-and-content heading="Company Name" class="mt-3">
                    <div v-html="detailsData.company_name"></div>
                </heading-and-content>
                <heading-and-content heading="Availability" class="mt-3">
                    <div v-html="detailsData.availability"></div>
                </heading-and-content>
                <heading-and-content heading="Composition" class="mt-3">
                     <div v-html="detailsData.composition"></div>
                </heading-and-content>
                <heading-and-content heading="Price" class="mt-3">
                     <div v-html="detailsData.price"></div>
                </heading-and-content>
                <heading-and-content heading="Indication" class="mt-3">
                    <div v-html="detailsData.indication"></div>
                </heading-and-content>
                <heading-and-content heading="Dosage" class="mt-3">
                     <div v-html="detailsData.dosage"></div>
                </heading-and-content>
                <heading-and-content heading="Added By" class="mt-3">
                    <div v-html="detailsData.added_by"></div>
                </heading-and-content>
            </inner-page-card>
        </container>
    </div>
</template>

<script>
import urls from '../../../../data/urls';
import axios from 'secure-axios';

export default {
    name: 'BrandDetailsPage',
    data () {
        return {
            detailsUrl: urls.drugs.brand.detail,
            brandId: this.$route.params.id,
            detailsData: null,
            loading: false
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods: {
        async loadDetails (item) {
            this.loading = true;
            const that = this;
            const response = await axios.form(
                this.detailsUrl, { id: this.brandId }
            ).catch(function (exception) {
                that.serverError();
            });
            const json = response.data;
            const dataItem = json.data;
            console.log('json', json.data);
            this.detailsData = dataItem;
            this.loading = false;
        }
    }
};
</script>

<style scoped>

</style>

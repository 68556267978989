import { render, staticRenderFns } from "./AddPlansPage.vue?vue&type=template&id=0f1204e1&scoped=true&"
import script from "./AddPlansPage.vue?vue&type=script&lang=js&"
export * from "./AddPlansPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f1204e1",
  null
  
)

export default component.exports
<template>
    <table-page heading="Indications Suggestions">
        <vue-table-card :fields="fields" :url="suggestionListUrl" ref="table" :table-data="data">
            <template #actions="{ rowData }">
                <btn-group>
                    <edit-btn @click="setEdit(rowData)"/>
                    <approve-btn @click="setApprove(rowData)"/>
                    <delete-btn @click="setDelete(rowData)"/>
                </btn-group>
            </template>
            <template #footer>
                <edit-modal ref="editModal" width="55r">
                    <b-form :save-url="suggestionAddEditUrl" @failure="userEditFailure" @success="formSuccess('M')"
                            v-slot="{ model, loading }"
                            :initial-data="editingItem">
                        <row>
                            <div class="col-lg-6 mt-n2 mb-7">
                                <validated-ajax-select class="mt-2 font-weight-700 text-2 v-select-dropdown-max-h-10r" label="Indication"
                                                       :url="VueSelectUrl"
                                                       :disabled="false"
                                                       v-model="model.indication_name"
                                                       :rules="rules.indication_name"
                                ></validated-ajax-select>
                            </div>
                            <div class="col-lg-6">
                                <form-group label="Treatment content <span class='text-danger'>*</span> :">
                                    <ckeditor
                                        class="w-100p"
                                        :editor="editor"
                                        v-model="model.treatment_content"
                                        :rules="rules.treatment_content"
                                        name="Content"
                                        :disabled="loading"
                                        :config="editorConfig"></ckeditor>
                                </form-group>
                            </div>
                            <col-12>
                                <save-btn/>
                            </col-12>
                        </row>
                    </b-form>
                </edit-modal>
                <approve-modal ref="approveModal" :url="suggestionApproveUrl"
                               :params="{ id: approvingItem && approvingItem.id }" @response="approveComplete">
                    <p>You are about to approve the suggestion for <b>{{
                        approvingItem && approvingItem.indication_name
                        }}</b>. Are you sure?</p>
                </approve-modal>
                <delete-modal
                    ref="deleteModal"
                    :url="suggestionDeleteUrl"
                    :params="deletingItem"
                    @response="deleteComplete">
                    <p>You are about to delete the suggestion
                        <b v-if="deletingItem"> {{ deletingItem.indication_name }}</b>. Are you sure?</p>
                    <template #loading>
                        <loading-animation/>
                        Please wait while we delete <b v-html="deletingItem && deletingItem.preperation_name"></b>.
                    </template>
                </delete-modal>
            </template>
        </vue-table-card>
    </table-page>
</template>
<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ApproveModal from '@components/modals/ApproveModal';
import urls from '@/data/urls';
import IndicationsSuggestionPage from './indication-suggestion-fields';

export default {
    name: 'IndicationsSuggestionPage',
    components: {
        ApproveModal
    },
    data () {
        return {
            fields: IndicationsSuggestionPage,
            suggestionListUrl: urls.pharmacy.indication.suggestionList,
            suggestionAddEditUrl: urls.pharmacy.indication.suggestionAddEdit,
            VueSelectUrl: urls.pharmacy.indication.vueSelect,
            suggestionDeleteUrl: urls.pharmacy.indication.suggestionDelete,
            suggestionApproveUrl: urls.pharmacy.indication.suggestionApprove,
            editingItem: null,
            approvingItem: null,
            deletingItem: null,
            data: [
                {
                    preperation_name: 'alfredo-',
                    treatment_content: 'lorem afga AWH',
                    suggested_person: '2'
                }
            ],
            rules: {
                indication_name: {
                    required: true
                },
                treatment_content: {
                    required: true
                },
                suggested_by: {
                    required: true
                }

            },
            editor: ClassicEditor,
            editorConfig: {}
        };
    },
    methods: {
        formSuccess (action) {
            const refs = this.$refs;
            refs.editModal.close();
            this.$notify(
                action === 'A' ? 'Plan Added Successfully' : 'Suggestion Updated Successfully',
                'Success',
                { type: 'success' }
            );
            refs.table.refreshTable();
            this.editingItem = null;
        },
        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.editModal.show();
        },
        setApprove (item) {
            this.approvingItem = { ...item };
            this.$refs.approveModal.show();
        },
        approveComplete () {
            this.approvingItem = null;
            this.$refs.approveModal.close();
            this.$notify('Suggestion Approved Successfully', 'Success', { type: 'success' });
            this.$refs.table.refreshTable();
        },
        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.deleteModal.show();
        },
        deleteComplete () {
            this.deletingItem = null;
            this.$refs.deleteModal.close();
            this.$notify('Suggestion Deleted Successfully', 'Success', { type: 'success' });
            this.$refs.table.refreshTable();
        },
        userEditFailure (response) {
            const errors = response.data.errors;
            for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                    const errorMessage = `${key}:  ${errors[key]}`;
                    this.$notify(errorMessage, 'Warning', { type: 'warning' });
                }
            }
        }

    }
};
</script>

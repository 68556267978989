const usersTableFields = [
    {
        name: 'brand_name',
        title: 'Brand Name',
        titleClass: '',
        dataClass: '',
        sortField: 'brand_name'

    },
    {
        name: 'added_by',
        title: 'Added By',
        titleClass: '',
        dataClass: '',
        sortField: 'added_by'

    },
    {
        name: '__slot:actions',
        title: 'Actions',
        titleClass: 'text-right',
        dataClass: 'text-right'
    }
];

export default usersTableFields;

<template>
    <container>
        <inner-page-card heading="">
            <div v-if="detailItem">
                <p class="font-weight-600 ">Disease: <span
                    class="font-weight-lighter  text-2">{{detailItem.heading}}</span></p>
                <p class="font-weight-600 ">Species /Category: <span
                    class="font-weight-lighter  text-2">{{ detailItem.disease_cat }}</span></p>
            </div>
            <div v-else class="d-flex">
                <loading-animation style="margin-left: 34rem"></loading-animation>
                <p>Please wait...</p>
            </div>
            <inner-page-card :scrollbar="true" heading="DISEASE DESCRIPTIONS" class="mx-n4 mt-5">
                <template #buttons>
                    <slot name="buttons">
                        <btn icon="fa fa-plus" color="success" @click="itemAdd" border-radius="0"></btn>
                    </slot>
                </template>
                <modal width="50r" ref="descriptiondetailsAddModal">
                    <s-form @submit="addItem">
                        <row>
                            <div class="col-lg-6 mt-n2">
                                <validated-input label="Title :" v-model="model.title"></validated-input>
                            </div>
                            <div class="col-lg-6 mt-n2">
                                <form-group label="Description :">
                                    <ckeditor class="w-100p" :editor="editor" v-model="model.description" :disabled="loading"
                                              :config="editorConfig"></ckeditor>
                                    <!--                                        <validated-input label="Description :" name="Description" v-model="model.description"></validated-input>-->
                                </form-group>
                            </div>
                            <div class="col-lg-6 mt-n2">
                                <p class="font-weight-700 text-2 mb-2">Image <span class='text-danger'>*</span>:</p>
                                <validated-file-input v-model="model.image" border-radius="0"></validated-file-input>
                            </div>
                            <div class="col-lg-6 mt-n2">
                                <validated-input type="number" label="Order <span class='text-danger'>*</span>:" v-model="model.order"></validated-input>
                            </div>
                            <col-12>
                                <btn text="Submit" :loading="loading" loading-text="saving..."></btn>
                            </col-12>

                        </row>
                    </s-form>
                </modal>
                <vue-table-card :fields="fields" :url="listURL" class="p-0"
                                :per-page="10" ref="table" search-placeholder="Title" :extra-params="{disease_id:diseaseId}">
                    <template #actions="{rowData}">
                        <btn-group>
                            <edit-btn @click="setEditItem(rowData)"/>
                            <delete-btn @click="setDeleteItem(rowData.id)"/>
                        </btn-group>
                    </template>
                    <template #footer>
                        <edit-modal ref="descriptiondetailsEditModal" width="55r">
                            <s-form @submit="addItem">
                                <row>
                                    <div class="col-lg-6 mt-4">
                                        <validated-input label="Title :" v-model="editItem.title"></validated-input>
                                    </div>
                                    <div class="col-lg-6 mt-4">
                                        <form-group label="Description :">
                                            <ckeditor class="w-100p" :editor="editor" v-model="editItem.description" :disabled="loading"
                                                      :config="editorConfig"></ckeditor>
                                            <!--                                        <validated-input label="Description :" name="Description" v-model="model.description"></validated-input>-->
                                        </form-group>
                                    </div>
                                    <div class="col-lg-6 mt-3">
                                        <p class="font-weight-700 text-2">Image:</p>
                                        <validated-file-input v-model="editItem.image" border-radius="0"></validated-file-input>
                                    </div>
                                    <div class="col-lg-6 mt-4">
                                        <validated-input type="number" label="Order :" v-model="editItem.order"></validated-input>
                                    </div>
                                    <col-12>
                                        <btn text="Submit" :loading="loading" loading-text="saving..."></btn>
                                    </col-12>
                                </row>
                            </s-form>
                        </edit-modal>
                        <delete-modal ref="descriptiondetailsDeleteModal" :url="deleteURL" :params="{id : deleteItem}" @response="deleteSuccess">
                            <p>You are about to delete a <b>DISEASE DESCRIPTION</b>. Are you sure about it
                                ?
                            </p>
                        </delete-modal>
                    </template>
                </vue-table-card>
            </inner-page-card>
        </inner-page-card>
    </container>
</template>

<script>
import tableFields from './description-details-table-field';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'secure-axios';
import urls from '@/data/urls';
import ckeditorSettings from '../../../../components/ckeditor-settings';

export default {
    name: 'DescriptionDetailsPage',
    data () {
        return {
            loading: false,
            detailItem: '',
            editItem: '',
            deleteItem: '',
            fields: tableFields,
            detailURL: urls.disease.disease.detail,
            addEditURL: urls.disease.diseaseDescription.addEdit,
            deleteURL: urls.disease.diseaseDescription.delete,
            listURL: urls.disease.diseaseDescription.list,
            diseaseId: this.$route.params.id,
            data: [
                {
                    title: 'ryytiyi',
                    description: 'jgfhdxdfcvg',
                    image: '/media/article/1580500463347_GASEOUS_ANESTHESIA_CIRCUIT..jpg',
                    order: 343434
                }
            ],
            model: {
                disease: this.$route.params.id,
                order: '',
                image: '',
                description: '',
                title: ''
            },
            editor: ckeditorSettings.ClassicEditor,
            editorConfig: ckeditorSettings.editorConfig
        };
    },
    mounted () {
        this.fetchData();
    },
    methods: {
        clearForm (item) {
            item.order = '';
            item.image = '';
            item.description = '';
            item.title = '';
        },
        deleteSuccess (item) {
            this.$refs.descriptiondetailsDeleteModal.close();
            if (item.success) {
                this.$notify('Deleted Successfully', 'Success', {
                    type: 'success'
                });
                this.$refs.table.refreshTable();
            } else {
                console.log('item error', item.errors.Id);
                this.$notify(item.errors.Id, 'warning', {
                    type: 'warning'
                });
            }
        },
        async addItem () {
            try {
                var errorMessage = '';
                this.loading = true;
                if (this.editItem) {
                    this.editItem.disease = this.$route.params.id;
                    console.log('in edit');
                    this.response = await axios.form(this.addEditURL, this.editItem);
                } else {
                    console.log('in add');

                    this.response = await axios.form(this.addEditURL, this.model);
                }
                const result = this.response.data;
                console.log('result', result);
                if (result.success === true) {
                    if (this.editItem) {
                        this.$notify('Updated Successfully', 'Success', { type: 'success' });
                        this.loading = false;
                        this.editingItem = '';
                        this.$refs.descriptiondetailsEditModal.close();
                    } else {
                        this.$notify('Added Successfully', 'Success', { type: 'success' });
                        this.loading = false;
                        this.$refs.descriptiondetailsAddModal.close();
                    }
                    this.$refs.table.refreshTable();
                } else {
                    const errors = this.response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            if (key === '__all__') {
                                errorMessage = `${errors[key]}`;
                            } else {
                                errorMessage = `${key}:  ${errors[key]}`;
                            }
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }

                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.this.response.status + ' ' + error.this.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        },
        async fetchData () {
            try {
                const response = await axios.form(this.detailURL, { id: this.diseaseId });
                const result = response.data;
                this.detailItem = result.data;
                console.log('detailItem', this.detailItem);
                if (result.success === true) {
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
            }
        },
        itemAdd () {
            this.editItem = '';
            this.clearForm(this.model);
            this.$refs.descriptiondetailsAddModal.show();
        },
        setEditItem (item) {
            console.log(item);
            this.editItem = '';
            this.editItem = { ...item };
            this.$refs.descriptiondetailsEditModal.show();
        },
        setDeleteItem (item) {
            console.log(item);
            this.deleteItem = item;
            this.$refs.descriptiondetailsDeleteModal.show();
        }
    }
};
</script>

<style scoped>

</style>

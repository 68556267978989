var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('container',[_c('table-page',{attrs:{"heading":"CATEGORY"},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('add-btn',{on:{"click":function($event){return _vm.$refs.addCategoryModal.show()}}})]},proxy:true}])},[_c('vue-table-card',{ref:"table",attrs:{"fields":_vm.fields,"url":_vm.listUrl},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var rowData = ref.rowData;
return [_c('btn-group',[_c('edit-btn',{on:{"click":function($event){return _vm.SetEditCategory(rowData)}}}),_c('delete-btn',{on:{"click":function($event){return _vm.setDeleteCategory(rowData)}}})],1)]}},{key:"footer",fn:function(){return [_c('modal',{ref:"addCategoryModal",attrs:{"title":"Add Category","width":"35r"}},[_c('b-form',{attrs:{"save-url":_vm.addEditUrl},on:{"failure":_vm.categoryAddEditFailure,"success":function($event){return _vm.formSuccess('A')}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var model = ref.model;
var loading = ref.loading;
return [_c('row',[_c('col-12',{attrs:{"lg":"8"}},[_c('validated-input',{attrs:{"label":"Name <span class='text-danger'>*</span> :","rules":_vm.rules.category_name,"disabled":loading},model:{value:(model.category_name),callback:function ($$v) {_vm.$set(model, "category_name", $$v)},expression:"model.category_name"}})],1),_c('col-12',[_c('submit-btn',{attrs:{"text":"Save","loading-text":"Saving...","size":"sm","disabled":loading,"loading":loading}})],1)],1)]}}])})],1),_c('edit-modal',{ref:"categoryEditModal",attrs:{"width":"35r"}},[_c('b-form',{attrs:{"save-url":_vm.addEditUrl,"initial-data":_vm.editingItem},on:{"failure":_vm.categoryAddEditFailure,"success":function($event){return _vm.formSuccess('M')}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var model = ref.model;
var loading = ref.loading;
return [_c('row',[_c('col-12',{attrs:{"lg":"8"}},[_c('validated-input',{attrs:{"label":"Name <span class='text-danger'>*</span> :","rules":_vm.rules.category_name,"disabled":loading},model:{value:(model.category_name),callback:function ($$v) {_vm.$set(model, "category_name", $$v)},expression:"model.category_name"}})],1),_c('col-12',[_c('submit-btn',{attrs:{"text":"Save","loading-text":"Saving...","size":"sm","disabled":loading,"loading":loading}})],1)],1)]}}])})],1),_c('delete-modal',{ref:"categoryDeleteModal",attrs:{"url":_vm.deleteUrl,"params":_vm.deletingItem},on:{"response":_vm.deleteComplete},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading-animation'),_vm._v(" Please wait while we delete "),_c('b',{domProps:{"innerHTML":_vm._s(_vm.deletingItem && _vm.deletingItem.name)}}),_vm._v(". ")]},proxy:true}])},[_c('p',[_vm._v("You are about to delete the category "),(_vm.deletingItem)?_c('b',[_vm._v(" "+_vm._s(_vm.deletingItem.name))]):_vm._e(),_vm._v(".Are you sure?")])]),_c('modal',{ref:"userViewModal",attrs:{"title":"User Info"}},[_c('p',[_vm._v(" Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab ad adipisci alias aliquid architecto aut blanditiis corporis cum, dolorum earum eligendi excepturi ipsum maiores nam nobis numquam odio officia, omnis pariatur provident quaerat quibusdam quos sed similique sunt veritatis voluptatibus. Harum itaque minima nesciunt quae recusandae sequi ut? Est, sunt. ")])])]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
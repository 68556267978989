<template>
     <container>
        <inner-page-card class="" :scrollbar="true" heading="TREATMENTS">
            <template #buttons>
                <slot name="buttons">
                    <btn icon="fa fa-plus" color="success" @click="itemAdd" border-radius="0"></btn>
                </slot>
            </template>
                   <modal width="50r" title="Add Treatment" ref="treatmentslistAddModal">
                        <s-form @submit="addItem">
                        <div class="row">
                            <div class="col-lg-6">
                                <validated-ajax-select :url="diseaseUrl" label="Disease <span class='text-danger'>*</span>:" v-model="model.disease" :rules="{required:true}"></validated-ajax-select>
                            </div>
                            <div class="col-lg-6">
                                <validated-input label="Treatment Content <span class='text-danger'>*</span>:" v-model="model.treatment_content" :rules="{required:true}"></validated-input>
                            </div>
                            <div class="col-lg-12">
                                <btn text="Submit" :loading="loading" loading-text="Saving..."></btn>
                            </div>
                        </div>
                            </s-form>
                    </modal>
            <vue-table-card :fields="fields" :url="listUrl"
            :per-page="10" ref="table" search-placeholder="Title" :extra-params="{disease_id : diseaseId}">

                <template #actions="{rowData}">
                    <btn-group>
                        <edit-btn @click="EditUser(rowData)"/>
                        <delete-btn @click="setDeleteUser(rowData)"/>
                    </btn-group>
                </template>
                <template #footer>
                    <edit-modal ref="treatmentlistEditModal">
                        <s-form @submit="addItem">
                            <row>
                                 <div class="col-lg-6">
                                <validated-ajax-select :url="diseaseUrl" label="Disease <span class='text-danger'>*</span>:" v-model="editingItem.disease" :rules="{required:true}"></validated-ajax-select>
                            </div>
                            <div class="col-lg-6">
                                <validated-input label="Treatment Content <span class='text-danger'>*</span>:" v-model="editingItem.treatment_content" :rules="{required:true}"></validated-input>
                            </div>
                            <div class="col-lg-12">
                                <btn text="Submit" :loading="loading" loading-text="Saving..."></btn>
                            </div>
                            </row>
                        </s-form>
                    </edit-modal>
                    <delete-modal ref="treatmentlistDeleteModal" :url="deleteUrl"  :params="{id : deletingItem.id}" @response="deleteSuccess">
                        <p>You are about to delete {{deletingItem.disease_name}}.
                            Are you sure about it ?
                        </p>
                    </delete-modal>
                </template>
            </vue-table-card>

        </inner-page-card>
    </container>
</template>

<script>
import InnerPageCard from '../../../components/InnerPageCard';
import tableFields from './treatments-list-table-field';
import urls from '@/data/urls';
import axios from 'secure-axios';
export default {
    name: 'TreatmentsListPage',
    components: InnerPageCard,
    data () {
        return {
            loading: false,
            diseaseId: this.$route.params.id,
            addEditUrl: urls.disease.treatment.addEdit,
            diseaseUrl: urls.diagnosis.completeBloodCount.diseaseVueSelect,
            editingItem: '',
            deleteUrl: urls.disease.treatment.delete,
            deletingItem: '',
            listUrl: urls.disease.treatment.list,
            fields: tableFields,
            data: [
                {
                    disease: 'HORDEOLUM',
                    treatment_content: 'Surgery is good'
                }
            ],
            model: {
                disease: '',
                treatment_content: ''
            }
        };
    },
    methods: {
        deleteSuccess (item) {
            this.$refs.treatmentlistDeleteModal.close();
            if (item.success) {
                this.$notify('Deleted Successfully', 'Success', {
                    type: 'success'
                });
                this.$refs.table.refreshTable();
            } else {
                console.log('item error', item.errors.Id);
                this.$notify(item.errors.Id, 'warning', {
                    type: 'warning'
                });
            }
        },
        async addItem () {
            try {
                this.loading = true;
                if (this.editingItem) {
                    console.log('in edit');
                    this.response = await axios.form(this.addEditUrl, this.editingItem);
                } else {
                    console.log('in add');

                    this.response = await axios.form(this.addEditUrl, this.model);
                }
                const result = this.response.data;
                console.log('result', result);
                if (result.success === true) {
                    if (this.editingItem) {
                        this.$notify('Updated Successfully', 'Success', { type: 'success' });
                        this.loading = false;
                        this.editingItem = '';
                        this.$refs.treatmentlistEditModal.close();
                    } else {
                        this.$notify('Added Successfully', 'Success', { type: 'success' });
                        this.loading = false;
                        this.$refs.treatmentslistAddModal.close();
                    }
                    this.$refs.table.refreshTable();
                } else {
                    const errors = this.response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.this.response.status + ' ' + error.this.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        },
        clearData (item) {
            item.disease = '';
            item.treatment_content = '';
        },
        EditUser (item) {
            console.log(item);
            this.editingItem = '';
            this.editingItem = item;
            this.$refs.treatmentlistEditModal.show();
        },
        setDeleteUser (item) {
            console.log(item);
            this.deletingItem = item;
            this.$refs.treatmentlistDeleteModal.show();
        },
        itemAdd () {
            this.editingItem = '';
            this.clearData(this.model);
            this.$refs.treatmentslistAddModal.show();
        }
    }

};
</script>

<style scoped>

</style>

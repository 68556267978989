<template>
    <table-page heading="Brand table">
        <vue-table-card :url="listUrl" :fields="fields" :table-data="data" ref="table">
            <template #actions="{rowData}">
                <btn-group>
                    <btn-dark text="Recommends" @click="viewRecommends(rowData)"/>
                    <view-btn @click="viewItem(rowData)"/>
                    <edit-btn @click="setEdit(rowData)"/>
                    <delete-btn @click=" setDeleteUser(rowData)"/>
                </btn-group>
            </template>
            <template #footer>
                <!--                Edit Modal-->
                <edit-modal ref="EditModal" width="45r">
                     <b-form :save-url="addEditUrl" @failure="userEditFailure" @success="formSuccess('M')" v-slot="{model, loading}" :initial-data="editingItem">
                    <row>
                        <col-12>
                            <validated-input label="Brand name <span class='text-danger'>*</span> :" v-model="model.brand_name" :rules="rules.brand_name"
                                             :disabled="loading"></validated-input>
                        </col-12>
                        <col-12>
                            <form-group
                                :disabled="loading" label="Categories <span class='text-danger'>*</span>:">
                                <ckeditor class="w-100p" :editor="editor" v-model="model.categories"
                                          :rules="rules.categories"
                                          :config="editorConfig"></ckeditor>
                            </form-group>
                        </col-12>
                        <col-12>
                            <form-group
                                :disabled="loading" label="Contents <span class='text-danger'>*</span>:">
                                <ckeditor class="w-100p" :editor="editor" v-model="model.contents"
                                          :rules="rules.contents"
                                          :config="editorConfig"></ckeditor>
                            </form-group>
                        </col-12>
                        <col-12>
                            <validated-input label="Company name <span class='text-danger'>*</span> :" v-model="model.company_name" :rules="rules.company_name"
                                             :disabled="loading"></validated-input>
                        </col-12>
                        <col-12>
                            <form-group
                                :disabled="loading" label="Availability <span class='text-danger'>*</span>:">
                                <ckeditor class="w-100p" :editor="editor" v-model="model.availability"
                                          :rules="rules.availability"
                                          :config="editorConfig"></ckeditor>
                            </form-group>
                        </col-12>
                        <col-12>
                            <form-group
                                :disabled="loading" label="Composition <span class='text-danger'>*</span>:">
                                <ckeditor class="w-100p" :editor="editor" v-model="model.composition"
                                          :rules="rules.composition"
                                          :config="editorConfig"></ckeditor>
                            </form-group>
                        </col-12>
                        <col-12>
                            <form-group
                                :disabled="loading" label="Price <span class='text-danger'>*</span>:">
                                <ckeditor class="w-100p" :editor="editor" v-model="model.price"
                                          :rules="rules.price"
                                          :config="editorConfig"></ckeditor>
                            </form-group>
                        </col-12>
                        <col-12>
                            <form-group
                                :disabled="loading" label="Indication <span class='text-danger'>*</span>:">
                                <ckeditor class="w-100p" :editor="editor" v-model="model.indication"
                                          :rules="rules.indication"
                                          :config="editorConfig"></ckeditor>
                            </form-group>
                        </col-12>
                        <col-12>
                            <form-group
                                :disabled="loading" label="Dosage <span class='text-danger'>*</span>:">
                                <ckeditor class="w-100p" :editor="editor" v-model="model.dosage"
                                          :rules="rules.dosage"
                                          :config="editorConfig"></ckeditor>
                            </form-group>
                        </col-12>
                        <col-12>
                            <save-btn loading-text="Saving..." size="sm" :disabled="loading"
                             :loading="loading"/>
                        </col-12>
                    </row>
                </b-form>
                </edit-modal>
                 <!--Delete Modal-->
                 <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
                        <p>You are about to delete
                            <b v-if="deletingItem"> {{ deletingItem.brand_name }}</b>.Are you sure about it ?</p>
                         <template #loading>
                        <loading-animation/>
                        Please wait while we delete <b v-html="deletingItem && deletingItem.brand_name"></b>.
                    </template>
                    </delete-modal>
                <!--                Recommends Table Modal-->
                <modal title="Home" ref="recommendsViewModal">
                    <div v-if="!selectedItem">
                     <loading-animation></loading-animation>
                    </div>
                    <vue-table-card :url="recommendedBrandListUrl+'?id='+selectedItem.id" :fields="recommendsFields" :table-data="recommendsData" v-else>
                    </vue-table-card>
                </modal>
            </template>
        </vue-table-card>
    </table-page>
</template>

<script>
import brandsTableFields from './brands-table-fields';
import recommendsTableFields from './brand-recommends-table-fields';
import ckeditorSettings from '../../../components/ckeditor-settings';
import urls from '../../../data/urls';

export default {
    name: 'DrugsViewCategoryPage',
    data () {
        return {
            fields: brandsTableFields.tableFields,
            recommendsFields: recommendsTableFields.tableFields,
            editor: ckeditorSettings.ClassicEditor,
            editorConfig: ckeditorSettings.editorConfig,
            data: brandsTableFields.sampleData,
            recommendsData: recommendsTableFields.sampleData,
            listUrl: urls.drugs.brand.listUrl,
            deleteUrl: urls.drugs.brand.delete,
            addEditUrl: urls.drugs.brand.addEditUrl,
            detail: urls.drugs.brand.detail,
            recommendedBrandListUrl: urls.drugs.brand.recommendedBrandList,
            rules: {
                brand_name: {
                    required: true
                },
                categories: {
                    required: true
                },
                contents: {
                    required: true
                },
                company_name: {
                    required: true
                },
                availability: {
                    required: true
                },
                composition: {
                    required: true
                },
                price: {
                    required: true
                },
                indication: {
                    required: true
                },
                dosage: {
                    required: true
                }

            },
            deletingItem: {
                name: ''
            },
            editingItem: null,
            selectedItem: null
        };
    },
    methods: {
        formSuccess (action) {
            const refs = this.$refs;
            // this.$refs.addModal.close();
            this.$refs.EditModal.close();
            if (action === 'A') {
                this.$notify('Brand Added Successfully', 'Success', {
                    type: 'success'
                });
            } else {
                this.$notify('Brand Updated Successfully', 'Success', {
                    type: 'success'
                });
            }
            refs.table.refreshTable();

            this.editingItem = null;
        },
        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.EditModal.show();
        },
        viewItem (item) {
            console.log(item);
            this.$router.push('/brand-details/' + item.id);
        },
        viewRecommends (item) {
            console.log(item);
            this.selectedItem = item;
            this.$refs.recommendsViewModal.show();
        },
        setDeleteUser (item) {
            console.log(item);
            this.deletingItem = { ...item };
            this.$refs.deleteModal.show();
        },
        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.deleteModal.close();
            this.$notify('Brand Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        userEditFailure (response) {
            const errors = response.data.errors;
            for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                    const errorMessage = `${key}:  ${errors[key]}`;
                    this.$notify(errorMessage, 'Warning', { type: 'warning' });
                }
            }
        }
    }
};
</script>

<style scoped>

</style>

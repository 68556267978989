<template>
    <container>
        <inner-page-card class="" :scrollbar="true" heading="DISEASE CATEGORY">
            <template #buttons>
                <slot name="buttons">
                    <btn icon="fa fa-plus" color="success" @click="addUser" border-radius="0"></btn>

                </slot>
            </template>
    <modal width="50r" ref="diseasespeciesAddModal" title="Add Details">
                        <s-form @submit="addItem">
                        <div class="row">
                            <div class="col-lg-6 mt-2">
                                <validated-input label="Animal name <span class='text-danger'>*</span> :" v-model="model.animal_name" :rules="{required:true}"></validated-input>
                            </div>
                            <div class="col-lg-6">
                                <p class="font-weight-700 text-2">Category icon:</p>
                                <validated-file-input v-model="model.category_icon" :rules="{required:false}" border-radius="0"></validated-file-input>
                            </div>
                            <div class="col-lg-12">
                                <btn text="Submit" :loading="loading" loading-text="saving..."></btn>
                            </div>
                        </div>
                            </s-form>
                    </modal>
            <vue-table-card :fields="fields" :url="listingUrl"
             :per-page="10" ref="table" search-placeholder="name">
                <template #actions="{rowData}">
                    <btn-group>
                        <edit-btn @click="EditUser(rowData)"/>
                        <delete-btn @click="setDeleteUser(rowData)"/>
                    </btn-group>
                </template>
                <template #footer>
                    <edit-modal ref="diseasespeciesEditModal">
                        <s-form @submit="addItem">
                            <row>
                                <div class="col-lg-6 mt-2">
                                    <validated-input label="Animal name <span class='text-danger'>*</span> :"  v-model="editingItem.animal_name"  :rules="{required:true}" ></validated-input>
                                </div>
                                <div class="col-lg-6">
                                    <p class="font-weight-700 text-2">Category icon:</p>
                                    <validated-file-input  :rules="{required: false}"  v-model="editingItem.category_icon" border-radius="0"></validated-file-input>
                                </div>
                                <div class="col-lg-12">
                                    <btn text="Submit" :loading="loading" loading-text="saving..."></btn>
                                </div>
                            </row>
                        </s-form>
                    </edit-modal>
                    <delete-modal ref="diseasespeciesDeleteModal" :url="deletingUrl" :params="{id : deletingItem.id}" @response="deleteSuccess">
                        <p>You are about to delete {{deletingItem.animal_name}}.
                            Are you sure about it ?
                        </p>
                    </delete-modal>
                </template>
            </vue-table-card>

        </inner-page-card>
    </container>
</template>

<script>
import InnerPageCard from '@components/InnerPageCard';
import tableFields from './diseases-species-table-fields';
import axios from 'secure-axios';
import urls from '@/data/urls';

export default {
    name: 'DiseasesSpeciesPage',
    components: InnerPageCard,
    data () {
        return {
            loading: false,
            response: '',
            fields: tableFields,
            data: [
                {
                    animal_name: 'Goat and Sheep'
                }
            ],
            model: {
                animal_name: '',
                category_icon: ''
            },
            editingUrl: urls.disease.diseaseCategory.addEdit,
            deletingUrl: urls.disease.diseaseCategory.delete,
            listingUrl: urls.disease.diseaseCategory.list,

            editingItem: '',
            listingItem: '',
            deletingItem: ''
        };
    },
    methods: {
        clrForm (item) {
            item.animal_name = '';
            item.category_icon = '';
        },
        deleteSuccess (item) {
            this.$refs.diseasespeciesDeleteModal.close();
            if (item.success) {
                this.$notify('Deleted Successfully', 'Success', {
                    type: 'success'
                });
                this.$refs.table.refreshTable();
            } else {
                console.log('item error', item.errors.Id);
                this.$notify(item.errors.Id, 'warning', {
                    type: 'warning'
                });
            }
        },
        async addItem () {
            try {
                this.loading = true;
                if (this.editingItem) {
                    console.log('in edit');
                    this.response = await axios.form(this.editingUrl, this.editingItem);
                } else {
                    console.log('in add');
                    this.response = await axios.form(this.editingUrl, this.model);
                }
                const result = this.response.data;
                console.log('result', result);
                if (result.success === true) {
                    if (this.editingItem) {
                        this.$notify('Updated Successfully', 'Success', { type: 'success' });
                        this.loading = false;
                        this.editingItem = '';
                        this.$refs.diseasespeciesEditModal.close();
                    } else {
                        this.$notify('Added Successfully', 'Success', { type: 'success' });
                        this.loading = false;
                        this.$refs.diseasespeciesAddModal.close();
                    }
                    this.$refs.table.refreshTable();
                } else {
                    const errors = this.response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.this.response.status + ' ' + error.this.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        },
        EditUser (item) {
            console.log(item);
            this.editingItem = '';
            this.editingItem = { ...item };
            this.$refs.diseasespeciesEditModal.show();
        },
        setDeleteUser (item) {
            console.log(item);
            this.deletingItem = item;

            this.$refs.diseasespeciesDeleteModal.show();
        },
        addUser () {
            this.editingItem = '';
            this.clrForm(this.model);
            this.$refs.diseasespeciesAddModal.show();
        }
    }

};
</script>

<style scoped>

</style>

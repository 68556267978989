<template>
    <div class="fl-te-c bs-5 p-4">
        <details-list>
            <details-list-item label-width="15r" :set-widths="false" label="Section" :value="section"/>
            <details-list-item label-width="15r" :set-widths="false" label="Message" :value="message"/>
            <details-list-item label-width="15r" :set-widths="false" label="Reported" :value="reported"/>
        </details-list>
        <slot name="buttons">
             <btn color="success" text="Fixed"/>
        </slot>

    </div>
</template>

<script>
export default {
    name: 'IssueReportItem',
    props: {
        section: { type: String, default: 'section' },
        message: { type: String, default: 'message' },
        reported: { type: String, default: 'reported' }
    },
    methods: {

    }
};
</script>

<style scoped>

</style>

<template>
    <div>
        <container>
            <inner-page-card heading="Articles">
                <custom-size-el>
                    <transition-fade>
                        <div class="fl-x-cc h-100p" v-if="!showForm" key="anim">
                            <loading-animation></loading-animation>
                        </div>
                        <b-form :save-url="addUrl" @failure="FormFailure" @success="formSuccess('A')" v-slot="{model, loading}">
                            <row>
                                <div class="col-lg-12">
                                    <validated-input label="Title <span class='text-danger'>*</span> :" v-model="model.title" :rules="rules.title"
                                                     :disabled="loading"></validated-input>
                                </div>
                                <div class="col-lg-6">
                                    <validated-input label="Author <span class='text-danger'>*</span> :" v-model="model.author" :rules="rules.author"
                                                     :disabled="loading"></validated-input>
                                </div>
                                <div class="col-lg-6">
                                    <validated-input label="Batch / Designation <span class='text-danger'>*</span> :" v-model="model.batch" :rules="rules.batch"
                                                     :disabled="loading"></validated-input>
                                </div>
                                <col-12>
                                    <form-group
                                        label="Content <span class='text-danger'>*</span>:">
                                        <ckeditor class="w-100p" :editor="editor" v-model="model.content"
                                                  :rules="rules.content"
                                                  :config="editorConfig" :disabled="loading"></ckeditor>
                                    </form-group>
                                </col-12>
                                <col-12>
                                    <save-btn loading-text="Saving..." size="sm" :disabled="loading"
                                              :loading="loading"/>
                                </col-12>
                            </row>
                        </b-form>
                    </transition-fade>
                </custom-size-el>
            </inner-page-card>
        </container>
    </div>
</template>

<script>
import urls from '../../../data/urls';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    name: 'ArticleOldAdd',
    data () {
        return {
            addUrl: urls.article.oldArticle.addEdit,
            rules: {
                title: {
                    required: true
                },
                author: {
                    required: true
                },
                batch: {
                    required: true
                },
                content: {
                    required: true
                }

            },
            showForm: true,
            editor: ClassicEditor,
            editorConfig: {}
        };
    },
    methods: {
        formSuccess (action) {
            console.log('form success');
            if (action === 'A') {
                this.$notify('Article Added Successfully', 'Success', {
                    type: 'success'
                });
                this.showForm = false;
                const that = this;
                setTimeout(function () {
                    that.showForm = true;
                }, 1000);
            }
        },
        FormFailure (response) {
            const errors = response.data.errors;
            console.log('errors', errors);
            for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                    const errorMessage = `${key}:  ${errors[key]}`;
                    this.$notify(errorMessage, 'Warning', { type: 'warning' });
                }
            }
        }
    }
};
</script>

<style scoped>

</style>

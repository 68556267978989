<template>
   <container>
        <inner-page-card heading="">
             <template #buttons>
                    <slot name="buttons">
                        <btn icon="fa fa-plus" color="success" text="desc" @click="viewReports" border-radius="0"></btn>
                    </slot>
                </template>
            <div v-if="detailItem">
                <p class="font-weight-600 ">Title: <span
                    class="font-weight-lighter  text-2">{{detailItem.heading}}</span></p>
                <p class="font-weight-600 ">Species: <span
                    class="font-weight-lighter  text-2">{{detailItem.disease_cat}}</span></p>
            </div>
             <div v-else class="d-flex">
                <loading-animation style="margin-left: 34rem"></loading-animation>
                <p>Please wait...</p>
            </div>
            <inner-page-card :scrollbar="true" heading="Reports" class="mx-n4 mt-5">
                <vue-table-card :fields="fields" :url="listURL" class="p-0" :extra-params="{id : diseaseId}"
                :per-page="10" ref="table" search-placeholder="Report" :show-search-box="true">
                     <template #actions="{rowData}">
                        <btn-group>
                            <delete-btn @click="setDeleteUser(rowData.id)"/>
                        </btn-group>
                    </template>
                    <template #footer>
                        <delete-modal ref="DeleteModal" :url="deleteURL" :params="{id : deleteItem}" @response="deleteSuccess">
                            <p>You are about to delete a <b>Report</b>. Are you sure about it
                                ?
                            </p>
                        </delete-modal>
                    </template>
                </vue-table-card>
            </inner-page-card>
        </inner-page-card>
    </container>
</template>

<script>
import tableFields from './reports-table-fields';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'secure-axios';
import urls from '@/data/urls';

export default {
    name: 'ReportsDetailsPage',
    data () {
        return {
            diseaseId: this.$route.params.id,
            detailItem: '',
            deleteItem: '',
            detailURL: urls.disease.disease.detail,
            listURL: urls.disease.diseaseReport.disease,
            deleteURL: urls.disease.diseaseReport.delete,

            fields: tableFields,
            editor: ClassicEditor,
            editorData: '<p> </p>',
            editorConfig: {}
        };
    },
    mounted () {
        this.fetchData();
    },
    methods: {
        deleteSuccess (item) {
            this.$refs.DeleteModal.close();
            if (item.success) {
                this.$notify('Deleted Successfully', 'Success', {
                    type: 'success'
                });
                this.$refs.table.refreshTable();
            } else {
                console.log('item error', item.errors.Id);
                this.$notify(item.errors.Id, 'warning', {
                    type: 'warning'
                });
            }
        },
        async fetchData () {
            try {
                const response = await axios.form(this.detailURL, { id: this.diseaseId });
                const result = response.data;
                this.detailItem = result.data;
                console.log('detailItem', this.detailItem);
                if (result.success === true) {
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
            }
        },
        /* viewUser (item) {
            console.log(item);
            this.$refs.descriptiondetailsViewModal.show();
        }, */
        viewReports () {
            this.$router.push('/description-detailspage/' + this.diseaseId + '/');
        },
        setDeleteUser (item) {
            console.log(item);
            this.deleteItem = item;
            this.$refs.DeleteModal.show();
        }
    }
};
</script>

<style scoped>

</style>

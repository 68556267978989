<template>
    <container>
        <inner-page-card class="" :scrollbar="true" heading="DICTIONARY">
            <template #buttons>
                <slot name="buttons">
                    <btn icon="fa fa-plus" color="success" @click="itemAdd" border-radius="0"></btn>
                </slot>
            </template>
                    <modal width="50r" ref="dictionaryViewModal" title="Add Details">
                        <s-form @submit="addItem">
                        <div class="row">
                            <div class="col-lg-6">
                                <validated-input label="Title <span class='text-danger'>*</span>:"  v-model="model.title" :rules="{required:true}"></validated-input>
                            </div>
                            <div class="col-lg-6">
                                <form-group
                                        :disabled="loading" label="Description <span class='text-danger'>*</span>:"  :rules="{required:true}">
                                        <ckeditor class="w-100p" :editor="editor" v-model="model.description"></ckeditor>
                                    </form-group>
                            </div>
                            <div class="col-lg-12">
                                <btn text="Submit" :loading="loading" loading-text="Saving...">Submit</btn>
                            </div>
                        </div>
                        </s-form>
                    </modal>
            <vue-table-card :fields="fields" :url="listUrl"
            :per-page="10" ref="table" search-placeholder="Title">
                <template #actions="{rowData}">
                    <btn-group>
                        <edit-btn @click="EditItem(rowData)"/>
                        <delete-btn @click="setDeleteItem(rowData)"/>
                    </btn-group>
                </template>
                <template #footer>
                    <edit-modal ref="dictionaryEditModal">
                        <s-form @submit="addItem">
                            <row>
                                 <div class="col-lg-6">
                                <validated-input label="Title <span class='text-danger'>*</span>:" v-model="editingItem.title" :rules="{required:true}"></validated-input>
                            </div>
                            <div class="col-lg-6">
                                <form-group
                                        :disabled="loading" label="Description <span class='text-danger'>*</span>:">
                                        <ckeditor class="w-100p" :editor="editor" v-model="editingItem.description" :rules="{required:true}"></ckeditor>
                                    </form-group>
                            </div>
                            <div class="col-lg-12">
                                <btn text="Submit" :loading="loading" loading-text="Saving..."></btn>
                            </div>
                            </row>
                        </s-form>
                    </edit-modal>
                    <delete-modal ref="dictionaryDeleteModal" :url="deleteUrl" :params="{id:deleteItem.id}" @response="deleteSuccess">
                        <p>You are about to delete {{deleteItem.title}}.<br>
                            Are you sure about it ?
                        </p>
                    </delete-modal>
                </template>
            </vue-table-card>

        </inner-page-card>
    </container>
</template>

<script>
import InnerPageCard from '@components/InnerPageCard';
import tableFields from './dictionary-table-fields';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import urls from '@/data/urls';
import axios from 'secure-axios';
export default {
    name: 'DictionaryPage',
    components: InnerPageCard,
    data () {
        return {
            listUrl: urls.disease.dictionary.list,
            addEditUrl: urls.disease.dictionary.addEdit,
            deleteUrl: urls.disease.dictionary.delete,
            editingItem: '',
            deleteItem: '',
            loading: false,
            fields: tableFields,
            data: [
                {
                    title: 'Abrasion',
                    description: 'A surface discontinuity caused by roughening or scratching or it is a wearing away of the upper layer of skin. It involve minimal bleeding. Abrasions are not deep wounds'
                }
            ],
            model: {
                title: '',
                description: ''
            },
            editor: ClassicEditor,
            editorConfig: {}
        };
    },
    methods: {
        async addItem () {
            try {
                this.loading = true;
                if (this.editingItem) {
                    this.editingItem.disease = this.$route.params.id;
                    console.log('in edit');
                    this.response = await axios.form(this.addEditUrl, this.editingItem);
                } else {
                    console.log('in add');

                    this.response = await axios.form(this.addEditUrl, this.model);
                }
                const result = this.response.data;
                console.log('result', result);
                if (result.success === true) {
                    if (this.editingItem) {
                        this.$notify('Updated Successfully', 'Success', { type: 'success' });
                        this.loading = false;
                        this.editingItem = '';
                        this.$refs.dictionaryEditModal.close();
                    } else {
                        this.$notify('Added Successfully', 'Success', { type: 'success' });
                        this.loading = false;
                        this.$refs.dictionaryViewModal.close();
                    }
                    this.$refs.table.refreshTable();
                } else {
                    const errors = this.response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.this.response.status + ' ' + error.this.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        },
        clearData (item) {
            item.title = '';
            item.description = '';
        },
        deleteSuccess (item) {
            this.$refs.dictionaryDeleteModal.close();
            if (item.success) {
                this.$notify('Deleted Successfully', 'Success', {
                    type: 'success'
                });
                this.$refs.table.refreshTable();
            } else {
                console.log('item error', item.errors.Id);
                this.$notify(item.errors.Id, 'warning', {
                    type: 'warning'
                });
            }
        },
        EditItem (item) {
            console.log(item);
            this.editingItem = '';
            this.editingItem = item;
            this.$refs.dictionaryEditModal.show();
        },
        setDeleteItem (item) {
            console.log(item);
            this.deleteItem = item;
            this.$refs.dictionaryDeleteModal.show();
        },
        itemAdd () {
            this.editingItem = '';
            this.clearData(this.model);
            this.$refs.dictionaryViewModal.show();
        }
    }
};
</script>

<style scoped>

</style>

const usersTableFields = [
    {
        name: 'name',
        title: 'Name',
        titleClass: '',
        dataClass: '',
        sortField: 'name'

    },
    {
        name: 'category',
        title: 'Category',
        titleClass: '',
        dataClass: '',
        sortField: 'category'

    },
    {
        name: 'college',
        title: 'College',
        titleClass: '',
        dataClass: '',
        sortField: 'college'

    },
    {
        name: 'department',
        title: 'Department',
        titleClass: '',
        dataClass: '',
        sortField: 'department'

    },
    {
        name: 'phone',
        title: 'Phone',
        titleClass: '',
        dataClass: '',
        sortField: 'phone'

    },
    {
        name: 'designation',
        title: 'Designation',
        titleClass: '',
        dataClass: '',
        sortField: 'designation'

    },
    {
        name: '__slot:actions',
        title: 'Actions',
        titleClass: 'text-right',
        dataClass: 'text-right'
    }
];

export default usersTableFields;

<template>
    <container>
        <inner-page-card heading="LISTING">
            <template #buttons>
                <add-btn @click="$refs.addModal.show()"/>
            </template>
            <vue-table-card :url="listUrl" :fields="fields" :table-data="data" ref="table">
                <template #actions="{rowData}">
                    <btn-group>
                        <edit-btn @click="setEdit (rowData)"/>
                        <delete-btn @click="setDeleteUser(rowData)"/>
                    </btn-group>
                </template>
                <template #footer>
                    <edit-modal ref="addModal" width="40r" title="LISTING">
                        <b-form :save-url="addUrl" @failure="EditFailure" @success="formSuccess('A')" v-slot="{model, loading}" class="">
                            <row>
                                <div class="col-lg-6 pt-1">
                                    <validated-ajax-select label="Company <span class='text-danger'>*</span>:" class="mt-2 font-weight-700 text-2 v-select-dropdown-max-h-10r"
                                                           border-radius="0"
                                                           :url="listVueSelect" :disabled="false" v-model="model.company"
                                                           :rules="rules.company"
                                                           name="Company"></validated-ajax-select>
                                </div>
                                <div class="col-lg-6 mt-2">
                                    <validated-input label="Order <span class='text-danger'>*</span>:" class="pt-1 pr-0" type="number" v-model="model.order" :rules="rules.order"
                                                     :disabled="loading"/>
                                </div>
                                <col-12>
                                    <submit-btn loading-text="Saving..." size="sm" class="mt-7" :disabled="loading"
                                                :loading="loading"/>
                                </col-12>
                            </row>
                        </b-form>
                    </edit-modal>
                    <edit-modal ref="EditModal">
                        <b-form :save-url="addUrl" @failure="userEditFailure" @success="formSuccess('M')" v-slot="{model, loading}"
                                :initial-data="editingItem">
                            <row>
                                <div class="col-lg-6 pb-7">
                                    <validated-ajax-select label="Company <span class='text-danger'>*</span>:" :border-radius="0" class=" pl-0 font-weight-700 text-2 v-select-dropdown-max-h-10r"
                                                           :url="listVueSelect" :disabled="false" v-model="model.company"
                                                           :rules="rules.company"
                                                           name="Company"></validated-ajax-select>
                                </div>
                                <div class="col-lg-6">
                                    <validated-input label="Order <span class='text-danger'>*</span>:" class=" pr-0" type="number"
                                                     v-model="model.order" :rules="rules.order" :disabled="loading"/>
                                </div>
                                <col-12>
                                    <submit-btn loading-text="Saving..." size="sm" :disabled="loading"
                                                :loading="loading"/>
                                </col-12>
                            </row>
                        </b-form>
                    </edit-modal>
                    <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
                        <p>You are about to delete
                            <b v-if="deletingItem"> {{ deletingItem.company }}</b>
                            Are you sure about it ?</p>
                        <template #loading>
                            <loading-animation/>
                            Please wait while we delete <b v-html="deletingItem && deletingItem.company"></b>.
                        </template>
                    </delete-modal>
                </template>
            </vue-table-card>
        </inner-page-card>
    </container>
</template>

<script>
import tableFields from './prioratizing-table-fields';
import urls from '../../data/urls';

export default {
    name: 'PrioratizingPage',
    data () {
        return {
            fields: tableFields,
            listUrl: urls.drugs.listing.list,
            addUrl: urls.drugs.listing.addEdit,
            listVueSelect: urls.drugs.listing.listVueSelect,
            deleteUrl: urls.drugs.listing.delete,
            deletingItem: {
                name: ''
            },
            editingItem: null,
            rules: {
                company: {
                    required: true
                },
                order: {
                    required: true
                }
            },
            data: [
                {
                    heading: 'Common names in Parasitology',
                    description: 'Sreerag, Gopu, Sanoop, Sarath TP'
                }
            ]
        };
    },
    methods: {
        formSuccess (action) {
            const refs = this.$refs;
            this.$refs.addModal.close();
            this.$refs.EditModal.close();
            if (action === 'A') {
                this.$notify('Prioratizing Added Successfully', 'Success', {
                    type: 'success'
                });
            } else {
                this.$notify('Prioratizing Updated Successfully', 'Success', {
                    type: 'success'
                });
            }
            refs.table.refreshTable();

            this.editingItem = null;
        },
        addModal (item) {
            this.editingItem = { ...item };
            this.$refs.addModal.show();
        },
        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.EditModal.show();
        },
        setDeleteUser (item) {
            console.log(item);
            this.deletingItem = { ...item };
            this.$refs.deleteModal.show();
        },
        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.deleteModal.close();
            this.$notify('Prioratizing Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        EditFailure (response) {
            var errorMessage = '';
            const errors = response.data.errors;
            for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                    if (key === '__all__') {
                        errorMessage = `${errors[key]}`;
                    } else {
                        errorMessage = `${key}:  ${errors[key]}`;
                    }
                    this.$notify(errorMessage, 'Warning', { type: 'warning' });
                }
            }
        }
    }
};
</script>

<style scoped>

</style>

const tableFields = [
    {
        name: 'brand_name',
        title: 'Brand Name',
        titleClass: '',
        dataClass: '',
        sortField: 'brand_name'

    },
    {
        name: 'drug_name',
        title: 'Drug Name',
        titleClass: '',
        dataClass: '',
        sortField: 'drug_name'

    },
    {
        name: 'categories',
        title: 'Category',
        titleClass: '',
        dataClass: '',
        sortField: 'categories'

    },
    {
        name: 'added_by',
        title: 'Added By',
        titleClass: '',
        dataClass: '',
        sortField: 'added_by'

    },
    {
        name: 'recommend_count',
        title: 'Recommend Count',
        titleClass: '',
        dataClass: '',
        sortField: 'recommend_count'

    },
    {
        name: '__slot:actions',
        title: 'Actions',
        titleClass: 'text-right',
        dataClass: 'text-right'
    }
];

const sampleData = [
    {
        id: '23',
        name: 'nj EUTHASOL®',
        drug_name: '',
        category: 'Euthanesia',
        added_by: 'Ghanasyam Joshi',
        recommend_count: '176'
    }
];

export default { tableFields, sampleData };

<template>
    <div>
        <container>
            <inner-page-card heading="BATCH">
                <template #buttons>
                    <add-btn @click="$refs.addBatchModal.show()"/>
                </template>
                <vue-table-card :fields="fields" :url="listUrl" ref="table">
                    <template #actions="{rowData}">
                        <btn-group>
                            <edit-btn @click="setEdit(rowData)"/>
                            <delete-btn @click="setDeleteBatch(rowData)"/>
                        </btn-group>
                    </template>
                    <template #footer>
                        <modal ref="addBatchModal" title="Add Batch" width="55r">
                            <b-form :save-url="addUrl" @failure="batchAddFailure" @success="formSuccess('A')" v-slot="{model, loading}">
                                <row>
                                    <col-12 lg="6">
                                        <validated-input label="Name :" v-model="model.name" :rules="rules.name"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input label="Category :" v-model="model.category" :rules="rules.category"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input label="Designation :" v-model="model.designation" :rules="rules.designation"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input label="College :" v-model="model.college" :rules="rules.college"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input label="Department :" v-model="model.department" :rules="rules.department"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input label="Phone :" v-model="model.phone" :rules="rules.phone"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12>
                                        <submit-btn loading-text="Submitting..." size="sm" :disabled="loading"
                                                    :loading="loading"/>
                                    </col-12>
                                </row>
                            </b-form>
                        </modal>
                        <edit-modal ref="batchEditModal" width="55r">
                            <b-form :save-url="addUrl" @failure="batchEditFailure" @success="formSuccess('M')" v-slot="{model, loading}"
                                    :initial-data="editingItem">
                                <row>
                                    <col-12 lg="6">
                                        <validated-input label="Name :" v-model="model.name" :rules="rules.name"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input label="Category :" v-model="model.category" :rules="rules.category"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input label="Designation :" v-model="model.designation" :rules="rules.designation"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input label="College :" v-model="model.college" :rules="rules.college"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input label="Department :" v-model="model.department" :rules="rules.department"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12 lg="6">
                                        <validated-input label="Phone :" v-model="model.phone" :rules="rules.phone"
                                                         :disabled="loading"></validated-input>
                                    </col-12>
                                    <col-12>
                                        <submit-btn loading-text="Submitting..." size="sm" :disabled="loading"
                                                    :loading="loading"/>
                                    </col-12>
                                </row>
                            </b-form>
                        </edit-modal>
                        <delete-modal ref="batchDeleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
                            <p>You are about to delete the batch.
                                <b v-if="deletingItem"> {{ deletingItem.name }}</b> Are you sure ?
                            </p>
                            <template #loading>
                                <loading-animation/>
                                Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
                            </template>
                        </delete-modal>
                        <modal title="User Info" ref="userViewModal">
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab ad adipisci alias aliquid architecto aut blanditiis corporis cum, dolorum earum
                                eligendi
                                excepturi ipsum maiores nam nobis numquam odio officia, omnis pariatur provident quaerat quibusdam quos sed similique sunt veritatis voluptatibus.
                                Harum
                                itaque minima nesciunt quae recusandae sequi ut? Est, sunt.
                            </p>
                        </modal>
                    </template>
                </vue-table-card>
            </inner-page-card>
        </container>
    </div>
</template>

<script>
import usersTableFields from './batch-table-fields';
import urls from '../../../data/urls';

export default {
    name: 'AddBatchPage',
    data () {
        return {
            fields: usersTableFields,
            listUrl: urls.directory.batch.list,
            addUrl: urls.directory.batch.addEdit,
            deleteUrl: urls.directory.batch.delete,
            editingItem: null,
            deletingItem: {
                name: ''
            },
            data: [
                {
                    id: '23',
                    name: 'Veterinary College Mannuthy',
                    category: 'UG Student',
                    department: 'Swathi Sivanand (GRP 2018)',
                    college: '2018 Batch',
                    designation: 'Department',
                    phone: '7902900460'
                }
            ],
            rules: {
                name: {
                    required: false
                },
                designation: {
                    required: false
                },
                category: {
                    required: false
                },
                college: {
                    required: false
                },
                department: {
                    required: false
                },
                phone: {
                    required: false
                }
            }
        };
    },
    methods: {
        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.batchEditModal.show();
        },
        setDeleteBatch (item) {
            this.deletingItem = { ...item };
            this.$refs.batchDeleteModal.show();
        },
        formSuccess (action) {
            const refs = this.$refs;
            this.$refs.addBatchModal.close();
            this.$refs.batchEditModal.close();
            if (action === 'A') {
                this.$notify('Batch Added Successfully', 'Success', {
                    type: 'success'
                });
            } else {
                this.$notify('Batch Updated Successfully', 'Success', {
                    type: 'success'
                });
            }
            refs.table.refreshTable();

            this.editingItem = null;
        },
        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.batchDeleteModal.close();
            this.$notify('Batch Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        batchEditFailure (response) {
            const errors = response.data.errors;
            for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                    const errorMessage = `${key}:  ${errors[key]}`;
                    this.$notify(errorMessage, 'Warning', { type: 'warning' });
                }
            }
        },
        batchAddFailure (response) {
            const errors = response.data.errors;
            for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                    const errorMessage = `${key}:  ${errors[key]}`;
                    this.$notify(errorMessage, 'Warning', { type: 'warning' });
                }
            }
        }
    }
};
</script>

<style scoped>

</style>

<template>
    <div>
        <container>
            <inner-page-card heading="DIRECTORY CATEGORY">
                <template #buttons>
                    <add-btn @click="$refs.addModal.show()"/>
                </template>
                <vue-table-card ref="table" :url="listUrl" :fields="fields" :table-data="data">
                    <template #actions="{rowData}">
                        <btn-group>
                            <add-btn color="primary" @click="redirectDirectoryListingPage(rowData)"/>
                            <edit-btn @click="setEdit(rowData)"/>
                            <delete-btn @click="setDelete(rowData)"/>
                        </btn-group>
                    </template>
                </vue-table-card>
                <modal ref="addModal" title="Add Category" width="35r">
                    <b-form :save-url="addUrl" @failure="categoryAddEditFailure" @success="formSuccess('A')" v-slot="{model, loading}">
                        <row>
                            <col-12>
                                <validated-input label="Name*" name="Name"
                                                 v-model="model.name" :rules="{required : true}"
                                                 :disabled="loading"/>
                            </col-12>
                            <col-12 lg="6">
                                <validated-select :options="listBySelectOptions" label="Filter by" name="listBy"
                                                  v-model="model.filter_by" :rules="rules.filter_by"
                                                  :disabled="loading"/>
                            </col-12>
                            <col-12 lg="6">
                                <validated-select :options="listBySelectOptions" label="List by" name="listBy"
                                                  v-model="model.list_by" :rules="rules.list_by"
                                                  :disabled="loading"/>
                            </col-12>
                            <col-12 lg="6">
                                <validated-select :options="listBySelectOptions" label="Unique Field" name="uniqueField"
                                                  v-model="model.unique_field" :rules="rules.unique_field"
                                                  :disabled="loading"/>
                            </col-12>
                            <col-12 mt="6">
                                <btn text="Save" loading-text="Saving..." size="sm" :disabled="loading"
                                     :loading="loading"/>
                            </col-12>
                        </row>
                    </b-form>
                </modal>
                <modal ref="editModal" title="Edit Category" width="35r">
                    <b-form :save-url="addUrl" @success="formSuccess('M')" @failure="categoryAddEditFailure" v-slot="{model, loading}"
                            :initial-data="editingItem">
                        <row>
                            <col-12>
                                <validated-input label="Name*" name="Name"
                                                 v-model="model.name" :rules="rules.name"
                                                 :disabled="loading"/>
                            </col-12>
                            <col-12 lg="6">
                                <validated-select :options="listBySelectOptions" label="Filter by" name="Filter by"
                                              v-model="model.filter_by" :rules="rules.filter_by"
                                              :disabled="loading"/>
                            </col-12>
                            <col-12 lg="6">
                                <validated-select :options="listBySelectOptions" label="List by" name="List by"
                                              v-model="model.list_by" :rules="rules.list_by"
                                              :disabled="loading"/>
                            </col-12>
                            <col-12 lg="6">
                                <validated-select :options="listBySelectOptions" label="Unique Field" name="uniqueField"
                                                  v-model="model.unique_field" :rules="rules.unique_field"
                                                  :disabled="loading"/>
                            </col-12>
                            <col-12 mt="6">
                                <btn text="Update" loading-text="Updating..." size="sm" :disabled="loading"
                                     :loading="loading"/>
                            </col-12>
                        </row>
                    </b-form>
                </modal>
                <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
                    <p>You are about to delete the category
                        <b v-if="deletingItem"> {{ deletingItem.name }}</b>.Are you sure?</p>
                    <template #loading>
                        <loading-animation/>
                        Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
                    </template>
                </delete-modal>
            </inner-page-card>
        </container>
    </div>
</template>

<script>
import urls from '../../../data/urls';

export default {
    name: 'DirectoryCategoryPage',
    data () {
        return {
            listUrl: urls.directory.category.list,
            addUrl: urls.directory.category.addEdit,
            deleteUrl: urls.directory.category.delete,
            editingItem: null,
            deletingItem: {
                name: ''
            },
            fields: [
                {
                    name: 'id',
                    sortField: 'id',
                    title: 'ID'
                },
                {
                    name: 'name',
                    sortField: 'name',
                    title: 'Name'
                },
                {
                    name: '__slot:actions',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right'
                }
            ],
            rules: {
                name: {
                    required: true
                },
                list_by: {
                    required: false
                },
                filter_by: {
                    required: true
                },
                unique_field: {
                    required: false
                }
            },
            listBySelectOptions: [
                {
                    label: 'Name',
                    value: 'name'
                },
                {
                    label: 'Category',
                    value: 'category'
                },
                {
                    label: 'College',
                    value: 'college'
                },
                {
                    label: 'Department',
                    value: 'department'
                }
            ]
        };
    },
    methods: {
        formSuccess (action) {
            const refs = this.$refs;
            this.$refs.addModal.close();
            this.$refs.editModal.close();
            if (action === 'A') {
                this.$notify('Category Added Successfully', 'Success', {
                    type: 'success'
                });
            } else {
                this.$notify('Category Updated Successfully', 'Success', {
                    type: 'success'
                });
            }
            refs.table.refreshTable();

            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.editModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.deleteModal.show();
        },

        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.deleteModal.close();
            this.$notify('Category Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        categoryAddEditFailure (response) {
            let errorMessage = '';
            const errors = response.data.errors;
            for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                    // const errorMessage = `${key}:  ${errors[key]}`;
                    if (key === '__all__') {
                        errorMessage = `${errors[key]}`;
                    } else {
                        errorMessage = `${key}:  ${errors[key]}`;
                    }
                    this.$notify(errorMessage, 'Warning', { type: 'warning' });
                }
            }
        },
        redirectDirectoryListingPage (item) {
            this.$router.push('/directory/' + item.id + '/' + item.name);
        }
    }
};
</script>

<style scoped>

</style>

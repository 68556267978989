<template>
    <table-page heading="Refund">

        <vue-table-card :url="refundList" :fields="fields" :table-data="data" ref="table">
            <template #actions="{rowData}">
                <btn-group>
                    <btn-group>
                        <view-btn text="View" @click="viewInfo(rowData)"/>
                    </btn-group>
                </btn-group>
            </template>
            <template #footer>
                <modal width="35r" title="Refund Info" ref="RefundViewModal">
                    <p class="pl-2" v-if="currentRefundItem"><span class="font-proxima-bold text-left">User </span>: {{currentRefundItem.name}}</p>
                    <details-card :heading="'Plan Name : ' + currentRefundItem.plan.name" v-if="currentRefundItem">
                        <details-list>
                            <details-list-item label="Transaction ID" :value="currentRefundItem.transaction_id"/>
                            <details-list-item label="Refund ID" :value="currentRefundItem.refund_id"/>
                            <details-list-item label="Status" :value="currentRefundItem.status"/>
                            <details-list-item label="Validity" :value="currentRefundItem.expiry_months"/>
                            <details-list-item label="Initiated Date" :value="currentRefundItem.initiated_date"/>
                            <details-list-item label="Amount" :value="currentRefundItem.refund_amount"/>
                            <details-list-item label="Refund Completed Date" :value="currentRefundItem.transaction_date"/>

                        </details-list>
                    </details-card>
                </modal>

            </template>
        </vue-table-card>

    </table-page>
</template>

<script>
import InnerPageCard from '@components/InnerPageCard';
import RefundPage from '../../../views/transactions/refund/refund-fields';
import urls from '../../../data/urls';
import axios from 'secure-axios';

export default {
    name: 'RefundPage',
    components: InnerPageCard,
    data () {
        return {
            fields: RefundPage,
            refundList: urls.transactions.refundList,
            refundDetail: urls.transactions.refundDetail,
            currentRefundItem: null,
            data: [
                {
                    name: 'alan',
                    phone_number: '811187885',
                    transaction_id: '811187885',
                    status: '811187885',
                    amount: '811187885',
                    expiry_month: '811187885',
                    initiated_date: '811187885'

                }
            ]
        };
    },
    methods: {
        async viewInfo (item) {
            console.log(item);
            // const that = this;
            const response = await axios.form(
                this.refundDetail, { id: item.id }
            ).catch(function (exception) {
                // that.serverError();
                console.log('server error');
            });
            console.log('response', response);
            const json = await response.data;
            const dataItem = json.data;
            console.log('json', json.data);
            this.currentRefundItem = dataItem;
            this.$refs.RefundViewModal.show();
        }
        // viewUser (item) {
        //     console.log(item);
        //     this.$refs.RefundViewModal.show();
        // }
    }

};
</script>

<style scoped>

</style>

<template>
    <div>
        <container>
            <inner-page-card heading="Add Preparation">
                <b-form :save-url="addEditUrl" @failure="userEditFailure" @success="formSuccess('b')" v-slot="{model, loading}">
                    <row>
                        <col-12>
                            <add-btn color="secondary" size="sm" text="Add Indication" @click="$refs.indicationAddModal.show()" type="button"/>
                        </col-12>
                        <col-12 lg="12" class="mt-3">
                            <validated-input class="mt-n2" v-model="model.preperation_name"
                                             :rules="rules.preperation_name"
                                             :disabled="loading"
                                             label="Preperation <span class='text-danger'>*</span> :"></validated-input>
                        </col-12>

                        <col-12 lg="12">
                            <form-group
                                :disabled="loading" label="Content <span class='text-danger'>*</span>:">
                                <ckeditor class="w-100p" :editor="editor" v-model="model.content"
                                          :rules="rules.content"
                                          :config="editorConfig"></ckeditor>
                            </form-group>

                        </col-12>
                        <col-12 lg="12" class="mb-6">
                            <!--                                    <validated-ajax-select class="mt-n2" v-model="model.indications" multiple
                                                                                 :disabled="loading" :url="indiactionUrl" ref="indicationVueSect"
                                                                                 label="Indication  :"></validated-ajax-select>-->
                            <validated-vue-select class="mt-n2 font-weight-700 text-2 v-select-dropdown-max-h-10r"
                                                  v-model="model.indications" multiple
                                                  :disabled="loading" :options="indicationOptions" ref="indicationVueSect"
                                                  label="Indication  :"></validated-vue-select>
                        </col-12>
                        <col-12>
                            <submit-btn loading-text="Saving..." size="sm" :disabled="loading"
                                        :loading="loading"></submit-btn>
                        </col-12>
                    </row>
                </b-form>
                <modal width="55r" title="Add" ref="indicationAddModal">
                    <b-form :save-url="IndiactionaddEditUrl" @failure="userEditFailure" @success="formSuccess('A')"
                            v-slot="{model, loading}">
                        <row>
                            <col-12 lg="6">
                                <validated-input class="mt-n2" v-model="model.indication_name"
                                                 :rules="rules.indication_name"
                                                 :disabled="loading"
                                                 label="Indication <span class='text-danger'>*</span> :"></validated-input>
                            </col-12>

                            <col-12 lg="6">
                                <form-group
                                    :disabled="loading" label="Content <span class='text-danger'>*</span>:">
                                    <ckeditor class="w-100p" :editor="editor" v-model="model.content"
                                              :rules="rules.content"
                                              :config="editorConfig"></ckeditor>
                                </form-group>

                            </col-12>
                            <col-12>
                                <submit-btn loading-text="Saving..." size="sm" :disabled="loading"
                                            :loading="loading"></submit-btn>
                            </col-12>
                        </row>
                    </b-form>
                </modal>

            </inner-page-card>
        </container>
    </div>
</template>

<script>

import ckeditorSettings from '../../../../components/ckeditor-settings';
import urls from '@/data/urls';
import axios from 'secure-axios';

export default {
    name: 'add-preparation',
    data () {
        return {
            addEditUrl: urls.pharmacy.preparation.addEdit,
            editorConfig: ckeditorSettings.editorConfig,
            editor: ckeditorSettings.ClassicEditor,
            indiactionUrl: urls.pharmacy.preparation.indiacationVueSelect,
            IndiactionaddEditUrl: urls.pharmacy.indication.addEdit,
            indicationOptions: [],
            model: {
                preperation_name: '',
                content: ''
            },
            rules: {
                preperation_name: {
                    required: true
                },
                content: {
                    required: true
                }
            }
        };
    },
    mounted () {
        this.fetchIndication();
    },
    methods: {
        userEditFailure (response) {
            const errors = response.data.errors;
            for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                    const errorMessage = `${key}:  ${errors[key]}`;
                    this.$notify(errorMessage, 'Warning', { type: 'warning' });
                }
            }
        },
        async fetchIndication () {
            try {
                const response = await axios.get(this.indiactionUrl);
                const result = response.data;
                this.indicationOptions = result.data;
                console.log('detailItem', this.indicationOptions);
                if (result.success === true) {
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
            }
        },
        formSuccess (action) {
            if (action === 'A') {
                this.$refs.indicationAddModal.close();
                this.fetchIndication();
            } else {
                this.$router.push('/preperations/');
            }
            this.$notify(' Added Successfully', 'Success', { type: 'success' });
        }
    }
};
</script>

<style scoped>

</style>

<template>
    <div class="about">
        <container>
            about
        </container>
    </div>
</template>
<script>

export default {
    name: 'About',
    components: {}
};
</script>

<style scoped>

</style>

const tableFields = [
    // {
    //     name: 'title',
    //     title: 'Title',
    //     titleClass: '',
    //     dataClass: 'w-20r'
    // },
    // {
    //     name: 'description',
    //     title: 'Description',
    //     titleClass: '',
    //     dataClass: 'w-20r'
    // },
    {
        name: 'file',
        title: 'Images',
        titleClass: '',
        dataClass: 'w-20r'
    },
    {
        name: 'order',
        title: 'Order',
        titleClass: '',
        dataClass: 'w-20r'
    },
    {
        name: '__slot:actions',
        title: 'Actions',
        titleClass: '',
        dataClass: ''
    }
];

export default tableFields;

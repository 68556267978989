const tableFields = [
    {
        name: 'name',
        title: 'Name',
        titleClass: '',
        dataClass: '',
        sortField: 'name'

    },
    {
        name: 'description',
        title: 'Description',
        titleClass: '',
        dataClass: '',
        sortField: 'description'

    },
    {
        name: 'value',
        title: 'Value',
        titleClass: '',
        dataClass: '',
        sortField: 'value'

    },
    {
        name: 'expiry_months',
        title: 'Expiry months',
        titleClass: '',
        dataClass: '',
        sortField: 'expiry_months'
    },
    {
        name: 'active',
        title: 'Status',
        titleClass: '',
        dataClass: '',
        sortField: 'active'
    },
    {
        name: '__slot:plan_type',
        title: 'Plan',
        titleClass: '',
        dataClass: 'w-15r',
        sortField: 'plan_type'
    },
    {
        name: '__slot:actions',
        title: 'Actions',
        titleClass: '',
        dataClass: ''
    }
];

export default tableFields;

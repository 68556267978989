<template>
      <container>
        <table-page class="" heading="Treatment Suggestions Approved">
            <vue-table-card :fields="fields" :url="listUrl"
            :per-page="10" ref="table" search-placeholder="Search">
                <template #actions="{rowData}" >
                    <btn-group>
                        <edit-btn @click="EditUser(rowData)"/>
                        <delete-btn @click="setDeleteUser(rowData.id)"/>
                    </btn-group>
                </template>
                <template #footer>
                    <edit-modal ref="treatmentsuggestionsapprovedEditModal">
                        <s-form @submit="addItem">
                            <row>
                                 <div class="col-lg-6">
                                <validated-select :options="sectionOptions" label="Section:" v-model="editingItem.section"></validated-select>
                            </div>
                            <div class="col-lg-6">
                                <validated-input label="Treatment content <span class='text-danger'>*</span>:" v-model="editingItem.treatment_content"></validated-input>
                            </div>
                              <div class="col-lg-12" v-if="editingItem.section==='disease'">
                                <validated-ajax-select class="mt-2 font-weight-700 text-2 v-select-dropdown-max-h-10r" :url="diseaseVueselect" label="Disease:" v-model="editingItem.disease"></validated-ajax-select>
                            </div>
                                <div class="col-lg-12" v-if="editingItem.section==='indication'">
                                <validated-ajax-select :url="indicationVueselect" label="Indication:" v-model="editingItem.indication"></validated-ajax-select>
                            </div>
                                <div class="col-lg-12" v-if="editingItem.section==='preperation'">
                                <validated-ajax-select :url="preparationVueselect" label="Preparation:" v-model="editingItem.preperations"></validated-ajax-select>
                            </div>
<!--                            <div class="col-lg-12">
                                <validated-ajax-select :url="suggestVueselect" label="Suggested by:" v-model="editingItem.suggested_by"></validated-ajax-select>
                            </div>-->
                            <div class="col-lg-12">
                                <btn text="Submit" :loading="loading" loading-text="Saving..."></btn>
                            </div>
                            </row>
                        </s-form>
                    </edit-modal>
                    <delete-modal ref="treatmentsuggestionsapprovedDeleteModal" :url="deleteUrl" :params="{id : deleteItem}" @response="deleteSuccess">
                        <p>You are about to delete.<br>
                            Are you sure about it ?
                        </p>
                    </delete-modal>
                </template>
            </vue-table-card>

        </table-page>
    </container>
</template>

<script>
import tableFields from './treatments-suggestions-approved-table-fields';
import urls from '@/data/urls';
import axios from 'secure-axios';
export default {
    name: 'TreatmentsSuggestionsApprovedPage',
    data () {
        return {
            response: '',
            loading: false,
            listUrl: urls.disease.treamentSuggestionApproved.list,
            deleteUrl: urls.disease.treamentSuggestionApproved.delete,
            addEditUrl: urls.disease.treamentSuggestionApproved.addEdit,
            suggestVueselect: urls.disease.treatmentsAddSuggestionList.sugestedVueSelect,
            diseaseVueselect: urls.disease.disease.vueSelect,
            indicationVueselect: urls.disease.suggestion.indications,
            preparationVueselect: urls.disease.suggestion.preparation,
            sectionOptions: [
                { label: '--------', value: '' },
                { label: 'Disease', value: 'disease' },
                /* { label: 'Indication', value: 'indication' }, */
                { label: 'Preperation', value: 'preperation' }
            ],
            deleteItem: '',
            editingItem: [],
            fields: tableFields,
            data: [
                {
                    disease: 'BOVINE CUTANEOUS PAPILLOMATOSIS',
                    treatment: 'tyfgjhk',
                    suggested_person: 'rtgh'

                }
            ]
        };
    },
    methods: {
        deleteSuccess (item) {
            console.log('delete state', item);
            this.$refs.treatmentsuggestionsapprovedDeleteModal.close();
            if (item.success) {
                this.$notify('Deleted Successfully', 'Success', {
                    type: 'success'
                });
                this.$refs.table.refreshTable();
            } else {
                console.log('item error', item.errors.Id);
                this.$notify(item.errors.Id, 'warning', {
                    type: 'warning'
                });
            }
        },
        async addItem () {
            try {
                this.loading = true;
                this.response = await axios.form(this.addEditUrl, this.editingItem);
                const result = this.response.data;
                console.log('result', result);
                if (result.success === true) {
                    this.$notify('Updated Successfully', 'Success', { type: 'success' });
                    this.loading = false;
                    this.editingItem = '';
                    this.$refs.treatmentsuggestionsapprovedEditModal.close();
                    this.$refs.table.refreshTable();
                } else {
                    const errors = this.response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.this.response.status + ' ' + error.this.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        },
        EditUser (item) {
            console.log(item);
            this.editingItem = '';
            this.editingItem = item;
            this.$refs.treatmentsuggestionsapprovedEditModal.show();
        },
        setDeleteUser (item) {
            console.log(item);
            this.deleteItem = item;
            this.$refs.treatmentsuggestionsapprovedDeleteModal.show();
        }
    }
};
</script>

<style scoped>

</style>

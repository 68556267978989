const tableFields = [
    {
        name: 'username',
        title: 'Username',
        titleClass: '',
        dataClass: 'w-10r',
        sortField: 'username'
    },
    {
        name: 'first_name',
        title: 'First Name',
        titleClass: '',
        dataClass: 'w-5r',
        sortField: 'first_name'
    },
    {
        name: 'last_name',
        title: 'Last Name',
        titleClass: '',
        dataClass: 'w-5r',
        sortField: 'last_name'

    },
    {
        name: 'college',
        title: 'College',
        titleClass: '',
        dataClass: 'w-5r',
        sortField: 'college'

    },
    {
        name: 'designation',
        title: 'Designation',
        titleClass: '',
        dataClass: 'w-5r',
        sortField: 'designation'

    },
    {
        name: 'email',
        title: 'Email',
        titleClass: '',
        dataClass: 'w-5r',
        sortField: 'email'

    },
    {
        name: 'phone_number',
        title: 'Phone Number',
        titleClass: '',
        dataClass: 'w-5r',
        sortField: 'phone_number'

    },
    {
        name: 'reg_no',
        title: 'Registration No',
        titleClass: '',
        dataClass: '',
        sortField: 'registration_no'

    },
    {
        name: 'country',
        title: 'Country',
        titleClass: '',
        dataClass: '',
        sortField: 'country'

    },
    {
        name: 'place',
        title: 'Place',
        titleClass: 'w-5r',
        dataClass: 'w-5r',
        sortField: 'place'

    },
    {
        name: '__slot:actions',
        title: 'Actions',
        titleClass: '',
        dataClass: ''
    }
];

export default tableFields;

<template>
    <table-page heading="Articles">
        <vue-table-card :url="listUrl" :fields="fields" :table-data="data" ref="table">
            <template #actions="{rowData}">
                <btn-group>
                    <view-btn @click="viewDetails(rowData)"/>
                    <edit-btn @click="setEdit (rowData)"/>
                    <delete-btn @click="setDeleteUser(rowData)"/>
                </btn-group>
            </template>
            <template #footer>
                <edit-modal ref="EditModal">
                    <b-form :save-url="addUrl" @failure="userEditFailure" @success="formSuccess('M')" v-slot="{model, loading}"
                            :initial-data="editingItem">
                        <row>
                            <col-12>
                                <validated-input label="Title :" v-model="model.title" :rules="rules.title"
                                         :disabled="loading"/>
                            </col-12>
                            <col-12 class="fl-x">
                                <validated-input label="Author <span class='text-danger'>*</span>:" class="col-6 pl-0" v-model="model.author" :rules="rules.author"
                                         :disabled="loading"/>
                                <validated-input label="Batch/Designation <span class='text-danger'>*</span>:" class="col-6 pr-0" v-model="model.batch" :rules="rules.batch"
                                         :disabled="loading"/>
                        </col-12>
                             <col-12>
                                 <form-group
                                        label="Content :">
                                        <ckeditor class="w-100p" :editor="editor" v-model="model.content"
                                                  :rules="rules.content"
                                                  :config="editorConfig" :disabled="loading"></ckeditor>
                                    </form-group>
<!--                                <validated-input label="Content :" v-model="model.content" :rules="rules.content"-->
<!--                                         :disabled="loading"/>-->
                            </col-12>
                        <col-12>
                            <save-btn loading-text="Saving..." size="sm" :disabled="loading"
                             :loading="loading"/>
                        </col-12>
                        </row>
                    </b-form>
                </edit-modal>
                <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
                    <p>You are about to delete
                        <b v-if="deletingItem"> {{ deletingItem.title }}</b>
                        Are you sure about it ?</p>
                      <template #loading>
                        <loading-animation/>
                        Please wait while we delete <b v-html="deletingItem && deletingItem.title"></b>.
                    </template>
                </delete-modal>
            </template>
        </vue-table-card>
    </table-page>
</template>

<script>
import tableFields from './articles-old-list-table-fields';
import urls from '../../../data/urls';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    name: 'ArticleOldListPage',
    data () {
        return {
            fields: tableFields,
            listUrl: urls.article.oldArticle.list,
            addUrl: urls.article.oldArticle.addEdit,
            deleteUrl: urls.article.oldArticle.delete,
            deletingItem: {
                name: ''
            },
            editingItem: null,
            rules: {
                title: {
                    required: false
                },
                author: {
                    required: true
                },
                batch: {
                    required: true
                },
                content: {
                    required: false
                }
            },
            editor: ClassicEditor,
            editorConfig: {},
            data: [
                {
                    title: 'Common names in Parasitology ( Trematode, Cestode, Nematode, Flies, Mites, Bugs, Louse, Ticks)',
                    author: 'Sreerag, Gopu, Sanoop, Sarath TP'
                }
            ]
        };
    },
    methods: {
        formSuccess (action) {
            const refs = this.$refs;
            // this.$refs.addModal.close();
            this.$refs.EditModal.close();
            if (action === 'A') {
                this.$notify('Article Added Successfully', 'Success', {
                    type: 'success'
                });
            } else {
                this.$notify('Article Updated Successfully', 'Success', {
                    type: 'success'
                });
            }
            refs.table.refreshTable();

            this.editingItem = null;
        },
        viewDetails (item) {
            console.log(item);
            this.$router.push('/articles-oldlist-detailspage/' + item.id);
        },
        //
        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.EditModal.show();
        },
        setDeleteUser (item) {
            console.log(item);
            this.deletingItem = { ...item };
            this.$refs.deleteModal.show();
        },
        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.deleteModal.close();
            this.$notify('Article Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        userEditFailure (response) {
            const errors = response.data.errors;
            for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                    const errorMessage = `${key}:  ${errors[key]}`;
                    this.$notify(errorMessage, 'Warning', { type: 'warning' });
                }
            }
        }
    }
};
</script>

<style scoped>

</style>

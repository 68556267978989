<template>
    <container>
        <table-page heading="Advertisement"></table-page>
            <vue-table-card :url="listUrl" :fields="fields" :table-data="data" ref="table">
                <template #actions="{rowData}">
                    <btn-group>
                        <edit-btn @click="setEdit(rowData)"/>
                        <delete-btn @click="setDeleteUser(rowData)"/>
                    </btn-group>
                </template>
                <template #footer>
                    <edit-modal ref="EditModal">
                         <b-form :save-url="addEditUrl" ref="form" @failure="FormFailure" @success="formSuccess('A')" v-slot="{model, loading}" :initial-data="editingItem">
                    <row>
                        <col-12>
                            <validated-input label="Title
                            <span class='text-danger'>*</span>:" v-model="model.title" :rules="rules.title"
                                             :disabled="loading"/>
                        </col-12>
                        <col-12>
                            <validated-file-input label="Image <span class='text-danger'>*</span>:" border-radius="0" class="font-weight-700 text-2" v-model="model.image"
                                                  :disabled="loading"></validated-file-input>
                        </col-12>
                        <col-12 class="fl-x">
                            <h6 class="text-2 font-weight-700 pt-3">Url :</h6>
                            <validated-input class="pl-2" v-model="model.url" :rules="rules.url"
                                             :disabled="loading"/>
                        </col-12>
                        <col-12>
                            <validated-select label="Position <span class='text-danger'>*</span>:" :options="AdvertisementOptions" v-model="model.position" :rules="rules.position"
                                              :disabled="loading"/>
                        </col-12>
                        <col-12>
                            <validated-input label="Position Number :" v-model="model.position_number" :rules="rules.position_number"
                                             :disabled="loading"/>
                        </col-12>
                        <col-12>
                            <save-btn loading-text="Saving..." size="sm" :disabled="loading"
                                      :loading="loading"/>
                        </col-12>
                    </row>
                </b-form>
                    </edit-modal>
                    <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
                        <p>You are about to delete
                            <b v-if="deletingItem"> {{ deletingItem.title }}</b>. Are you sure about it ?</p>
                         <template #loading>
                        <loading-animation/>
                        Please wait while we delete <b v-html="deletingItem && deletingItem.title"></b>.
                    </template>
                    </delete-modal>
                </template>
            </vue-table-card>
    </container>
</template>

<script>
import tableFields from './advertisements-list-table-fields';
import urls from '../../../data/urls';
export default {
    name: 'AdvertisementListPage',
    data () {
        return {
            fields: tableFields,
            listUrl: urls.advertisement.list,
            addEditUrl: urls.advertisement.addEdit,
            deleteUrl: urls.advertisement.delete,
            deletingItem: {
                name: ''
            },
            editingItem: null,
            rules: {
                title: {
                    required: true
                },
                image: {
                    required: true
                },
                url: {
                    required: false
                },
                position: {
                    required: true
                },
                position_number: {
                    required: false
                }
            },
            AdvertisementOptions: [
                {
                    label: 'Top',
                    value: 'top'
                }, {
                    label: 'Bottom',
                    value: 'bottom'
                }
            ],
            data: [
                {
                    title: 'drug updation',
                    image: '/media/automatic__updationcorrected_1hsAn7d.png',
                    url: '',
                    position: 'bottom',
                    position_number: '4'
                }
            ]
        };
    },
    methods: {
        formSuccess (action) {
            const refs = this.$refs;
            // this.$refs.addModal.close();
            this.$refs.EditModal.close();
            if (action === 'A') {
                this.$notify('Advertisement Updated Successfully', 'Success', {
                    type: 'success'
                });
            }
            refs.table.refreshTable();

            this.editingItem = null;
        },
        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.EditModal.show();
        },
        setDeleteUser (item) {
            console.log(item);
            this.deletingItem = { ...item };
            this.$refs.deleteModal.show();
        },
        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.deleteModal.close();
            this.$notify('Advertisement Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        userEditFailure (response) {
            const errors = response.data.errors;
            for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                    const errorMessage = `${key}:  ${errors[key]}`;
                    this.$notify(errorMessage, 'Warning', { type: 'warning' });
                }
            }
        },
        FormFailure (response) {
            const errors = response.data.errors;
            console.log('errors', errors);
            for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                    const errorMessage = `${key}:  ${errors[key]}`;
                    this.$notify(errorMessage, 'Warning', { type: 'warning' });
                }
            }
        }
    }

};
</script>

<style scoped>

</style>

<template>
    <container>
        <inner-page-card class="" :scrollbar="true" heading="SUGGESTIONS">
            <template #buttons>
                <slot name="buttons">
                    <btn icon="fa fa-plus" color="success" @click="itemAdd" border-radius="0"></btn>

                </slot>
            </template>
            <modal width="50r" ref="addsuggestionsAddModal">
                <s-form @submit="addItem">
                    <div class="row">
                        <div class="col-lg-6">
                            <validated-vue-select :options="sectionOptions" label="Section:" v-model="model.section"></validated-vue-select>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Treatment content <span class='text-danger'>*</span>:" :rules="{required:true}"
                                             v-model="model.treatment_content"></validated-input>
                        </div>

                        <div class="col-lg-12">
                            <validated-ajax-select v-if="model.section==='disease'" :url="diseaseVueselect" label="Disease:" v-model="model.disease"></validated-ajax-select>

<!--                            <validated-vue-select v-if="model.section==='indication'" :options="indiactionOption" label="Indication:"
                                                  v-model="model.indication"></validated-vue-select>
&lt;!&ndash;                                -->

                            <validated-vue-select v-if="model.section==='preperation'" :options="preperationOption" label="Preparation:"
                                                  v-model="model.preperations"></validated-vue-select>
                        </div>

                        <div class="col-lg-12 mb-6">
                            <validated-ajax-select class="mt-2 font-weight-700 text-2 v-select-dropdown-max-h-10r" :url="suggestVueselect" label="Suggested by:"
                                                   v-model="model.suggested_by"></validated-ajax-select>
                        </div>
                        <div class="col-lg-12">
                            <btn text="Submit" :loading="loading" loading-text="Saving..."></btn>
                        </div>
                    </div>
                </s-form>
            </modal>
            <vue-table-card :fields="fields" :url="listingUrl"
                            :per-page="10" ref="table" search-placeholder="Search">
                <template #actions="{rowData}">
                    <btn-group>
                        <edit-btn @click="EditUser(rowData)"/>
                        <delete-btn @click="setDeleteUser(rowData.id)"/>
                    </btn-group>
                </template>
                <template #footer>
                    <edit-modal ref="addsuggestionsEditModal">
                        <s-form @submit="addItem">
                            <row>
                                <div class="col-lg-6">
                                    <validated-vue-select :options="sectionOptions" label="Section:" v-model="editingItem.section"></validated-vue-select>
                                </div>
                                <div class="col-lg-6">
                                    <validated-input label="Treatment content <span class='text-danger'>*</span>:" v-model="editingItem.treatment_content"
                                                     :rules="{required:true}"></validated-input>
                                </div>

                                 <div class="col-lg-12" v-if="editingItem.section==='disease'">
                                <validated-ajax-select :url="diseaseVueselect" label="Disease:" v-model="editingItem.disease"></validated-ajax-select>
                            </div>
<!--                                <div class="col-lg-12" v-if="editingItem.section==='indication'">
                                <validated-vue-select :options="indiactionOption" label="Indication:" v-model="editingItem.indication"></validated-vue-select>
                            </div>-->
                                <div class="col-lg-12" v-if="editingItem.section==='preperation'">
                                    <validated-vue-select :options="preperationOption" label="Preparation:" v-model="editingItem.preperations"></validated-vue-select>
                                </div>

                                <div class="col-lg-12 mb-6">
                                    <validated-ajax-select class="mt-2 font-weight-700 text-2 v-select-dropdown-max-h-10r" :url="suggestVueselect" label="Suggested by:" v-model="editingItem.suggested_by"></validated-ajax-select>
                                </div>
                                <div class="col-lg-12">
                                    <btn text="Submit" :loading="loading" loading-text="Saving..."></btn>
                                </div>
                            </row>
                        </s-form>
                    </edit-modal>
                    <delete-modal ref="addsuggestionsDeleteModal" @response="deleteSuccess" :url="deleteUrl" :params="{ id:deletingItem }">
                        <p>You are about to delete.
                            Are you sure about it ?
                        </p>
                    </delete-modal>
                </template>
            </vue-table-card>

        </inner-page-card>
    </container>
</template>

<script>
import InnerPageCard from '@components/InnerPageCard';
import tableFields from './add-suggestion-table-fields';
import urls from '@/data/urls';
import axios from 'secure-axios';
export default {
    name: 'AddSuggestionsPage',
    components: InnerPageCard,
    data () {
        return {
            loading: false,
            editingUrl: urls.disease.suggestion.addEdit,
            listingUrl: urls.disease.suggestion.list,
            deleteUrl: urls.disease.suggestion.delete,
            suggestVueselect: urls.disease.treatmentsAddSuggestionList.sugestedVueSelect,
            diseaseVueselect: urls.disease.disease.vueSelect,
            indicationVueselect: urls.disease.suggestion.indications,
            preparationVueselect: urls.disease.suggestion.preparation,
            indiactionOption: '',
            preperationOption: '',
            sectionOptions: [
                { label: '--------', value: '' },
                { label: 'Disease', value: 'disease' },
                /* { label: 'Indication', value: 'indication' }, */
                { label: 'Preperation', value: 'preperation' }
            ],
            detailItem: [],

            model: {
                section: '',
                suggested_by: '',
                treatment_content: '',
                disease: '',
                indication: '',
                preperations: ''
            },
            editingItem: '',
            deletingItem: '',
            fields: tableFields,
            data: [
                {
                    section: 'Goat and Sheep',
                    disease: 'gjh',
                    treatment: 'tyfgjhk',
                    preperation: 'fdgfdg',
                    indication: 'uyufjguj',
                    suggested_person: 'rtgh'

                }
            ]
        };
    },
    mounted () {
        this.fetchIndication();
        this.fetchPreparation();
    },
    methods: {

        async addItem () {
            try {
                this.loading = true;
                if (this.editingItem) {
                    console.log('in edit');
                    this.response = await axios.form(this.editingUrl, this.editingItem);
                } else {
                    console.log('in add');

                    this.response = await axios.form(this.editingUrl, this.model);
                }
                const result = this.response.data;
                console.log('result', result);
                if (result.success === true) {
                    if (this.editingItem) {
                        this.$notify('Updated Successfully', 'Success', { type: 'success' });
                        this.loading = false;
                        this.editingItem = '';
                        this.$refs.addsuggestionsEditModal.close();
                    } else {
                        this.$notify('Added Successfully', 'Success', { type: 'success' });
                        this.loading = false;
                        this.$refs.addsuggestionsAddModal.close();
                    }
                    this.$refs.table.refreshTable();
                } else {
                    const errors = this.response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.this.response.status + ' ' + error.this.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        },
        clearForm (item) {
            item.section = '';
            item.suggested_by = '';
            item.treatment_content = '';
            item.disease = '';
            item.indication = '';
            item.preperations = '';
        },
        deleteSuccess (item) {
            console.log('delete state', item);
            this.$refs.addsuggestionsDeleteModal.close();
            if (item.success) {
                this.$notify('Deleted Successfully', 'Success', {
                    type: 'success'
                });
                this.$refs.table.refreshTable();
            } else {
                console.log('item error', item.errors.Id);
                this.$notify(item.errors.Id, 'warning', {
                    type: 'warning'
                });
            }
        },
        async fetchDisease () {
            try {
                const response = await axios.get(this.suggestVueselect);
                const result = response.data.data;
                this.detailItem = result;

                console.log('detailItem', this.detailItem);
                if (result.success === true) {
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
            }
        },
        async fetchPreparation () {
            try {
                const response = await axios.get(this.preparationVueselect);
                const result = response.data.data;
                this.preperationOption = result;

                console.log('detailItem', this.detailItem);
                if (result.success === true) {
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
            }
        },
        async fetchIndication () {
            try {
                const response = await axios.get(this.indicationVueselect);
                const result = response.data.data;
                this.indiactionOption = result;

                console.log('detailItem', this.detailItem);
                if (result.success === true) {
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
            }
        },
        EditUser (item) {
            console.log(item);
            this.editingItem = '';
            this.editingItem = { ...item };
            this.$refs.addsuggestionsEditModal.show();
        },
        setDeleteUser (item) {
            console.log(item);
            this.deletingItem = item;
            this.$refs.addsuggestionsDeleteModal.show();
        },
        itemAdd () {
            this.editingItem = '';
            this.clearForm(this.model);
            this.$refs.addsuggestionsAddModal.show();
        }
    }
};
</script>

<style scoped>

</style>

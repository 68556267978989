<template>
    <div>
        <container>
            <inner-page-card heading="Advertisements">
                  <custom-size-el>
                    <transition-fade>
                        <div class="fl-x-cc h-100p" v-if="!showForm" key="anim">
                            <loading-animation></loading-animation>
                        </div>
                <!--Add Advertisement-->
                <b-form :save-url="addUrl" ref="form" @failure="EditFailure" @success="formSuccess('A')" v-slot="{model, loading}">
                    <row>
                        <col-12>
                            <validated-input label="Title
                            <span class='text-danger'>*</span>:" v-model="model.title" :rules="rules.title"
                                             :disabled="loading"/>
                        </col-12>
                        <col-12>
                            <validated-file-input label="Image <span class='text-danger'>*</span>:" border-radius="0" class="font-weight-700 text-2" v-model="model.image"
                                                  :disabled="loading"></validated-file-input>
                        </col-12>
                        <col-12 class="fl-x">
                            <h6 class="text-2 font-weight-700 pt-3">Url :</h6>
                            <validated-input class="pl-2" v-model="model.url" :rules="rules.url"
                                             :disabled="loading"/>
                        </col-12>
                        <col-12>
                            <validated-select label="Position <span class='text-danger'>*</span>:" :options="AdvertisementOptions" v-model="model.position" :rules="rules.position"
                                              :disabled="loading"/>
                        </col-12>
                        <col-12>
                            <validated-input label="Position Number :" v-model="model.position_number" :rules="rules.position_number"
                                             :disabled="loading"/>
                        </col-12>
                        <col-12>
                            <save-btn loading-text="Saving..." size="sm" :disabled="loading"
                                      :loading="loading"/>
                        </col-12>
                    </row>
                </b-form>
                    </transition-fade>
                  </custom-size-el>
            </inner-page-card>
        </container>
    </div>
</template>

<script>
import InnerPageCard from '../../../components/InnerPageCard';
import urls from '../../../data/urls';

export default {
    name: 'AddAdvertisementPage',
    components: { InnerPageCard },
    data () {
        return {
            addUrl: urls.advertisement.addEdit,
            rules: {
                title: {
                    required: true
                },
                image: {
                    required: true
                },
                position: {
                    required: true
                },
                position_number: {
                    required: false
                }
            },
            showForm: true,
            AdvertisementOptions: [
                {
                    label: 'Top',
                    value: 'top'
                }, {
                    label: 'Bottom',
                    value: 'bottom'
                }
            ]
        };
    },
    methods: {
        formSuccess (action) {
            if (action === 'A') {
                this.$notify('Advertisement Added Successfully', 'Success', {
                    type: 'success'
                });
                this.showForm = false;
                const that = this;
                setTimeout(function () {
                    that.showForm = true;
                }, 1000);
                const formValidator = this.$refs.form;
                formValidator.model = {};
            }
        },
        EditFailure (response) {
            var errorMessage = '';
            const errors = response.data.errors;
            for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                    if (key === '__all__') {
                        errorMessage = `${errors[key]}`;
                    } else {
                        errorMessage = `${key}:  ${errors[key]}`;
                    }
                    this.$notify(errorMessage, 'Warning', { type: 'warning' });
                }
            }
        }
    }
};
</script>

<style scoped>

</style>

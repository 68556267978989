<template>
    <container>
        <inner-page-card class="" :scrollbar="true" heading="NORMAL VALUES">
            <template #buttons>
                <slot name="buttons">
                    <add-btn @click="addItem"/>

                </slot>
            </template>
            <modal width="50r" title="NORMAL VALUES" ref="normalValueAddModal">
                <b-form :save-url="editingUrl" @success="formSuccess('M')" v-slot="{model, loading}">
                    <div class="row">
                        <div class="col-lg-6">
                            <validated-ajax-select label="Species <span class='text-danger'>*</span>:"
                                                   :url="speciesUrl" :disabled="false" :rules="{required:true}"
                                                   v-model="model.species"></validated-ajax-select>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Life span: " v-model="model.lifespan"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Gestation age:"
                                             v-model="model.gestation_age"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Heart rate :" v-model="model.heart_rate"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Temperature :" v-model="model.temperature"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Pulse :" v-model="model.pulse"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Respiration :"
                                             v-model="model.respiration"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Total protein :"
                                             v-model="model.total_protein"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Albumin :" v-model="model.albumin"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Globulin :" v-model="model.globulin"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Glucose :" v-model="model.glucose"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Bun :" v-model="model.bun"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Creatinine :" v-model="model.creatinine"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Calcium :" v-model="model.calcium"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="HGB :" v-model="model.hgb"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="PCV :" v-model="model.pcv"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="RBC :" v-model="model.rbc"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="WBC :" v-model="model.wbc"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Reticulocytes :" v-model="model.reticulocytes"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Absolute Reticulocytes Count :" v-model="model.absolute_reticulocytes_count"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="MCV :" v-model="model.mcv"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="MCH :" v-model="model.mch"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="MCHC :" v-model="model.mchc"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Platelets :" v-model="model.platelets"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="MPV :" v-model="model.mpv"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Neutrophils Segmented :" v-model="model.neutrophils_segmented"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Neutrophils Band :" v-model="model.neutrophils_band"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Lymphocytes :" v-model="model.lymphocytes"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Monocytes :" v-model="model.monocytes"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Eosinophils :" v-model="model.eosinophils"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Basophils :" v-model="model.basophils"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="ALT :" v-model="model.alt"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="AST :" v-model="model.ast"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="GGT :" v-model="model.ggt"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="LDH :" v-model="model.ldh"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="SDH :" v-model="model.sdh"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Bicarbonate :" v-model="model.bicarbonate"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Amylase :" v-model="model.amylase"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Alk Phos :" v-model="model.alk_phos"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Bilirubin :" v-model="model.bilirubin"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Chloride :" v-model="model.chloride"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Cholesterol :" v-model="model.cholesterol"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Magnesium :" v-model="model.magnesium"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Phosphorus :" v-model="model.phosphorus"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Potassium :" v-model="model.potassium"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Sodium :" v-model="model.sodium"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Urea :" v-model="model.urea"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Rumen Fluid ph :" v-model="model.rumen_fluid_ph"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Blood ph :" v-model="model.blood_ph"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Milk ph :" v-model="model.milk_ph"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Urine ph :" v-model="model.urine_ph"></validated-input>
                        </div>

                        <div class="col-lg-12">
                            <submit-btn :loading="loading" loading-text="Saving"/>
                        </div>
                    </div>
                </b-form>
            </modal>
            <vue-table-card :fields="fields" :url="listingUrl"
                            :per-page="10" ref="table" search-placeholder="Search">
                <template #actions="{rowData}">
                    <btn-group>
                        <view-btn @click="viewInfo(rowData)" text="View"/>
                        <edit-btn @click="EditUser(rowData)"/>
                        <delete-btn @click="setDeleteUser(rowData)"/>
                    </btn-group>
                </template>
                <template #footer>
                    <edit-modal ref="normalValueEditModal">
                        <s-form @submit="itemAdd">
                            <div class="row">
                                <div class="col-lg-6">
                                    <validated-ajax-select label="Species <span class='text-danger'>*</span>:"
                                                           :url="speciesUrl" :disabled="false" :rules="{required:true}"
                                                           v-model="editingItem.species"></validated-ajax-select>
                                </div>
                                <div class="col-lg-6">
                                    <validated-input label="Life span: " v-model="editingItem.lifespan"></validated-input>
                                </div>
                                <div class="col-lg-6">
                                    <validated-input label="Gestation age:"
                                                     v-model="editingItem.gestation_age"></validated-input>
                                </div>
                                <div class="col-lg-6">
                                    <validated-input label="Heart rate :" v-model="editingItem.heart_rate"></validated-input>
                                </div>
                                <div class="col-lg-6">
                                    <validated-input label="Temperature :" v-model="editingItem.temperature"></validated-input>
                                </div>
                                <div class="col-lg-6">
                                    <validated-input label="Pulse :" v-model="editingItem.pulse"></validated-input>
                                </div>
                                <div class="col-lg-6">
                                    <validated-input label="Respiration :"
                                                     v-model="editingItem.respiration"></validated-input>
                                </div>
                                <div class="col-lg-6">
                                    <validated-input label="Total protein :"
                                                     v-model="editingItem.total_protein"></validated-input>
                                </div>
                                <div class="col-lg-6">
                                    <validated-input label="Albumin :" v-model="editingItem.albumin"></validated-input>
                                </div>
                                <div class="col-lg-6">
                                    <validated-input label="Globulin :" v-model="editingItem.globulin"></validated-input>
                                </div>
                                <div class="col-lg-6">
                                    <validated-input label="Glucose :" v-model="editingItem.glucose"></validated-input>
                                </div>
                                <div class="col-lg-6">
                                    <validated-input label="Bun :" v-model="editingItem.bun"></validated-input>
                                </div>
                                <div class="col-lg-6">
                                    <validated-input label="Creatinine :" v-model="editingItem.creatinine"></validated-input>
                                </div>
                                <div class="col-lg-6">
                                    <validated-input label="Calcium :" v-model="editingItem.calcium"></validated-input>
                                </div>
                                 <div class="col-lg-6">
                            <validated-input label="HGB :" v-model="model.hgb"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="PCV :" v-model="model.pcv"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="RBC :" v-model="model.rbc"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="WBC :" v-model="model.wbc"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Reticulocytes :" v-model="model.reticulocytes"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Absolute Reticulocytes Count :" v-model="model.absolute_reticulocytes_count"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="MCV :" v-model="model.mcv"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="MCH :" v-model="model.mch"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="MCHC :" v-model="model.mchc"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Platelets :" v-model="model.platelets"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="MPV :" v-model="model.mpv"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Neutrophils Segmented :" v-model="model.neutrophils_segmented"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Neutrophils Band :" v-model="model.neutrophils_band"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Lymphocytes :" v-model="model.lymphocytes"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Monocytes :" v-model="model.monocytes"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Eosinophils :" v-model="model.eosinophils"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Basophils :" v-model="model.basophils"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="ALT :" v-model="model.alt"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="AST :" v-model="model.ast"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="GGT :" v-model="model.ggt"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="LDH :" v-model="model.ldh"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="SDH :" v-model="model.sdh"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Bicarbonate :" v-model="model.bicarbonate"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Amylase :" v-model="model.amylase"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Alk Phos :" v-model="model.alk_phos"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Bilirubin :" v-model="model.bilirubin"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Chloride :" v-model="model.chloride"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Cholesterol :" v-model="model.cholesterol"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Magnesium :" v-model="model.magnesium"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Phosphorus :" v-model="model.phosphorus"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Potassium :" v-model="model.potassium"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Sodium :" v-model="model.sodium"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Urea :" v-model="model.urea"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Rumen Fluid ph :" v-model="model.rumen_fluid_ph"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Blood ph :" v-model="model.blood_ph"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Milk ph :" v-model="model.milk_ph"></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-input label="Urine ph :" v-model="model.urine_ph"></validated-input>
                        </div>
                                <div class="col-lg-12">
                                    <submit-btn :loading="loading" loading-text="Saving"/>
                                </div>
                            </div>

                        </s-form>
                    </edit-modal>
                    <delete-modal ref="normalValueDeleteModal" :url="deletingUrl" :params="{id:deletingItem.id}" @response="deleteSuccess">
                        <p>You are about to delete a <b> {{ deletingItem.species_name }}</b>.
                            Are you sure about it ?
                        </p>
                    </delete-modal>
                </template>
            </vue-table-card>

        </inner-page-card>
    </container>
</template>

<script>
import InnerPageCard from '@components/InnerPageCard';
import normalvalueTableFields from '@/views/diagnosis/normal-values/normalvalue-table-fields';
import urls from '@/data/urls';
import axios from 'secure-axios';

export default {
    name: 'NormalValuesPage',
    components: InnerPageCard,
    data () {
        return {
            loading: false,
            editingItem: '',
            deletingItem: '',
            editingUrl: urls.diagnosis.normalValues.addEdit,
            deletingUrl: urls.diagnosis.normalValues.delete,
            listingUrl: urls.diagnosis.normalValues.list,
            fields: normalvalueTableFields,
            speciesUrl: urls.diagnosis.normalValues.species,
            data: [
                {
                    species: 'fex',
                    calcium: '2',
                    life_span: '12',
                    generation_age: '2',
                    heart_rate: '0.4',
                    temperature: '2',
                    pulse: '1.3',
                    respiration: '84',
                    total_protein: '75',
                    albumin: '23',
                    globulin: '1',
                    glucose: '1',
                    bun: '2.3',
                    creatine: '1.3'
                }
            ],
            model: {
                species: '',
                lifespan: '',
                gestation_age: '',
                heart_rate: '',
                temperature: '',
                pulse: '',
                total_protein: '',
                albumin: '',
                globulin: '',
                glucose: '',
                respiration: '',
                bun: '',
                creatinine: '',
                calcium: '',
                hgb: '',
                pcv: '',
                rbc: '',
                wbc: '',
                reticulocytes: '',
                absolute_reticulocytes_count: '',
                mcv: '',
                mch: '',
                mchc: '',
                platelets: '',
                mpv: '',
                neutrophils_segmented: '',
                neutrophils_band: '',
                lymphocytes: '',
                monocytes: '',
                eosinophils: '',
                basophils: '',
                alt: '',
                ggt: '',
                ldh: '',
                sdh: '',
                bicarbonate: '',
                amylase: '',
                alk_phos: '',
                bilirubin: '',
                chloride: '',
                cholesterol: '',
                magnesium: '',
                phosphorus: '',
                potassium: '',
                sodium: '',
                urea: '',
                rumen_fluid_ph: '',
                blood_ph: '',
                milk_ph: '',
                urine_ph: ''
            }
        };
    },
    methods: {
        async viewInfo (item) {
            this.$router.push('/normal-details/' + item.id);
            // console.log(item);
            // const that = this;
            // const response = await axios.form(
            //     this.detailsUrl, { id: item.id }
            // ).catch(function (exception) {
            //     that.serverError();
            // });
            // const json = response.data;
            // const dataItem = json.data;
            // console.log('json', json.data);
            // this.currentTransactionItem = dataItem;
            // this.$refs.transactionViewModal.show();
            // this.makeRefundClicked = false;
        },
        deleteSuccess (item) {
            this.$refs.normalValueDeleteModal.close();
            if (item.success) {
                this.$notify('Deleted Successfully', 'Success', {
                    type: 'success'
                });
                this.$refs.table.refreshTable();
            } else {
                console.log('item error', item.errors.Id);
                this.$notify(item.errors.Id, 'warning', {
                    type: 'warning'
                });
            }
        },
        clearForm (item) {
            item.species = '';
            item.lifespan = '';
            item.gestation_age = '';
            item.heart_rate = '';
            item.temperature = '';
            item.pulse = '';
            item.total_protein = '';
            item.albumin = '';
            item.globulin = '';
            item.glucose = '';
            item.respiration = '';
            item.bun = '';
            item.creatinine = '';
            item.calcium = '';
        },
        formSuccess () {
            this.$notify('Added Successfully', 'Success', { type: 'success' });
            this.$refs.normalValueAddModal.close();
            this.$refs.table.refreshTable();
        },
        EditUser (item) {
            console.log(item);
            this.editingItem = '';
            this.editingItem = { ...item };
            this.$refs.normalValueEditModal.show();
        },
        setDeleteUser (item) {
            console.log(item);
            this.deletingItem = item;
            this.$refs.normalValueDeleteModal.show();
        },
        addItem () {
            this.$refs.normalValueAddModal.show();
        },
        async itemAdd () {
            try {
                this.loading = true;
                this.response = await axios.form(this.editingUrl, this.editingItem);
                const result = this.response.data;
                console.log('result', result);
                if (result.success === true) {
                    this.$notify('Updated Successfully', 'Success', { type: 'success' });
                    this.loading = false;
                    this.clearForm(this.model);
                    this.$refs.normalValueEditModal.close();
                    this.$refs.table.refreshTable();
                } else {
                    const errors = this.response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.this.response.status + ' ' + error.this.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        }
    }

};
</script>

<style scoped>

</style>

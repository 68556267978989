const tableFields = [
    {
        name: 'heading',
        title: 'Title',
        titleClass: '',
        sortField: 'heading',
        dataClass: ''
    },
    {
        name: 'disease_cat_name',
        title: 'Species',
        sortField: 'disease_cat',
        titleClass: '',
        dataClass: ''
    },
    {
        name: '__slot:actions',
        title: 'Actions',
        titleClass: '',
        dataClass: ''
    }
];

export default tableFields;

q<template>
    <container>
        <inner-page-card class="" :scrollbar="true" heading="INDICATIONS">
            <template #buttons>
                <slot name="buttons">
                    <add-btn @click="$refs.addModal.show()"/>

                </slot>
            </template>

            <vue-table-card :fields="fields" :url="listUrl" ref="table">
                <template #actions="{rowData}">
                    <btn-group>
                        <view-btn @click="viewDetails(rowData)"/>

                        <edit-btn @click="setEdit(rowData)"/>
                        <delete-btn @click="setDelete(rowData)"/>
                    </btn-group>
                </template>
                <template #footer>
                    <delete-modal ref="planDeleteModal" :url="deleteUrl" :params="deletingItem"
                                  @response="deleteComplete">
                        <p>You are about to delete the plan
                            <b v-if="deletingItem"> {{ deletingItem.indication_name }}</b>.Are you sure?</p>
                        <template #loading>
                            <loading-animation/>
                            Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
                        </template>
                    </delete-modal>
                    <modal width="55r" title="Add" ref="addModal">
                        <b-form :save-url="addEditUrl" @failure="userEditFailure" @success="formSuccess('A')"
                                v-slot="{model, loading}">
                            <row>
                                <col-12 lg="6">
                                    <validated-input class="mt-n2" v-model="model.indication_name"
                                                     :rules="rules.indication_name"
                                                     :disabled="loading"
                                                     label="Indication <span class='text-danger'>*</span> :"></validated-input>
                                </col-12>

                                <col-12 lg="6">
                                    <form-group
                                        :disabled="loading" label="Content <span class='text-danger'>*</span>:">
                                        <ckeditor class="w-100p" :editor="editor" v-model="model.content"
                                                  :rules="rules.content"
                                                  :config="editorConfig"></ckeditor>
                                    </form-group>

                                </col-12>
                                <col-12>
                                    <submit-btn loading-text="Saving..." size="sm" :disabled="loading"
                                                :loading="loading"></submit-btn>
                                </col-12>
                            </row>
                        </b-form>
                    </modal>
                    <modal width="55r" title="Edit" ref="editPlanModal">
                        <b-form :save-url="addEditUrl" @failure="userEditFailure" @success="formSuccess('M')"
                                v-slot="{model, loading}"
                                :initial-data="editingItem">
                            <row>
                                <col-12 lg="6">
                                    <validated-input class="mt-n2" v-model="model.indication_name"
                                                     :rules="rules.indication_name"
                                                     :disabled="loading"
                                                     label="Indication <span class='text-danger'>*</span> :"></validated-input>
                                </col-12>

                                <col-12 lg="6">
                                    <form-group
                                        :disabled="loading" label="Content <span class='text-danger'>*</span>:">
                                        <ckeditor class="w-100p" :editor="editor" v-model="model.content"
                                                  :rules="rules.content"
                                                  :config="editorConfig"></ckeditor>
                                    </form-group>

                                </col-12>
                                <col-12>
                                    <submit-btn loading-text="Saving..." size="sm" :disabled="loading"
                                                :loading="loading"></submit-btn>
                                </col-12>
                            </row>
                        </b-form>
                    </modal>
                </template>
            </vue-table-card>

        </inner-page-card>
    </container>
</template>

<script>
import InnerPageCard from '@components/InnerPageCard';
import indicationfieldpage from '@/views/pharmacy/indications/indications-fields';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import urls from '../../../data/urls';

export default {
    name: 'AddPlanPage',
    components: InnerPageCard,
    data () {
        return {
            fields: indicationfieldpage,
            listUrl: urls.pharmacy.indication.list,
            addEditUrl: urls.pharmacy.indication.addEdit,
            deleteUrl: urls.pharmacy.indication.delete,
            activating: false,
            editingItem: null,
            currentUserItem: null,

            // activatePlanItem: {
            //     id: ''
            // },
            deletingItem: {
                name: ''
            },

            rules: {
                indication_name: {
                    required: true
                },
                content: {
                    required: true
                }
            },

            data: [
                {
                    name: 'alfredo-',
                    description: 'lorem afga AWH',
                    value: '2',
                    expiry_months: '2-3-2021',
                    status: 'lorem ',
                    plan: '2'
                }
            ],
            editor: ClassicEditor,
            editorConfig: {}

        };
    },
    methods: {
        formSuccess (action) {
            const refs = this.$refs;
            this.$refs.addModal.close();
            this.$refs.editPlanModal.close();
            if (action === 'A') {
                this.$notify('Indication Added Successfully', 'Success', {
                    type: 'success'
                });
            } else {
                this.$notify('Indication Updated Successfully', 'Success', {
                    type: 'success'
                });
            }
            refs.table.refreshTable();

            this.editingItem = null;
        },
        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.editPlanModal.show();
        },
        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.planDeleteModal.show();
        },
        viewDetails (item) {
            console.log(item);
            this.$router.push('/indications-details/' + item.id);
        },
        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.planDeleteModal.close();
            this.$notify('Indication Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        userEditFailure (response) {
            const errors = response.data.errors;
            for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                    const errorMessage = `${key}:  ${errors[key]}`;
                    this.$notify(errorMessage, 'Warning', { type: 'warning' });
                }
            }
        }

    }

};
</script>

<style scoped>

</style>

const usersTableFields = [
    {
        name: 'category_name',
        title: 'Name',
        titleClass: '',
        dataClass: '',
        sortField: 'category_name'
    },
    {
        name: 'added_by',
        title: 'Added By',
        titleClass: '',
        dataClass: '',
        sortField: 'added_by'
    },
    {
        name: '__slot:actions',
        title: 'Actions',
        titleClass: 'text-right',
        dataClass: 'text-right'
    }
];

export default usersTableFields;

const tableFields = [
    {
        name: 'species_name',
        title: 'Species',
        titleClass: '',
        dataClass: 'w-25r',
        sortField: 'species'

    },
    {
        name: 'disease_name',
        title: 'Disease',
        titleClass: '',
        dataClass: 'w-25r',
        sortField: 'disease'

    },
    {
        name: 'pathological_findings',
        title: 'Pathological Findings',
        titleClass: '',
        dataClass: 'w-25r',
        sortField: 'pathological_findings'

    },

    {
        name: '__slot:actions',
        title: 'Actions',
        titleClass: '',
        dataClass: ''
    }
];

export default tableFields;

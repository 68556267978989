<template>
    <div>
        <container>
            <inner-page-card heading="Add Drugs">
                <div class="h-80vh">
                    <transition-fade>
                        <div class="fl-x-cc h-100p" v-if="!showForm" key="anim">
                            <loading-animation></loading-animation>
                        </div>
                        <div  v-else>
                            <row>
                                 <col-12>
                                    <add-btn color="secondary" size="sm" text="Add Category" @click="$refs.addCategoryModal.show()"/>
                                </col-12>
                            </row>
                             <b-form :save-url="drugAddUrl" ref="form" @failure="drugAddFormFailure"
                                @success="drugAddFormSuccess('A')" v-slot="{model, loading}" class="pb-4">
                            <row>
                                <col-12 mt="2">
                                    <validated-ajax-select :url="drugCategorySelectUrl" label="Select Category <span class='text-danger'>*</span> :"
                                                           v-model="model.drug_category" :rules="drugRules.drug_category" multiple
                                                           :disabled="loading"></validated-ajax-select>
                                </col-12>
                                <col-12>
                                    <validated-input label="Drug name <span class='text-danger'>*</span> :"
                                                     v-model="model.drug_name" :rules="drugRules.drug_name"
                                                     :disabled="loading"></validated-input>
                                </col-12>
                                <col-12>
                                    <form-group ck-editor-size="lg" label="Drug action <span class='text-danger'>*</span> :">
                                        <ckeditor label="Drug action <span class='text-danger'>*</span> :" class="w-100p" :editor="editor" v-model="model.drug_action"
                                                  :rules="drugRules.drug_action" :config="editorConfig"></ckeditor>
                                    </form-group>
                                </col-12>
                                <col-12>
                                    <form-group ck-editor-size="lg" label="Indications <span class='text-danger'>*</span> :">
                                        <ckeditor name="Indications" label="Indications" class="w-100p"
                                                  :editor="editor" v-model="model.indications"
                                                  :rules="drugRules.indications"
                                                  :config="editorConfig"></ckeditor>
                                        <!--                                <validation-provider name="Indications" :rules="drugRules.indications" immediate v-slot="{ errors }" class="w-100p">-->
                                        <!--                                    <ckeditor @input="ckeditorInputEventHandler('indications')" name="Indications" label="Indications" class="w-100p" :required="true"-->
                                        <!--                                              :editor="editor" v-model="model.indications"-->
                                        <!--                                              :rules="drugRules.indications"-->
                                        <!--                                              :config="editorConfig"></ckeditor>-->
                                        <!--                                    <div v-if="ckEditorInputs.indications === true">-->
                                        <!--                                        <div class="text-danger" v-if="errors[0] && editor.model">{{ errors[0] }} {{model.indications}}</div>-->
                                        <!--                                    </div>-->
                                        <!--                                </validation-provider>-->
                                    </form-group>
                                    <!--                            <div v-if="!model.indication" class="text-danger" style="display:block;">Indications Field Required</div>-->
                                </col-12>
                                <col-12>
                                    <form-group ck-editor-size="lg" label="Dose <span class='text-danger'>*</span> :">
                                        <ckeditor class="w-100p" :editor="editor" v-model="model.dose" :rules="drugRules.dose" :disabled="loading"
                                                  :config="editorConfig"></ckeditor>
                                    </form-group>
                                </col-12>
                                <col-12>
                                    <form-group ck-editor-size="lg" label="Contraindications <span class='text-danger'>*</span> :">
                                        <ckeditor class="w-100p" :editor="editor" v-model="model.contraindications"
                                                  :rules="drugRules.contraindications" :disabled="loading" :config="editorConfig"></ckeditor>
                                    </form-group>
                                </col-12>
                                <col-12>
                                    <form-group ck-editor-size="lg" label="Available brands <span class='text-danger'>*</span> :">
                                        <ckeditor class="w-100p" :editor="editor" v-model="model.available_brands"
                                                  :rules="drugRules.available_brands" :disabled="loading" :config="editorConfig"></ckeditor>
                                    </form-group>
                                </col-12>
                                <col-12>
                                    <save-btn loading-text="Saving..." :disabled="loading"
                                              :loading="loading"/>
                                </col-12>
                            </row>

                        </b-form>
                            <!--                Add Category-->
                            <modal ref="addCategoryModal" title="Add Category" width="55r">
                            <b-form :save-url="addDrugCategoryUrl" @success="drugCategoryFormSuccess('A')" v-slot="{model, loading}">
                                <row>
                                    <col-12>
                                        <validated-input label="Category name <span class='text-danger'>*</span> :"
                                                         v-model="model.category_name" :rules="categoryRules.category_name" :disabled="loading">
                                        </validated-input>
                                    </col-12>
                                    <col-12>
                                        <save-btn loading-text="Saving..." :disabled="loading"
                                                  :loading="loading"></save-btn>
                                    </col-12>
                                </row>
                            </b-form>
                        </modal>
                        </div>
                    </transition-fade>
                </div>
            </inner-page-card>
        </container>
    </div>
</template>

<script>
import ckeditorSettings from '../../../components/ckeditor-settings';
import urls from '../../../data/urls';

export default {
    name: 'AddDrugsPage',
    data () {
        return {
            model: {
                drugName: ''
            },
            editor: ckeditorSettings.ClassicEditor,
            editorConfig: ckeditorSettings.editorConfig,
            addDrugCategoryUrl: urls.drugs.drugCategory.addEdit,
            drugAddUrl: urls.drugs.drug.addEdit,
            drugCategorySelectUrl: urls.drugs.drugCategory.vueSelect,
            categoryRules: {
                category_name: {
                    required: false
                }
            },
            drugCategoryOptions: [
                {
                    label: 'Categ 1',
                    value: '1'
                },
                {
                    label: 'Categ 2',
                    value: '2'
                }
            ],
            drugRules: {
                drug_category: {
                    required: true
                },
                drug_name: {
                    required: true
                },
                drug_action: {
                    required: true
                },
                indications: {
                    required: true
                },
                dose: {
                    required: true
                },
                contraindications: {
                    required: true
                },
                available_brands: {
                    required: true
                }
            },
            ckEditorInputs: {
                indications: false
            },
            showForm: true
        };
    },
    methods: {
        showDrugName () {
            console.log(this.model.drugName);
            alert(this.model.drugName);
        },
        ckeditorInputEventHandler (item) {
            this.ckEditorInputs[item] = true;
        },
        drugCategoryFormSuccess (action) {
            // const refs = this.$refs;
            this.$refs.addCategoryModal.close();
            // this.$refs.FarmEditModal.close();
            if (action === 'A') {
                this.$notify('Category Added Successfully', 'Success', {
                    type: 'success'
                });
            } else {
                this.$notify('Category Updated Successfully', 'Success', {
                    type: 'success'
                });
            }
            // refs.table.refreshTable();
            //
            // this.editingItem = null;
        },
        drugAddFormSuccess (action) {
            // const refs = this.$refs;
            // this.$refs.addCategoryModal.close();
            // this.$refs.FarmEditModal.close();
            if (action === 'A') {
                this.$notify('Drug Added Successfully', 'Success', {
                    type: 'success'
                });
            } else {
                this.$notify('Drug Updated Successfully', 'Success', {
                    type: 'success'
                });
            }
            this.showForm = false;
            const that = this;
            setTimeout(function () {
                that.showForm = true;
            }, 1000);
            // refs.table.refreshTable();
            //
            // this.editingItem = null;
        },
        drugAddFormFailure (response) {
            const errors = response.data.errors;
            console.log('errors');
            for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                    const errorMessage = `${key}:  ${errors[key]}`;
                    this.$notify(errorMessage, 'Warning', { type: 'warning' });
                }
            }
        }
    }
};
</script>

<style scoped>

</style>

<template>
    <container>
        <inner-page-card class="" :scrollbar="true" heading="DELETED PLANS">
            <vue-table-card :fields="fields" :table-data="data" :url="deletedPlanListUrl" ref="table">
                <template #plan_type="{rowData}">
                    <div v-html="getPlanType(rowData.plan_type)"/>
                </template>
            </vue-table-card>

        </inner-page-card>
    </container>
</template>

<script>
import InnerPageCard from '@components/InnerPageCard';
import FormerPlansPage from '@/views/plans/former-plans/former-plans-fields';
import urls from '../../../data/urls';

export default {
    name: 'FormerPlansPage',
    components: InnerPageCard,
    data () {
        return {
            fields: FormerPlansPage,
            deletedPlanListUrl: urls.userManagement.plan.deletedPlanList,
            planOptions: [
                {
                    label: 'Basic',
                    value: '1'
                }, {
                    label: 'Standard',
                    value: '2'
                }, {
                    label: 'Premium',
                    value: '3'
                }
            ],
            data: [
                {
                    name: 'alfredo-',
                    description: 'lorem afga AWH',
                    value: '2',
                    expiry_months: '2-3-2021',
                    status: 'lorem ',
                    plan: '2'
                }
            ]
        };
    },
    methods: {
        getPlanType (planType) {
            const filteredPlan = this.planOptions.filter(plan => plan.value === planType);
            return filteredPlan[0].label;
        }
    }

};
</script>

<style scoped>

</style>

import { render, staticRenderFns } from "./AddFarmPage.vue?vue&type=template&id=2b789764&scoped=true&"
import script from "./AddFarmPage.vue?vue&type=script&lang=js&"
export * from "./AddFarmPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b789764",
  null
  
)

export default component.exports
<template>
    <container>
        <inner-page-card :heading="detailItem.title" v-if="detailItem">
            <div v-if="detailItem">
                <p class="font-weight-600 ">Author: <span
                    class="font-weight-lighter  text-2">{{detailItem.author}}</span></p>
                <p class="font-weight-600 ">Batch/Designation: <span
                    class="font-weight-lighter  text-2">{{detailItem.batch}}</span></p>
                <div v-html="detailItem.content"></div>
            </div>
            <div v-else class="d-flex">
                <loading-animation style="margin-left: 34rem"></loading-animation>
                <p>Please wait...</p>
            </div>
            <inner-page-card :scrollbar="true" heading="IMAGES" class="mx-n4 mt-5">
                <template #buttons>
                    <slot name="buttons">
                        <btn icon="fa fa-plus" color="success" @click="ItemAdd" border-radius="0"></btn>
                    </slot>
                </template>
                <vue-table-card :fields="fields" :url="ListUrl" class="p-0"
                :per-page="10" ref="table" search-placeholder="Title" :extra-params="{article_id : articleId}">
                    <template #actions="{rowData}">
                        <btn-group>
                            <edit-btn @click="EditItem(rowData)"/>
                            <delete-btn @click="setDeleteItem(rowData.id)"/>
                        </btn-group>
                    </template>

                    <template #footer>
                         <modal width="50r" ref="addModal" title="Add Article">
                            <s-form @submit="addItem">
                            <row>
                                <div class="col-lg-6 mt-n2">
                                    <p class="font-weight-700 text-2 mb-2">File <span class='text-danger'>*</span>:</p>
                                    <validated-file-input  v-model="model.file" border-radius="0"></validated-file-input>
                                </div>
                                <div class="col-lg-6 mt-n2">
                                    <validated-input label="Description :"  v-model="model.description"></validated-input>
                                </div>
                                <div class="col-lg-6 mt-n2">
                                    <validated-input label="Order :" type="number" v-model="model.order"></validated-input>
                                </div>
                                <col-12>
                                    <submit-btn text="Upload" :loading="loading" loading-text="saving..."></submit-btn>
                                </col-12>
                            </row>
                                </s-form>
                        </modal>
                        <edit-modal ref="EditModal" width="55r">
                            <s-form @submit="addItem">
                                <row>
                                    <div class="col-lg-4 mt-n2">
                                        <p class="font-weight-700 text-2">Image <span class='text-danger'>*</span>: Currently:</p>
                                        <validated-file-input label="change:" v-model="editingItem.file" border-radius="0"></validated-file-input>
                                    </div>
                                    <div class="col-lg-4 mt-4">
                                        <validated-input label="Description :" v-model="editingItem.description"></validated-input>
                                    </div>
                                    <div class="col-lg-4 mt-4">
                                        <validated-input type="number" label="Order :" v-model="editingItem.order"></validated-input>
                                    </div>
                                    <col-12>
                                        <btn text="Submit" :loading="loading" loading-text="saving..."></btn>
                                    </col-12>
                                </row>
                            </s-form>
                        </edit-modal>
                        <delete-modal ref="DeleteModal" :url="deleteUrl" :params="{id : deletingItem}" @response="deleteSuccess">
                            <p>You are about to delete
                        <b v-if="deletingItem"> {{ deletingItem.file }}</b>
                        Are you sure about it ?</p>
                      <template #loading>
                        <loading-animation/>
                        Please wait while we delete <b v-html="deletingItem && deletingItem.file"></b>.
                    </template>
                        </delete-modal>
                    </template>
                </vue-table-card>
            </inner-page-card>
        </inner-page-card>
    </container>
</template>

<script>
import tableFields from './articleslist-details-table-fields';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'secure-axios';
import urls from '@/data/urls';

export default {
    name: 'ArticleOldListDetailsPage',
    data () {
        return {
            loading: false,
            editingItem: '',
            deletingItem: '',
            addEditUrl: urls.article.oldArticleImage.addEditUrl,
            deleteUrl: urls.article.oldArticleImage.deleteUrl,
            ListUrl: urls.article.oldArticleImage.ListUrl,
            articleId: this.$route.params.id,
            detailItem: '',
            detailUrl: urls.article.oldArticle.detail,
            fields: tableFields,
            data: [
                {
                    images: '/media/article/1580500463347_GASEOUS_ANESTHESIA_CIRCUIT..jpg',
                    order: 'lorem afga AWH'
                }
            ],
            model: {
                article: this.$route.params.id,
                order: '',
                file: '',
                description: ''
            },
            editor: ClassicEditor,
            editorData: '<p> </p>',
            editorConfig: {}
        };
    },
    mounted () {
        console.log('id', this.articleId);
        this.fetchData();
    },
    methods: {
        deleteSuccess () {
            this.$refs.DeleteModal.close();
            this.$notify('Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        async addItem () {
            console.log('addEdit');
            try {
                this.loading = true;
                if (this.editingItem) {
                    console.log('in edit');
                    this.editingItem.article = this.$route.params.id;
                    this.response = await axios.form(urls.article.oldArticleImage.addEditUrl, this.editingItem);
                } else {
                    console.log('in add');

                    this.response = await axios.form(urls.article.oldArticleImage.addEditUrl, this.model);
                }
                const result = this.response.data;
                console.log('result', result);
                if (result.success === true) {
                    if (this.editingItem) {
                        this.$notify('Updated Successfully', 'Success', { type: 'success' });
                        this.loading = false;
                        this.editingItem = '';
                        this.$refs.EditModal.close();
                    } else {
                        this.$notify('Added Successfully', 'Success', { type: 'success' });
                        this.loading = false;
                        this.$refs.addModal.close();
                    }
                    this.$refs.table.refreshTable();
                } else {
                    const errors = this.response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.this.response.status + ' ' + error.this.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        },
        async fetchData () {
            try {
                const response = await axios.form(this.detailUrl, { id: this.articleId });
                const result = response.data;
                this.detailItem = result.data;
                console.log('detailItem', this.detailItem);
                if (result.success === true) {
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
            }
        },
        ItemAdd () {
            this.editingItem = '';
            this.$refs.addModal.show();
        },
        EditItem (item) {
            console.log(item);
            this.editingItem = '';
            this.editingItem = { ...item };

            this.$refs.EditModal.show();
        },
        setDeleteItem (item) {
            console.log(item);
            this.deletingItem = '';
            this.deletingItem = item;
            this.$refs.DeleteModal.show();
        }
    }
};
</script>

<style scoped>

</style>

const tableFields = [
    {
        name: 'name',
        title: 'Name',
        titleClass: '',
        dataClass: '',
        sortField: 'name'
    },
    {
        name: 'phone_number',
        title: 'Phone number',
        titleClass: '',
        dataClass: '',
        sortField: 'phone_number'
    },
    {
        name: 'transaction_id',
        title: 'Transaction id',
        titleClass: '',
        dataClass: '',
        sortField: 'transaction_id'
    },
    {
        name: 'status',
        title: 'Status',
        titleClass: '',
        dataClass: '',
        sortField: 'status'
    },
    {
        name: 'amount',
        title: 'Amount',
        titleClass: '',
        dataClass: 'w-10r',
        sortField: 'amount'
    },
    {
        name: 'expiry_months',
        title: 'Expiry month',
        titleClass: '',
        dataClass: '',
        sortField: 'expiry_months'
    },
    {
        name: 'initiated_date',
        title: 'Initiated date',
        titleClass: '',
        dataClass: '',
        sortField: 'initiated_date'
    },
    {
        name: '__slot:actions',
        title: 'Actions',
        titleClass: '',
        dataClass: ''
    }
];

export default tableFields;

const tableFields = [
    {
        name: 'species_name',
        title: 'Species',
        sortField: 'species_name',
        titleClass: '',
        dataClass: 'w-30r'
    },
    {
        name: 'test_type',
        title: 'Test Type',
        sortField: 'test_type',
        titleClass: '',
        dataClass: 'w-40r'
    },

    {
        name: '__slot:actions',
        title: 'Actions',
        titleClass: '',
        dataClass: ''
    }
];

export default tableFields;

<template>
    <container>
        <table-page heading="Pending Treatment Suggestions">
            <vue-table-card :fields="fields" :url="treatmentUrl"
          :per-page="10" ref="table" search-placeholder="Search">
                <template #actions="{rowData}">
                    <btn-group>
                        <edit-btn @click="EditUser(rowData)"/>
                        <approve-btn @click="ApproveUser(rowData)"/>
                        <delete-btn @click="setDeleteUser(rowData)"/>
                    </btn-group>
                </template>
                <template #footer>
                    <edit-modal ref="treatmentsuggestionEditModal">
                        <s-form @submit="addItem">
                            <row>
                                <div class="col-lg-6">
                                    <validated-input label="Section:" v-model="editingItem.section"></validated-input>
                                </div>
                                <div class="col-lg-6">
                                    <validated-input label="Treatment Content <span class='text-danger'>*</span>:" :rules="{ required: true }" v-model="editingItem.treatment_content"></validated-input>
                                </div>
                                <div class="col-lg-12">
                                    <validated-ajax-select class="mt-2 font-weight-700 text-2 v-select-dropdown-max-h-10r" :url="diseaseUrl" label="Disease:" v-model="editingItem.disease"></validated-ajax-select>
                                </div>
                                <div class="col-lg-12 mb-6">
                                    <validated-ajax-select class="mt-2 font-weight-700 text-2 v-select-dropdown-max-h-10r" :url="suggestVueselect" label="Suggested by:" v-model="editingItem.suggested_by" ></validated-ajax-select>
                                </div>
                                <div class="col-lg-12">
                                    <btn text="Submit" :loading="loading" loading-text="saving..."></btn>
                                </div>
                            </row>
                        </s-form>
                    </edit-modal>
                    <approve-modal ref="treatmentsuggestionApproveModal" @response="approveSuccess" :url="approveUrl" :params="{id : approveitem.id}">
                        <p>You are about to Approve <b>{{approveitem.disease_name}}</b>.<br>
                            Are you sure about it ?
                        </p>
                    </approve-modal>
                    <delete-modal ref="treatmentsuggestionDeleteModal" @response="deleteSuccess" :url="deleteUrl" :params="{id : deleteItem.id}">
                        <p>You are about to delete <b>{{deleteItem.disease_name}}</b>.<br>
                            Are you sure about it ?
                        </p>
                    </delete-modal>
                </template>
            </vue-table-card>

        </table-page>
    </container>
</template>

<script>
import tableFields from './treatment-suggestion-table-fields';
import ApproveModal from '../../../components/modals/ApproveModal';
import axios from 'secure-axios';
import urls from '@/data/urls';

export default {
    name: 'TreatmentsSuggestionsPage',
    components: { ApproveModal },
    data () {
        return {
            addEditUrl: urls.disease.treatmentsAddSuggestionList.addEdit,
            suggestVueselect: urls.disease.treatmentsAddSuggestionList.sugestedVueSelect,
            treatmentUrl: urls.disease.treatmentsAddSuggestionList.treatmentList,
            diseaseUrl: urls.disease.disease.vueSelect,
            deleteUrl: urls.disease.treatmentsAddSuggestionList.delete,
            approveUrl: urls.disease.treatmentsAddSuggestionList.approve,
            approveitem: '',
            treatmentOptions: [],
            diseaseOptions: [],
            editingItem: '',
            deleteItem: '',
            loading: false,
            URL: urls.disease.treatmentsAddSuggestionList,
            fields: tableFields,
            data: [
                {
                    disease: 'BOVINE BACILLARY HEMOGLOBINURIA',
                    treatment: 'ggg',
                    suggested_person: 'vhgh'
                }
            ]
        };
    },
    mounted () {
        /* this.fetchDisease();
        this.fetchTreatment(); */
    },
    methods: {
        deleteSuccess (item) {
            console.log('delete state', item);
            this.$refs.treatmentsuggestionDeleteModal.close();
            if (item.success) {
                this.$notify('Deleted Successfully', 'Success', {
                    type: 'success'
                });
                this.$refs.table.refreshTable();
            } else {
                console.log('item error', item.errors.Id);
                this.$notify(item.errors.Id, 'warning', {
                    type: 'warning'
                });
            }
        },
        approveSuccess (item) {
            console.log('approve state', item);
            this.$refs.treatmentsuggestionApproveModal.close();
            this.$notify('Approved Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        async fetchTreatment () {
            try {
                const response = await axios.get(this.suggestVueselect);
                const result = response.data.data;
                this.treatmentOptions = this.diseaseOptions = result.map(({ label }) => label);
                ;
                console.log('suggesyted option', this.treatmentOptions);
                if (result.success === true) {
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
            }
        },
        async fetchDisease () {
            try {
                const response = await axios.get(this.diseaseUrl);
                const result = response.data.data;
                this.diseaseOptions = result.map(({ label }) => label);

                console.log('detailItem', this.diseaseOptions);
                if (result.success === true) {
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
            }
        },
        async addItem () {
            try {
                this.loading = true;
                const response = await axios.form(this.addEditUrl, this.editingItem);
                const result = response.data;
                console.log('result', result);
                if (result.success === true) {
                    this.$notify('Updated Successfully', 'Success', { type: 'success' });
                    this.loading = false;
                    this.$refs.treatmentsuggestionEditModal.close();
                    this.$refs.table.refreshTable();
                    this.$emit('success');
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        },
        EditUser (item) {
            console.log(item);
            this.editingItem = '';
            this.editingItem = { ...item };
            this.$refs.treatmentsuggestionEditModal.show();
        },
        ApproveUser (item) {
            console.log(item);
            this.approveitem = item;
            this.$refs.treatmentsuggestionApproveModal.show();
        },
        setDeleteUser (item) {
            console.log(item);
            this.deleteItem = item;
            this.$refs.treatmentsuggestionDeleteModal.show();
        }
    }
};
</script>

<style scoped>

</style>

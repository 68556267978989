import { render, staticRenderFns } from "./AddCategoryPage.vue?vue&type=template&id=20874836&scoped=true&"
import script from "./AddCategoryPage.vue?vue&type=script&lang=js&"
export * from "./AddCategoryPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20874836",
  null
  
)

export default component.exports
<template>
    <container>
        <inner-page-card class="" :scrollbar="true" heading="CLINICAL SIGNS">
            <template #buttons>
                <slot name="buttons">
                    <add-btn @click="itemAdd"/>

                    <!--                    <btn icon="fa fa-plus" color="success" @click="viewUser" border-radius="0"></btn>-->
                </slot>
            </template>

            <modal width="50r" title="CLINICAL SIGNS" ref="clinicalsignAddModal">
                <s-form @submit="addItem">
                    <row>
                        <div class="col-lg-6">
                            <validated-ajax-select label="Species <span class='text-danger'>*</span>:"
                                                   class="mt-2 font-weight-700 text-2 v-select-dropdown-max-h-10r"
                                                   :url="speciesUrl" v-model="model.species" :rules="{required:true}"
                                                   :disabled="false"></validated-ajax-select>
                        </div>
                        <div class="col-lg-6">
                            <validated-ajax-select label="Disease <span class='text-danger'>*</span>:"
                                                   class="mt-2 font-weight-700 text-2 v-select-dropdown-max-h-10r"
                                                   :url="diseaseURL" v-model="model.disease" :rules="{required:true}"
                                                   :disabled="false"></validated-ajax-select>
                        </div>
                        <col-12 lg="6">
                            <validated-input
                                label="Clinical signs <span class='text-danger'>*</span> :"
                                v-model="model.clinical_signs" :rules="{required:true}"></validated-input>
                        </col-12>
                        <div class="col-lg-12">
                            <submit-btn :loading="loading" loading-text="Saving..."/>

                        </div>
                    </row>
                </s-form>
            </modal>

            <vue-table-card :fields="fields" :url="listingUrl"
                            :per-page="10" ref="table" search-placeholder="Search">
                <template #actions="{rowData}">
                    <btn-group>
                        <edit-btn @click="EditUser(rowData)"/>
                        <delete-btn @click="setDeleteUser(rowData)"/>
                    </btn-group>
                </template>
                <template #footer>
                    <edit-modal ref="clinicalSignEditModal">
                        <s-form @submit="addItem">
                            <row>
                                <div class="col-lg-6">
                                    <validated-ajax-select label="Species <span class='text-danger'>*</span>:"
                                                           class="mt-2 font-weight-700 text-2 v-select-dropdown-max-h-10r"
                                                           :url="speciesUrl" v-model="editingItem.species"
                                                           :rules="{required:true}"
                                                           :disabled="false"></validated-ajax-select>
                                </div>
                                <div class="col-lg-6">
                                    <validated-ajax-select label="Disease <span class='text-danger'>*</span>:"
                                                           class="mt-2 font-weight-700 text-2 v-select-dropdown-max-h-10r"
                                                           :url="diseaseURL" v-model="editingItem.disease"
                                                           :rules="{required:true}"
                                                           :disabled="false"></validated-ajax-select>
                                </div>
                                <div class="col-lg-6">
                                    <validated-input
                                        label="Clinical signs  <span class='text-danger'>*</span>:"
                                        v-model="editingItem.clinical_signs" :rules="{required:true}"></validated-input>
                                </div>
                                <col-12>
                                    <submit-btn :loading="loading" loading-text="Saving..."/>

                                </col-12>
                            </row>
                        </s-form>
                    </edit-modal>
                    <delete-modal ref="clinicalSignDeleteModal" @response="deleteSuccess" :url="deletingUrl"
                                  :params="{id : deletingItem.id}">
                        <p>You are about to delete a <b>{{ deletingItem.disease_name}}</b> .
                            Are you sure about it ?
                        </p>
                    </delete-modal>
                </template>
            </vue-table-card>

        </inner-page-card>
    </container>
</template>

<script>
import InnerPageCard from '@components/InnerPageCard';
import ClinicalSignsPage from '@/views/diagnosis/clinical-signs/clinical-sign-table-fields';
import urls from '@/data/urls';
import axios from 'secure-axios';

export default {
    name: 'ClinicalSignsPage',
    components: InnerPageCard,
    data () {
        return {
            loading: false,
            diseaseURL: urls.disease.disease.vueSelect,
            speciesUrl: urls.diagnosis.clinicalSigns.species,
            listingUrl: urls.diagnosis.clinicalSigns.list,
            deletingUrl: urls.diagnosis.clinicalSigns.delete,
            addEditUrl: urls.diagnosis.clinicalSigns.addEdit,
            editingItem: '',
            deletingItem: '',
            fields: ClinicalSignsPage,
            model: {
                species: '',
                disease: '',
                clinical_signs: ''
            },
            data: [
                {
                    species: 'fex-swts',
                    disease: 'afs vsgg ssvsgw',
                    note: 'ghbhhjbj'
                }
            ]
        };
    },
    methods: {
        deleteSuccess (item) {
            console.log('delete state', item);
            this.$refs.clinicalSignDeleteModal.close();
            if (item.success) {
                this.$notify('Deleted Successfully', 'Success', {
                    type: 'success'
                });
                this.$refs.table.refreshTable();
            } else {
                console.log('item error', item.errors.Id);
                this.$notify(item.errors.Id, 'warning', {
                    type: 'warning'
                });
            }
        },
        async addItem () {
            try {
                this.loading = true;
                if (this.editingItem) {
                    console.log('in edit');
                    this.response = await axios.form(this.addEditUrl, this.editingItem);
                } else {
                    console.log('in add');

                    this.response = await axios.form(this.addEditUrl, this.model);
                }
                const result = this.response.data;
                console.log('result', result);
                if (result.success === true) {
                    if (this.editingItem) {
                        this.$notify('Updated Successfully', 'Success', { type: 'success' });
                        this.loading = false;
                        this.editingItem = '';
                        this.$refs.clinicalSignEditModal.close();
                    } else {
                        this.$notify('Added Successfully', 'Success', { type: 'success' });
                        this.loading = false;
                        this.$refs.clinicalsignAddModal.close();
                    }
                    this.$refs.table.refreshTable();
                } else {
                    const errors = this.response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.this.response.status + ' ' + error.this.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        },
        addFormClear () {
            this.model.disease = '';
            this.model.clinical_signs = '';
            this.model.species = '';
        },
        EditUser (item) {
            console.log(item);
            this.editingItem = '';
            this.editingItem = { ...item };
            this.$refs.clinicalSignEditModal.show();
        },
        setDeleteUser (item) {
            console.log(item);
            this.deletingItem = item;
            this.$refs.clinicalSignDeleteModal.show();
        },
        itemAdd () {
            this.addFormClear();
            this.editingItem = '';
            this.$refs.clinicalsignAddModal.show();
        }
    }

};
</script>

<style scoped>

</style>

<template>
    <table-page heading="Brand Recommended Table">
        <vue-table-card :url="recommendedBrandListUrl" :fields="recommendsFields" :table-data="recommendsData" ref="table">
        </vue-table-card>
    </table-page>
</template>

<script>
import tableFields from './brand-recommends-table-fields';
import urls from '../../../data/urls';

export default {
    name: 'RecommendedBrandsListPage',
    data () {
        return {
            // fields: tableFields,
            recommendsFields: tableFields.tableFields,
            recommendsData: tableFields.sampleData,
            recommendedBrandListUrl: urls.drugs.brand.recommendedBrandList
        };
    }
};
</script>

<style scoped>

</style>

<template>

    <table-page heading="Users" heading-classes="mx-lg-n8">
        <vue-table-card ref="table" :fields="fields" :url="listUrl" class="mx-lg-n8" table-class="table table-bordered">
            <template #actions="{rowData}">
                <div class="fl-y fl-a-s">
                    <view-btn color="success" text="View" @click="viewUser(rowData)"/>
                    <ban-btn text="Ban" @click="BanUser(rowData)"/>
                    <!--                    <unban-btn @click="BanUser(rowData)"/>-->
                    <edit-btn text="Edit" @click="setEdit(rowData)"/>
                    <transactions-btn icon="fa fa-eye" text="Transactions" @click="viewTransactions(rowData)"/>
                </div>
            </template>
            <template #footer>
                <edit-modal ref="userEditModal">
                    <b-form :save-url="addUrl" @failure="userEditFailure" @success="UserEditFormSuccess('M')" v-slot="{model, loading}"
                            :initial-data="editingItem">
                        <row>
                            <col-12>
                                <validated-input label="Username <span class='text-danger'>*</span> :"
                                                 v-model="model.username" :rules="rules.username"
                                                 :disabled="true"></validated-input>
                            </col-12>
                            <col-12>
                                <validated-input label="First name" v-model="model.first_name"
                                                 :rules="rules.first_name" :disabled="loading"></validated-input>
                            </col-12>
                            <col-12>
                                <validated-input label="Last name" v-model="model.last_name" :rules="rules.last_name"
                                                 :disabled="loading"></validated-input>
                            </col-12>
                            <col-12>
                                <validated-input label="Email <span class='text-danger'>*</span> :"
                                                 v-model="model.email"
                                                 :rules="rules.email" :disabled="loading"></validated-input>
                                <form-info>Required</form-info>
                            </col-12>
                            <col-12>
                                <validated-input label="Designation <span class='text-danger'>*</span> :"
                                                 v-model="model.designation" :rules="rules.designation"
                                                 :disabled="loading"></validated-input>
                            </col-12>
                            <col-12>
                                <validated-input label="College " v-model="model.college" :rules="rules.college"
                                                 :disabled="loading"></validated-input>
                            </col-12>
                            <col-12>
                                <validated-input label="Batch" v-model="model.batch" :rules="rules.batch"
                                                 :disabled="loading"></validated-input>
                            </col-12>
                            <col-12>
                                <validated-input label="Reg no" v-model="model.reg_no" :rules="rules.reg_no"
                                                 :disabled="loading"></validated-input>
                            </col-12>
                            <col-12>
                                <validated-input label="Phone number" v-model="model.phone_number" :rules="rules.phone_number"
                                                 :disabled="loading"></validated-input>
                            </col-12>
                            <col-12>
                                <validated-input label="Country <span class='text-danger'>*</span> :"
                                                 v-model="model.country" :rules="rules.country"
                                                 :disabled="loading"></validated-input>
                            </col-12>
                            <col-12>
                                <validated-input label="Place" v-model="model.place" :rules="rules.place"
                                                 :disabled="loading"></validated-input>
                            </col-12>
                            <col-12>
                                <change-btn loading-text="Saving..." :disabled="loading"
                                            :loading="loading"/>
                            </col-12>
                        </row>
                    </b-form>
                </edit-modal>
                <delete-modal ref="userDeleteModal">
                    <p>You are about to delete the User <b>User Name</b>. Are you sure
                        ?
                    </p>
                </delete-modal>
                <!--View Info Modal-->
                <modal width="35r" title="User Info" ref="userViewModal">
                    <details-card :heading="'Username : ' + currentUserItem.username" v-if="currentUserItem">
                        <details-list>
                            <details-list-item label="First Name" :value="currentUserItem.first_name"/>
                            <details-list-item label="Last Name" :value="currentUserItem.last_name"/>
                            <details-list-item label-width="13r" label="College name" :value="currentUserItem.college"/>
                            <details-list-item label="Batch" :value="currentUserItem.batch"/>
                            <details-list-item label-width="14r" label="Designation" :value="currentUserItem.designation"/>
                            <details-list-item label="Email" :value="currentUserItem.email"/>
                            <details-list-item label="Phone number" :value="currentUserItem.phone_number"/>
                            <details-list-item label="Registration number" :value="currentUserItem.reg_no"/>
                            <details-list-item label="Country" :value="currentUserItem.country"/>
                            <details-list-item label="Place" :value="currentUserItem.place"/>
                        </details-list>
                    </details-card>
                </modal>
                <!--Transactions Modal-->
                <modal width="35r" title="Plans Info" ref="TransactionsInfoModal">
                    <p class="text-2" v-if="selectedUserTransactions && selectedUserTransactions.length === 0">No Transactions found</p>
                    <template v-else>
                        <div v-for="(item, i) in selectedUserTransactions" :key="i">
                            <card p="2" border-radius="1" >
                                {{item.username}}
                            </card>
                            <details-card :heading="'Plan Name : ' + item.plan.name" v-if="item.plan" class="mt-3">
                                <details-list>
                                    <details-list-item label="Transaction ID" :value="item.transaction_id"/>
                                    <details-list-item label="Status" :value="item.status"/>
                                    <details-list-item label="Validity" :value="item.plan.expiry_months + ' Months'"/>
                                    <details-list-item label="Initiated Date" :value="item.timestamp"/>
                                    <details-list-item label="Transaction Date" :value="item.transaction_timestamp"/>
                                    <details-list-item label="Amount" :value="item.amount"/>
                                    <details-list-item label="Transaction Mode" :value="item.status"/>
                                    <details-list-item label="Bank ID" :value="item.bank_id"/>
                                    <details-list-item label="Bank Ref No" :value="item.bank_ref_no"/>
                                    <details-list-item label="Active" :value="item.plan.active"/>
                                    <details-list-item label="refund Status" :value="item.refund_status"/>
                                </details-list>
                            </details-card>
                        </div>
                    </template>
                </modal>
            </template>
        </vue-table-card>
    </table-page>

</template>

<script>
import usersTableFields from './users-table-fields';
import DetailsList from '../../../components/list-items/DetailsList';
import DetailsListItem from '../../../components/list-items/DetailsListItem';
import urls from '../../../data/urls';
import axios from 'secure-axios';

export default {
    name: 'ViewUsersPage',
    components: { DetailsListItem, DetailsList },
    data () {
        return {
            fields: usersTableFields,
            listUrl: urls.userManagement.users.list,
            banUrl: urls.userManagement.users.userBan,
            detailsUrl: urls.userManagement.users.detail,
            addUrl: urls.userManagement.users.addEdit,
            transactionsUrl: urls.userManagement.users.transactions,
            rules: {
                username: {
                    required: true
                },
                first_name: {
                    required: false
                },
                last_name: {
                    required: false
                },
                email: {
                    required: true
                },
                designation: {
                    required: true
                },
                college: {
                    required: false
                },
                batch: {
                    required: false
                },
                reg_no: {
                    required: false
                },
                phone_number: {
                    required: false
                },
                country: {
                    required: true
                },
                place: {
                    required: false
                }
            },
            currentUserItem: null,
            selectedUserTransactions: null,
            editingItem: null
        };
    },
    methods: {
        async BanUser (item) {
            // ban user post method
            const that = this;
            const response = await axios.form(this.banUrl, { id: item.id })
                .catch(function (exception) {
                    that.serverError();
                });
            const responseData = await response.data;
            if (responseData.success) {
                this.$notify('User is banned Successfully', 'Success', {
                    type: 'success'
                });
            }
            if (responseData.error) {
                that.$notify('Error fount..!', 'Error',
                    {
                        type: 'warning',
                        duration: 3000,
                        permanent: false
                    }
                );
            }
            this.$refs.table.refreshTable();
        },
        async viewUser (item) {
            const that = this;
            const response = await axios.form(
                this.detailsUrl, { id: item.id }
            ).catch(function (exception) {
                that.serverError();
            });
            const json = response.data;
            const dataItem = json.data;
            // console.log('json', json.data.first_name);
            this.currentUserItem = dataItem;
            this.$refs.userViewModal.show();
        },
        serverError () {
            // this.invalidCredential = true;
            this.message = 'Server error, Please try again later.';
        },
        async viewTransactions (item) {
            // Fetch Transactions Data
            const that = this;
            const response = await axios.form(
                this.transactionsUrl, { id: item.id }
            ).catch(function (exception) {
                that.serverError();
            });
            const json = response.data;
            const dataItem = json.data;
            this.selectedUserTransactions = dataItem;
            this.$refs.TransactionsInfoModal.show();
        },
        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.userEditModal.show();
        },
        UserEditFormSuccess (action) {
            // const refs = this.$refs;
            // this.$refs.addModal.close();
            this.$refs.userEditModal.close();
            if (action === 'A') {
                this.$notify('User Added Successfully', 'Success', {
                    type: 'success'
                });
                this.$refs.table.refreshTable();
            } else {
                this.$notify('User Updated Successfully', 'Success', {
                    type: 'success'
                });
            }
            this.$refs.table.refreshTable();
            this.editingItem = null;
        },
        userEditFailure (response) {
            const errors = response.data.errors;
            for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                    const errorMessage = `${key}:  ${errors[key]}`;
                    this.$notify(errorMessage, 'Error', { type: 'danger' });
                }
            }
        }
    }
};

</script>

<style scoped>

</style>

const tableFields = [
    {
        name: 'username',
        title: 'Username',
        titleClass: '',
        dataClass: 'w-10r',
        sortField: 'username'
    },
    {
        name: 'first_name',
        title: 'First Name',
        titleClass: '',
        dataClass: '',
        sortField: 'first_name'

    },
    {
        name: 'last_name',
        title: 'Last Name',
        titleClass: '',
        dataClass: '',
        sortField: 'last_name'
    },
    {
        name: '__slot:actions',
        title: 'Actions',
        titleClass: 'text-right',
        dataClass: 'text-right'
    }
];

export default tableFields;
